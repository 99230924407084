import React, {FC, ReactNode} from 'react';
import {observer} from 'mobx-react-lite';
import {withViewModel} from '@joyrideautos/ui-common/src/utils/componentUtils';
import {HasViewModel} from '@joyrideautos/ui-models/src/types';
import {BottomBannerEnum, BottomBannerViewModel} from './BottomBannerViewModel';
import BannerTemplate from './bannerTemplate/BannerTemplate';
import {Button} from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import {Router, useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import {Routes} from '../../../Routes';
import {rootStore} from '../../../models/stores/RootStore';
import {TFunction} from 'i18next';
import {Trans, useTranslation} from 'react-i18next';
import styles from './BottomBanner.module.less';
import {URL_SUPPORT_BIDDING_LIMITS_GLOBAL} from '@joyrideautos/ui-common/src/constants';

const bottomBanner: {
    [banner in BottomBannerEnum]: (viewModel: any, router: Router, t: TFunction<'en'>) => ReactNode;
} = {
    [BottomBannerEnum.COMPLETE_PURCHASE]: (viewModel, router, t) => (
        <BannerTemplate
            title={t('banner.completePurchase.title')}
            subTitle={
                <Trans
                    i18nKey="banner.completePurchase.subTitle"
                    values={{time: viewModel.timeText}}
                    components={{strong: <strong />}}
                />
            }
            button={<Button>{t('banner.completePurchase.buttonLabel')}</Button>}
        />
    ),
    [BottomBannerEnum.OVERDUE]: (viewModel, router, t) => (
        <BannerTemplate
            title={t('banner.overdue.title')}
            subTitle={
                <Trans
                    i18nKey="banner.overdue.subTitle"
                    values={{time: viewModel.timeText}}
                    components={{strong: <strong />}}
                />
            }
            button={<Button>{t('banner.overdue.buttonLabel')}</Button>}
        />
    ),
    [BottomBannerEnum.EXPIRED]: (viewModel, router, t) => (
        <BannerTemplate
            icon={<WarningOutlined />}
            title={t('banner.expired.title')}
            subTitle={
                <Trans
                    i18nKey="banner.expired.subTitle"
                    components={{
                        CustomLink: (
                            <a href="mailto:support@marketplace.autura.com">
                                <u>support@marketplace.autura.com</u>
                            </a>
                        ),
                    }}
                />
            }
            button={<Button>{t('banner.expired.buttonLabel')}</Button>}
            className="expired"
        />
    ),
    [BottomBannerEnum.ADD_PAYMENT_METHOD]: (viewModel, router, t) => (
        <BannerTemplate
            title={t('banner.addPaymentMethod.title')}
            subTitle={<span>{t('banner.addPaymentMethod.subTitle')}</span>}
            button={
                <Button onClick={() => router.push(Routes.PROFILE_SETTINGS({hash: 'payment_methods'}))}>
                    {t('banner.addPaymentMethod.buttonLabel')}
                </Button>
            }
        />
    ),
    [BottomBannerEnum.BIDDING_LIMIT_REACHED]: (viewModel, router, t) =>
        viewModel.loggedInUserHasOutstandingPayments ? (
            <BannerTemplate
                title={t('banner.balanceThresholdReached.title')}
                subTitle={<span>{t('banner.balanceThresholdReached.subTitle')}</span>}
                button={
                    <Button onClick={() => router.push(Routes.PAYMENT_SUMMARY())}>
                        {t('banner.balanceThresholdReached.payButtonLabel')}
                    </Button>
                }
            />
        ) : (
            <BannerTemplate
                title={t('banner.balanceThresholdReached.title')}
                button={
                    <Button href={URL_SUPPORT_BIDDING_LIMITS_GLOBAL} rel="noreferrer" target="_blank">
                        {t('common.learnMore')}
                    </Button>
                }
            />
        ),
};

interface BottomBannerProps extends HasViewModel<typeof BottomBannerViewModel> {}

const BottomBanner: FC<BottomBannerProps> = observer(({viewModel}) => {
    const router = useRouter();
    const {t} = useTranslation();
    const {currentBanner, currentViewModel} = viewModel;
    return currentBanner ? (
        <div className={styles['bottom-banner']}>{bottomBanner[currentBanner](currentViewModel, router, t)}</div>
    ) : null;
});

export default withViewModel(BottomBannerViewModel, rootStore)(BottomBanner);
