export enum AuctionAccessTypeEnum {
    PUBLIC = 'PUBLIC',
    RESTRICTED = 'RESTRICTED',
}

export enum AuctionTypeEnum {
    SEQUENCE = 'SEQUENCE',
    LISTING = 'LISTING',
}

export enum AuctionPauseEnum {
    PAUSED = 'paused',
    SUSPENDED = 'suspended',
}

export const AuctionSchedulerBaseDates = {
    [AuctionTypeEnum.LISTING]: 'expirationByScheduler',
    [AuctionTypeEnum.SEQUENCE]: 'startEventByScheduler',
} as const;

export const AuctionStartTypes = {
    [AuctionTypeEnum.LISTING]: 'start',
    [AuctionTypeEnum.SEQUENCE]: 'startEvent',
} as const;

export const AuctionBaseDateFields = {
    [AuctionTypeEnum.LISTING]: 'expiration',
    [AuctionTypeEnum.SEQUENCE]: 'startEvent',
} as const;
export type AuctionBaseDateField = typeof AuctionBaseDateFields[keyof typeof AuctionBaseDateFields];

export type AnnouncementTypeDto = {
    html: string;
    markdown: string;
    timestamp: string;
};

export enum AuctionSeriesAnnouncementKeyEnum {
    REGULAR = 'regular',
    LIVE = 'live',
}

export type AuctionStartType = typeof AuctionStartTypes[keyof typeof AuctionStartTypes];
export type AuctionSchedulerBaseDate = typeof AuctionSchedulerBaseDates[keyof typeof AuctionSchedulerBaseDates];

export type AuctionPauseType = AuctionPauseEnum | null | undefined;

export type SequenceAuctionStartSettings = {
    startDate: Date;
    startEventDate: Date;
};

export type ListingAuctionStartSettings = {
    startDate: Date;
    expiration: Date;
};

export function isListingAuction(auctionType?: AuctionTypeEnum) {
    return auctionType === AuctionTypeEnum.LISTING;
}

export function isSequenceAuction(auctionType?: AuctionTypeEnum) {
    return auctionType === AuctionTypeEnum.SEQUENCE;
}

export function isPublicAuction(accessType?: AuctionAccessTypeEnum) {
    return accessType === AuctionAccessTypeEnum.PUBLIC;
}

export function isRestrictedAuction(accessType?: AuctionAccessTypeEnum) {
    return accessType === AuctionAccessTypeEnum.RESTRICTED;
}
