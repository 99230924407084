import {Calculator} from './types';
import {validateMoney, validatePercent} from './utils';
import {AuctionOccurrenceSettings} from '../dtos/AuctionOccurrenceDto';
import {Money} from '../types/Money';

export class SalesTaxCalculator implements Calculator<Money> {
    constructor(private salesTaxPercent: number) {}

    static getInstance(salesTax: number) {
        return new SalesTaxCalculator(salesTax);
    }

    static getInstanceWithAuctionSettings(
        auctionSettings: AuctionOccurrenceSettings,
        options: {
            isExemptFromSalesTax: boolean;
        }
    ) {
        const salesTax = options.isExemptFromSalesTax ? 0 : auctionSettings.salesTax || 0;
        return new SalesTaxCalculator(salesTax);
    }

    static getEmptyInstance() {
        return new SalesTaxCalculator(0);
    }

    calculate(amount: Money): Money {
        this.validate(amount);
        return amount.takePercent(this.salesTaxPercent);
    }

    get percent() {
        return this.salesTaxPercent;
    }

    validate(amount: Money): void {
        validateMoney(amount, 'bidAmount');
        validatePercent(this.salesTaxPercent, 'salesTax');
    }
}
