import {AuctionsListenerType, Socket} from '../types';
import {BaseService} from './BaseService';
import {AuctionSeriesDto} from '@joyrideautos/auction-core/src/dtos/AuctionSeriesDto';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {WithKey} from '../firebase/types';
import {AuctionOccurrenceDto, AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {DataSnapshot} from '../firebase/Database';
import {promiseTimeout} from '@joyrideautos/auction-utils/src/PromiseUtils';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {WSExtendBiddingExpirationRes} from '@joyrideautos/auction-core/src/types/requests/WebSocketsReqTypes';
import {
    AnnouncementTypeDto,
    AuctionPauseType,
    AuctionTypeEnum,
} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {
    GetAuctionScheduleReqData,
    GetAuctionScheduleResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/getAuctionScheduleReqTypes';
import {
    SaveAuctionScheduleReqData,
    SaveAuctionScheduleResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/saveAuctionScheduleReqTypes';
import {
    EnablePlacingBidsRPCReqData,
    EnablePlacingBidsRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/enablePlacingBidsReqTypes';
import {
    DeleteAuctionOccurrenceRPCReqData,
    DeleteAuctionOccurrenceRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/deleteAuctionOccurrenceReqTypes';
import {DeleteAllAuctionsDataRPCReqData} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/deleteAllAuctionsDataReqTypes';
import {
    ValidateActiveAuctionsConfigurationReqData,
    ValidateActiveAuctionsConfigurationResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/validateActiveAuctionsConfigurationReqTypes';
import {CustomBidIncrementDto} from '@joyrideautos/auction-core/src/dtos/BidIncrementDto';
import {
    SaveBidIncrementReqData,
    SaveBidIncrementResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/saveBidIncrementReqTypes';
import {CATALYTIC_CONVERTER_OTHER} from '@joyrideautos/auction-core/src/constants/Constants';
import {
    DeleteAuctionScheduleReqData,
    DeleteAuctionScheduleResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/deleteAuctionScheduleReqTypes';
import {
    UpdateAuctionSeriesReqData,
    UpdateAuctionSeriesResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/updateAuctionSeriesReqTypes';
import {
    UpdateAuctionOccurrenceReqData,
    UpdateAuctionOccurrenceResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/updateAuctionOccurrenceReqTypes';
import {
    GetLiveAuctionsForSellerReqData,
    GetLiveAuctionsForSellerResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/auction/getLiveAuctionsForSellerReqTypes';

const convertAuctionSeriesSnapshotToAuction = (regionId: string, snapshot: DataSnapshot): AuctionSeriesDto => {
    return {
        ...snapshot.val(),
        key: snapshot.key,
        regionId: regionId,
    };
};

const convertAuctionSnapshotToAuction = (
    regionId: string,
    snapshot: DataSnapshot
): {key: string; value: AuctionOccurrenceDto}[] => {
    return [
        {
            key: snapshot.key!,
            value: {
                ...snapshot.val(),
                regionId: regionId,
                auctionId: snapshot.key,
            },
        },
    ];
};

const convertAnnouncementSnapshotToAnnouncement = (snapshot: DataSnapshot): AnnouncementTypeDto | undefined => {
    return snapshot.exists() ? {...snapshot.val()} : undefined;
};

export class AuctionService extends BaseService {
    async getAuctionSeriesList(regionId: string, ended: boolean | null = null): Promise<AuctionSeriesDto[]> {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_GET_AUCTION_SERIES)({regionId, ended});
    }

    subscribeToAuctionSeries(regionId: string, subscriber: (auctionSeries: AuctionSeriesDto[]) => void): () => void {
        return this.firebase.database.subscribeToSnapshot(`/${regionId}/auctionSeries`, (snapshot) => {
            const values: AuctionSeriesDto[] = [];
            if (snapshot) {
                snapshot.forEach((auctionSnapshot: any) => {
                    values.push({...auctionSnapshot.val(), regionId, key: auctionSnapshot.key});
                });
            }
            subscriber(values);
        });
    }

    async fetchMultipleAuctionSeries(
        auctionSeries: {regionId: string; auctionSeriesId: string}[]
    ): Promise<AuctionSeriesDto[]> {
        const makePath = (regionId: string, auctionSeriesId: string) => `${regionId}/auctionSeries/${auctionSeriesId}`;
        const promises = auctionSeries.map((a) =>
            this.firebase.database.fetchOnceSnapshot(makePath(a.regionId, a.auctionSeriesId))
        );
        const result = await Promise.all(promises);
        return result
            .filter((s: any) => !!s.val())
            .map((snapshot, i) => {
                const auctionSeriesToLoad = auctionSeries[i];
                return convertAuctionSeriesSnapshotToAuction(auctionSeriesToLoad.regionId, snapshot);
            });
    }

    async findAuctionSeriesById(regionId: string, seriesId: number) {
        const query = this.firebase.database.ref(`${regionId}/auctionSeries`).orderByChild('id').equalTo(seriesId);
        return this.firebase.database.fetchOnceArray<WithKey<AuctionSeriesDto> & {regionId: string}>(
            query,
            (snapshot) => ({
                key: snapshot.key,
                regionId,
                ...snapshot.val(),
            })
        );
    }

    async fetchMultipleAuctionOccurences(auctionOccurences: AuctionPath[]) {
        const makePath = (regionId: string, auctionId: string) => `${regionId}/auctions/${auctionId}`;
        const promises = auctionOccurences.map((a) =>
            this.firebase.database.fetchOnceSnapshot(makePath(a.regionId, a.auctionId))
        );
        const result = await Promise.all(promises);
        return result.map((snapshot, i) => {
            const auctionToLoad = auctionOccurences[i];
            return convertAuctionSnapshotToAuction(auctionToLoad.regionId, snapshot)[0].value;
        });
    }

    async fetchAuctionOccurrences(regionId: string) {
        return this.firebase.database.fetchOnceArray<AuctionOccurrenceDto>(`/${regionId}/auctions`, (snapshot) =>
            convertAuctionSnapshotToAuction(regionId, snapshot)
        );
    }

    async fetchAuctionOcurrencesForSeries(regionId: string, auctionSeriesId: string) {
        if (!regionId || !auctionSeriesId) {
            throw Error(`Illegal argument: regionId=${regionId}; auctionSeriesId=${auctionSeriesId}`);
        }
        const auctionsForSeriesQuery = this.firebase.database
            .ref(`/${regionId}/auctions`)
            .orderByChild('auctionSeries')
            .equalTo(auctionSeriesId);
        return this.firebase.database.fetchOnceArray<AuctionOccurrenceDto>(auctionsForSeriesQuery, (auctionSnap) =>
            convertAuctionSnapshotToAuction(regionId, auctionSnap)
        );
    }

    subscribeToAuctions(regionId: string, listener: AuctionsListenerType): () => void {
        if (!regionId) {
            throw Error('region is undefined');
        }
        const onAuctionsSnapshot = (auctionsSnapshot: DataSnapshot | null) => {
            const values: AuctionOccurrenceDto[] = [];
            if (auctionsSnapshot) {
                auctionsSnapshot.forEach((auctionSnapshot: DataSnapshot) => {
                    values.push({...auctionSnapshot.val(), regionId: regionId, auctionId: auctionSnapshot.key});
                });
            }
            listener(values);
        };

        return this.firebase.database.subscribeToSnapshot(`/${regionId}/auctions`, onAuctionsSnapshot);
    }

    async fetchAuction(regionId: string, auctionId: string): Promise<AuctionOccurrenceDto> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/auctions/${auctionId}`);
        return {...snapshot.val(), regionId, auctionId};
    }

    async fetchAuctionSeries(regionId: string, seriesKey: string): Promise<AuctionSeriesDto> {
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/auctionSeries/${seriesKey}`);
        return convertAuctionSeriesSnapshotToAuction(regionId, snapshot);
    }

    async extendBiddingExpirationIO(socket: Socket, regionId: string, auctionId: string): Promise<void> {
        try {
            await promiseTimeout(
                new Promise<any>((resolve) => {
                    socket.emit(
                        'auction:extendBiddingExpiration',
                        {
                            regionId,
                            auctionId,
                        },
                        (callbackData: WSExtendBiddingExpirationRes) => {
                            resolve(callbackData);
                        }
                    );
                }),
                3000
            );
        } catch (e: any) {
            logError()(e.message);
            throw new Error('Extend Bidding Expiration Failed.');
        }
    }

    async extendBiddingExpiration(regionId: string, auctionId: string): Promise<void> {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_EXTEND_BIDDING_EXPIRATION)({
            regionId,
            auctionId,
        });
    }

    async updateAuctionPause(regionId: string, auctionId: string, pause: AuctionPauseType): Promise<void> {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_PAUSE)({
            regionId,
            auctionId,
            pause,
        });
    }

    async updateAuctionPauseIO(
        socket: Socket,
        regionId: string,
        auctionId: string,
        pause: AuctionPauseType
    ): Promise<void> {
        await promiseTimeout(
            new Promise((resolve) => {
                socket.emit('auction:updateAuctionPause', {regionId, auctionId, pause}, (callbackData: any) =>
                    resolve(callbackData)
                );
            }),
            3000
        );
    }

    async getAuctionSeriesInternalIdByAuctionId(regionId: string, auctionId: string): Promise<number | null> {
        const auctionSeriesId = await this.firebase.database.fetchOnce(
            `/${regionId}/auctions/${auctionId}/auctionSeries`
        );
        return this.firebase.database.fetchOnce(`/${regionId}/auctionSeries/${auctionSeriesId}/id`);
    }

    async updateAuctionSeries(
        regionId: string,
        seriesKey: string | undefined,
        dto: Partial<AuctionSeriesDto>,
        isPartialUpdate?: boolean
    ): Promise<void> {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_SERIES)<
            UpdateAuctionSeriesReqData,
            UpdateAuctionSeriesResData
        >({
            regionId,
            seriesKey,
            dto,
            isPartialUpdate,
        });
    }

    async updateAuctionOccurrence(
        regionId: string,
        auctionKey: string | undefined,
        dto: Partial<AuctionOccurrenceDto>,
        isPartialUpdate?: boolean
    ) {
        const {auctionId, auction} = await this.firebase.rpcService.call(
            FeReqRouteEnum.API_AUCTIONS_UPDATE_AUCTION_OCCURRENCE
        )<UpdateAuctionOccurrenceReqData, UpdateAuctionOccurrenceResData>({
            regionId,
            auctionKey,
            dto,
            isPartialUpdate,
        });
        return {...auction, auctionId, regionId};
    }

    async endAuctionVehicle(regionId: string, auctionId: string, itemKey: string): Promise<any> {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_END_AUCTION_VEHICLE)({
            regionId,
            auctionId,
            itemId: itemKey,
        });
    }

    async skipAuctionVehicle(regionId: string, auctionId: string, itemKey: string): Promise<any> {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_SKIP_AUCTION_VEHICLE)({
            regionId,
            auctionId,
            itemId: itemKey,
        });
    }

    async fetchAllFilters(auctionId: string, regionId: string, refs: Record<string, string[]> = {}): Promise<any> {
        type Attribute =
            | 'body'
            | 'color'
            | 'make'
            | 'year'
            | 'currentBid'
            | 'keyStatus'
            | 'startCode'
            | 'odometer'
            | 'model'
            | 'seller'
            | 'catalyticConverter';

        const attributes = `/${regionId}/attributes/${auctionId}/`;
        const result = {} as {[key in Attribute]: any};
        await this.firebase.database.fetchOnceArray(attributes, (snapshot) => {
            const auctionAttributes = snapshot.val();
            const snapValKeys = Object.keys(auctionAttributes) as Attribute[];
            const attribute = snapshot.key! as Attribute;
            if (attribute === 'model') {
                result[attribute] = snapValKeys.reduce((val: any, acc: any) => {
                    const snapValPropsKeys = Object.keys(auctionAttributes[acc]);
                    val[acc] = snapValPropsKeys.slice(0, snapValPropsKeys.length - 1);
                    return val;
                }, {});
            } else if (attribute === 'year') {
                // @ts-ignore
                result[attribute] = {from: Math.min.apply(Math, snapValKeys), to: Math.max.apply(Math, snapValKeys)};
            } else if (attribute === 'catalyticConverter' && refs['catalyticConverter']) {
                const reduced = snapValKeys.reduce((ac, cur) => {
                    if (refs['catalyticConverter'].includes(cur)) {
                        ac.add(cur);
                    } else {
                        ac.add(CATALYTIC_CONVERTER_OTHER.code);
                    }
                    return ac;
                }, new Set<string>());
                result[attribute] = [...reduced];
            } else {
                result[attribute] = snapValKeys;
            }
            return [];
        });
        return result;
    }

    subscribeToAuctionAnnouncement(
        regionId: string,
        auctionId: string,
        live: 'live' | 'regular',
        subscriber: (announcement: AnnouncementTypeDto) => void
    ) {
        return this.firebase.database.subscribeToSnapshot(
            `/${regionId}/announcements/${auctionId}/${live}`,
            (snapshot) => {
                const announcement = convertAnnouncementSnapshotToAnnouncement(snapshot);
                announcement && subscriber(announcement);
            }
        );
    }

    async fetchAuctionAnnouncement(
        regionId: string,
        auctionId: string,
        live: 'live' | 'regular'
    ): Promise<AnnouncementTypeDto | undefined> {
        const response = await this.firebase.database.fetchOnceSnapshot(
            `/${regionId}/announcements/${auctionId}/${live}`
        );
        return convertAnnouncementSnapshotToAnnouncement(response);
    }

    async updateAuctionAnnouncement(
        regionId: string,
        auctionId: string,
        live: 'live' | 'regular',
        announcement: AnnouncementTypeDto
    ): Promise<any> {
        await this.firebase.database.setValues(`/${regionId}/announcements/${auctionId}/${live}`, announcement);
    }

    async rescheduleAuctionOccurrence(
        regionId: string,
        auctionId: string,
        initialStart?: string,
        newEventStart?: string
    ): Promise<boolean> {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_RESCHEDULE_AUCTION_OCCURRENCE)({
            regionId,
            auctionId,
            initialStart,
            newEventStart,
        });
    }

    async getAuctionSchedule(data: GetAuctionScheduleReqData): Promise<GetAuctionScheduleResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_GET_AUCTION_SCHEDULE)(data);
    }

    async saveAuctionSchedule(data: SaveAuctionScheduleReqData): Promise<SaveAuctionScheduleResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_SAVE_AUCTION_SCHEDULE)(data);
    }

    async deleteAuctionSchedule(auctionScheduleId: string) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_DELETE_AUCTION_SCHEDULE)<
            DeleteAuctionScheduleReqData,
            DeleteAuctionScheduleResData
        >({seriesId: auctionScheduleId});
    }

    async enablePlacingBids(data: EnablePlacingBidsRPCReqData): Promise<EnablePlacingBidsRPCResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_ENABLE_PLACING_BIDS)(data);
    }

    async deleteAuction({auctionId, regionId}: DeleteAuctionOccurrenceRPCReqData) {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_DELETE_AUCTION)<
            DeleteAuctionOccurrenceRPCReqData,
            DeleteAuctionOccurrenceRPCResData
        >({
            auctionId,
            regionId,
        });
    }

    async deleteAllAuctionsData(data: DeleteAllAuctionsDataRPCReqData): Promise<void> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_DELETE_ALL_AUCTIONS_DATA)(data);
    }

    async validateActiveAuctionsConfiguration(
        data: ValidateActiveAuctionsConfigurationReqData
    ): Promise<ValidateActiveAuctionsConfigurationResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_VALIDATE_CONFIGURATION)(data);
    }

    async fetchAuctionFromFirestore({
        regionId,
        auctionId,
    }: AuctionPath): Promise<(AuctionOccurrenceDto & AuctionPath) | undefined> {
        try {
            const auction = await this.firebase.firestore.fetchOnce<AuctionOccurrenceDto>(
                this.firebase.firestore.documentRef(`/auctions/${auctionId}`)
            );
            if (!auction) {
                return;
            }
            return {...auction, regionId, auctionId};
        } catch (e: any) {
            console.error('failed to fetch auction', {regionId, auctionId}, e.message);
            return undefined;
        }
    }

    async addSellerToAuctionSeries(sellerId: string, regionKey: string, seriesKey: string) {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_ADD_SELLER_TO_AUCTION_SERIES)({
            regionKey,
            seriesKey,
            sellerId,
        });
        return true;
    }

    async removeSellerFromAuctionSeries(sellerId: string, regionKey: string, seriesKey: string) {
        // TODO (Future): define the req/res types in libs/core/src/types/requests/RPCReqTypes
        await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_REMOVE_SELLER_FROM_AUCTION_SERIES)({
            regionKey,
            seriesKey,
            sellerId,
        });
        return true;
    }

    async getBidIncrementConfig(type: string) {
        const incrementConfig = await this.firebase.firestore.fetchOnce<CustomBidIncrementDto>(
            this.firebase.firestore.documentRef(`/bidIncrements/${type}`)
        );
        if (!incrementConfig) {
            this.logger.log(`Missed increment config for type: ${type}`);
            return undefined;
        }
        return incrementConfig;
    }

    async saveBidIncrementConfig(data: SaveBidIncrementReqData): Promise<SaveBidIncrementResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_SAVE_BID_INCREMENT)(data);
    }

    subscribeToBidIncrements(listener: (bidIncrement: {[key: string]: CustomBidIncrementDto}) => void) {
        return this.firebase.firestore.subscribeToCollection<CustomBidIncrementDto>(
            this.firebase.firestore.collectionRef(`/bidIncrements`),
            (result) => result && listener(result)
        );
    }

    subscribeToUpcomingAuctionsByType(
        auctionType: AuctionTypeEnum,
        limitTo: number | undefined,
        listener: (auctions: WithKey<AuctionOccurrenceDto>[]) => void
    ) {
        const orderBy = auctionType === AuctionTypeEnum.LISTING ? 'settings.expiration' : 'settings.startEvent';
        const query = this.firebase.firestore
            .collectionRef<AuctionOccurrenceDto>(`/auctions`)
            .where('ended', '==', false)
            .where('deleted', '==', false)
            .where('settings.auctionType', '==', auctionType)
            .orderBy(orderBy, 'asc');

        if (limitTo) {
            query.limit(limitTo);
        }
        return this.firebase.firestore.subscribeToCollection<AuctionOccurrenceDto>(query, (result) => {
            const auctions: WithKey<AuctionOccurrenceDto>[] = [];
            if (result) {
                for (const [key, value] of Object.entries(result)) {
                    auctions.push({...value, key});
                }
            }
            return listener(auctions);
        });
    }

    async getLiveAuctionsForSeller(data: GetLiveAuctionsForSellerReqData): Promise<GetLiveAuctionsForSellerResData> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_AUCTIONS_GET_LIVE_AUCTIONS_FOR_SELLER)(data);
    }
}
