import {ONE_HOUR, ONE_MIN} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {Instance} from 'mobx-state-tree';
import {now} from 'mobx-utils';

export const OverdueBannerViewModel = RootStoreAwareViewModel.named('OverdueBannerViewModel')
    .props({})
    .views((self) => ({
        get nearestOverdueItem() {
            return self.rootStore.wonItemsStore?.nearestOverdueItem;
        },
        get nearestOverdueDateMs() {
            return this.nearestOverdueItem?.overdueAt?.timestamp;
        },
        get nearestExpirationDateMs() {
            return this.nearestOverdueItem?.expirationAt?.timestamp;
        },
        get isActive() {
            return this.nearestOverdueDateMs ? this.nearestOverdueDateMs < now(ONE_MIN) : false;
        },
        get time() {
            return Math.ceil((this.nearestExpirationDateMs - now(ONE_MIN)) / ONE_HOUR);
        },
        get timeText() {
            return self.rootStore.languageStore.hoursCount(this.time);
        },
    }));

export interface OverdueBannerViewModelType extends Instance<typeof OverdueBannerViewModel> {}
