import {
    BodyDto,
    ColorDataDto,
    DriveTrainDto,
    EngineDataDto,
    LicensePlaceStateDto,
    MakeDataDto,
    ModelDataDto,
    TransmissionDto,
    DocumentationTypeDataDto,
    KeyStatusDataDto,
    StartStatusDataDto,
    BuyerDto,
    CatalyticConverterDataDto,
    SellerIntegrationDto,
    BusinessRoleDto,
} from '@joyrideautos/auction-core/src/dtos/RefsDto';
import {flatten} from '@joyrideautos/auction-utils/src/arrayUtils';
import {BaseService} from './BaseService';

export class RefsService extends BaseService {
    fetchEngineData() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<EngineDataDto>('/refEngineTypes')
                .where('active', '==', true)
                .orderBy('sortOrder')
        );
    }

    fetchDrivetrainData() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<DriveTrainDto>('/refDrivetrain')
                .where('active', '==', true)
                .orderBy('sortOrder')
        );
    }

    getBusinessTypes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<BuyerDto>('/refBuyerType').orderBy('sortOrder', 'asc')
        );
    }

    getRoleTypes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<BusinessRoleDto>('/refBusinessRoles')
        );
    }

    fetchStartStatuses(): Promise<string[]> {
        return this.firebase.firestore.fetchOnceArray<StartStatusDataDto, string>(
            this.firebase.firestore.collectionRef('/refStartStatuses'),
            (snapshot) => snapshot.data().name || snapshot.data().code
        );
    }

    fetchLicensePlateStates() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<LicensePlaceStateDto>('/refLicensePlateStates')
                .where('active', '==', true)
        );
    }

    fetchAllMakes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<MakeDataDto>('/refMakes').where('active', '==', true).orderBy('make')
        );
    }

    async fetchAllModels() {
        const models = await this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<ModelDataDto>('/refModels')
                .where('active', '==', true)
                .orderBy('makeId')
        );
        return models.filter(({makeCode}) => !!makeCode);
    }

    async fetchModels(makeName: string) {
        if (!makeName) {
            return [];
        }
        const makes = await this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<MakeDataDto>('/refMakes')
                .where('active', '==', true)
                .where('make', '==', makeName)
        );
        const make = makes[0];

        if (make) {
            const models = await this.firebase.firestore.fetchOnceArray(
                this.firebase.firestore
                    .collectionRef<ModelDataDto>('/refModels')
                    .where('makeId', '==', make.makeId)
                    .where('active', '==', true)
            );
            return models;
        }
        return [];
    }

    async fetchModelsForMakes(makeNames: string[]): Promise<ModelDataDto[]> {
        if (makeNames.length === 0) {
            return [];
        }

        const result = await Promise.all(makeNames.map((make) => this.fetchModels(make)));
        return flatten(result);
    }

    fetchKeyStatuses() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<KeyStatusDataDto>('/refKeyStatuses'),
            (snapshot) => ({
                code: snapshot.data().code,
                name: snapshot.data().name,
                key: snapshot.id,
            })
        );
    }

    fetchDocumentationTypes() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<DocumentationTypeDataDto>('/refDocumentationTypes')
        );
    }

    fetchColors() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<ColorDataDto>('/refColors').where('active', '==', true)
        );
    }

    fetchTransmissions() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore
                .collectionRef<TransmissionDto>('/refTransmissions')
                .where('active', '==', true)
                .orderBy('sortOrder')
        );
    }

    fetchBody() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<BodyDto>('/refBody').where('active', '==', true).orderBy('sortOrder')
        );
    }

    fetchCatalyticConverters() {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<CatalyticConverterDataDto>('/refCatalyticConverters'),
            (snapshot) => ({
                code: snapshot.data().code,
                name: snapshot.data().name,
                key: snapshot.id,
            })
        );
    }

    fetchSellerIntegrations = async () => {
        return this.firebase.firestore.fetchOnceArray(
            this.firebase.firestore.collectionRef<SellerIntegrationDto>('/refSellerIntegrations')
        );
    };

    fetchFuelTypes() {
        return ['Gas', 'Natural Gas', 'Diesel', 'Electric', 'Hybrid'];
    }
}
