import {getRoot, Instance, SnapshotOut, types} from 'mobx-state-tree';
import type {ItemType} from './item/Item';
import {FavoriteItemDto} from '@joyrideautos/auction-core/src/dtos/FavoriteDto';

export const FavoriteRegion = types.model({
    regionId: types.string,
    sortableKey: types.string,
});

export const FavoriteAuctionSeries = types.model({
    regionId: types.string,
    auctionSeriesId: types.string,
    sortableKey: types.string,
});

export const FavoriteAuction = types.model({
    regionId: types.string,
    auctionId: types.string,
    sortableKey: types.string,
});

export const FavoriteItem = types
    .model({
        regionId: types.string,
        auctionId: types.string,
        isSeller: false,
        sortableKey: types.string,
        itemKey: types.string,
    })
    .views((self) => ({
        getItemsStore(parent: any) {
            const {regionId, auctionId} = parent;
            const root = getRoot(parent) as any;
            return root.itemsStoreFactory.getItemsStoreForAuction({regionId, auctionId});
        },

        get item(): ItemType | undefined {
            return this.getItemsStore(self).fetchItemAsync({
                regionId: self.regionId,
                auctionId: self.auctionId,
                itemId: self.itemKey,
            });
        },
    }));

export interface FavoriteRegionType extends Instance<typeof FavoriteRegion> {}
export interface FavoriteAuctionSeriesType extends Instance<typeof FavoriteAuctionSeries> {}
export interface FavoriteAuctionType extends Instance<typeof FavoriteAuction> {}
export interface FavoriteItemType extends Instance<typeof FavoriteItem> {}

export function toFavoriteItemDto(item: SnapshotOut<FavoriteItemType>): FavoriteItemDto {
    return item as FavoriteItemDto;
}

export function fromFavoriteItemDto(item: FavoriteItemDto): FavoriteItemType {
    return item as FavoriteItemType;
}

export function mapFromFavoriteItemDto(dtos: Record<string, FavoriteItemDto>): Record<string, FavoriteItemType> {
    return Object.keys(dtos).reduce<Record<string, FavoriteItemType>>((items, key) => {
        items[key] = fromFavoriteItemDto(dtos[key]);
        return items;
    }, {});
}
