import * as s from 'superstruct';
import {
    AuctionOccurrenceSettingsValidation,
    AuctionOccurrenceValidation,
} from '../types/validations/auctionOccurrenceValidation';

export const AUCTION_SCHEDULER = 'SCHEDULER';

export const AuctionPathValidation = s.type({
    regionId: s.string(),
    auctionId: s.string(),
});
export type AuctionPath = s.Infer<typeof AuctionPathValidation>;

export function isAuctionPath(input: any): input is AuctionPath {
    return input.regionId !== undefined && input.auctionId !== undefined;
}

export function auctionPathComparator(a: AuctionPath, b: AuctionPath) {
    return a.regionId === b.regionId && a.auctionId === b.auctionId;
}

export type AuctionOccurrenceSettings = s.Infer<typeof AuctionOccurrenceSettingsValidation>;
export type AuctionOccurrenceDto = s.Infer<typeof AuctionOccurrenceValidation>;

export interface AuctionOccurrenceWithId extends AuctionOccurrenceDto {
    id: string;
}
