import {Socket, Unsubscribe, WinningBidAddedListenerType, WinningBidsListenerType} from '../types';
import {ItemPath, PersistedItem} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {AutoBidDto, BidDto, BidPath, BidTypeEnum} from '@joyrideautos/auction-core/src/dtos/BidDto';
import {WinningBidDto} from '@joyrideautos/auction-core/src/dtos/WinningBidDto';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {
    RejectAllActiveOffersRPCReqData,
    HandleHighestOfferBySellerRPCReqData,
    RejectAllActiveOffersRPCResData,
    HandleHighestOfferBySellerRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/handleOfferBySellerReqTypes';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {delay, promiseTimeout} from '@joyrideautos/auction-utils/src/PromiseUtils';
import {
    isFailed,
    isSuccessful,
    WSPlaceAutoBidReq,
    WSPlaceAutoBidRes,
    WSPlaceAutoBidResSuccess,
    WSPlaceBidReq,
    WSPlaceBidRes,
    WSPlaceBidResSuccess,
} from '@joyrideautos/auction-core/src/types/requests/WebSocketsReqTypes';
import {PLACE_BID_TIMEOUT_IN_MS} from '@joyrideautos/auction-core/src/constants/Constants';
import {BaseService} from './BaseService';
import {DataSnapshot} from '../firebase/Database';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {
    RevertBidRPCReqData,
    RevertBidRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/bidders/revertBidReqTypes';

const convertSnapshotToWinningBid = (regionId: string, auctionId: string) => (itemSnapshot: DataSnapshot) => {
    return {...itemSnapshot.val(), itemId: itemSnapshot.key, regionId, auctionId};
};

function validateBid(bid: any): bid is BidDto {
    return bid.uid != null && typeof bid.amount === 'number' && bid.amount > 0 && bid.authType != null;
}

const snapshotToBid = (itemPath: ItemPath, isManager: boolean) => {
    const {regionId, auctionId, itemId} = itemPath;
    return (snapshot: DataSnapshot): {key: string; value: WithKey<BidDto> & AuctionPath & {item: string}} | null => {
        const bid: BidDto | null = snapshot.exists() ? snapshot.val() : null;
        if (!bid || !validateBid(bid)) {
            return null;
        }
        if (bid.bidInfo?.bidderNumber && !isManager) {
            delete bid.bidInfo.bidderNumber.email;
        }
        return {key: snapshot.key!, value: {key: snapshot.key!, item: itemId, ...bid, regionId, auctionId}};
    };
};

const convertFirebaseBidSnapshot = (
    itemPath: ItemPath,
    isManager: boolean
): ((snapshot: DataSnapshot) => {key: string; value: BidDto}[] | null) => {
    const fromSnapshot = snapshotToBid(itemPath, isManager);
    return (snapshot) => {
        const bid = fromSnapshot(snapshot);
        if (!bid) {
            return null;
        }
        return [bid];
    };
};

const convertFirebaseOfferSnapshot = (
    itemPath: ItemPath,
    isManager: boolean
): ((snapshot: DataSnapshot) => {key: string; value: BidDto}[] | null) => {
    const fromSnapshot = snapshotToBid(itemPath, isManager);
    return (snapshot) => {
        if (isManager) {
            const offers: {key: string; value: BidDto}[] = [];
            snapshot.forEach((s) => {
                offers.push(fromSnapshot(s)!);
            });
            return offers;
        } else {
            const bid = fromSnapshot(snapshot);
            return bid && [bid];
        }
    };
};

const convertFirebaseAutoBidSnapshot = (
    item: ItemPath
): ((snapshot: DataSnapshot) => {key: string; value: AutoBidDto}[]) => {
    const {regionId, auctionId} = item;
    return (snapshot) => {
        const bid = snapshot.val();
        return [{key: snapshot.key!, value: {key: snapshot.key, item: item.itemId, ...bid, regionId, auctionId}}];
    };
};

export class BidService extends BaseService {
    rejectAllActiveOffers(params: RejectAllActiveOffersRPCReqData): Promise<void> {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_BIDS_REJECTY_ALL_ACTIVE_OFFERS)<
            RejectAllActiveOffersRPCReqData,
            RejectAllActiveOffersRPCResData
        >(params);
    }

    handleHighestOfferBySeller(params: HandleHighestOfferBySellerRPCReqData): Promise<void> {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_BIDS_HANDLE_HIGHEST_OFFER_BY_SELLER)<
            HandleHighestOfferBySellerRPCReqData,
            HandleHighestOfferBySellerRPCResData
        >(params);
    }

    subscribeToNewBids({regionId, auctionId, itemId}: ItemPath, cb: (bid: BidDto) => void): Unsubscribe {
        return this.firebase.database.subscribeToNewlyCreated(
            `/${regionId}/bids/${auctionId}/${itemId}`,
            (snapshot) => {
                if (!snapshot) {
                    return;
                }
                const bidRef = snapshot.ref;
                let unsubscribe: Unsubscribe | null = null;
                const bidInfoCb = (snap: DataSnapshot) => {
                    const bid: BidDto | null = snap.exists() ? snap.val() : null;

                    if (bid?.bidInfo && 'accepted' in bid.bidInfo) {
                        cb(bid);
                        if (unsubscribe != null) {
                            unsubscribe();
                            unsubscribe = null;
                        }
                    }
                };
                unsubscribe = this.firebase.database.subscribeToSnapshot(bidRef, bidInfoCb);
            }
        );
    }

    subscribeToNewBidder(regionId: string, auctionId: string, itemId: string, cb: () => void): Unsubscribe {
        const bidderNumbersRef = `/${regionId}/biddersNumbers/${auctionId}/${itemId}`;
        const currentUser = this.firebase.auth.currentUser;

        const callback = (snapshot: DataSnapshot | null) => {
            if (snapshot?.key !== currentUser?.uid) {
                cb();
            }
        };
        return this.firebase.database.subscribeToNewlyCreated(bidderNumbersRef, callback);
    }

    async fetchWinningBid(regionId: string, auctionId: string, itemId: string): Promise<WinningBidDto | undefined> {
        try {
            const snapshot = await this.firebase.database.fetchOnceSnapshot(
                `/${regionId}/results/${auctionId}/${itemId}`
            );
            if (snapshot.exists()) {
                const winningBidConverter = convertSnapshotToWinningBid(regionId, auctionId);
                return winningBidConverter(snapshot);
            }
            return;
        } catch (e: any) {
            this.logger.log(e.message);
            return;
        }
    }

    fetchAllItemsWithOffers(sellerId: string): Promise<any> {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_BIDS_FETCH_ALL_ITEMS_WITH_OFFERS)<
            {
                sellerId: string;
            },
            any
        >({sellerId});
    }

    async addBid(
        uid: string,
        regionId: string,
        auctionId: string,
        itemId: string,
        amount: number,
        paddleNumber?: string
    ): Promise<string> {
        const bidsRef = `/${regionId}/bids/${auctionId}/${itemId}`;
        const bid: BidDto = {
            uid,
            authType: 'USER',
            amount,
            paddleNumber: paddleNumber ?? undefined,
            type: BidTypeEnum.AUCTION,
            autobidId: undefined,
            bidInfo: undefined,
            createdAt: this.firebase.database.serverTimestamp(),
        };
        const newBidRef = await this.firebase.database.pushValues(bidsRef, bid);
        return newBidRef!.key!;
    }

    async addBidSocketIO(
        socket: Socket,
        uid: string,
        regionId: string,
        auctionId: string,
        itemId: string,
        amount: number,
        paddleNumber?: string
    ): Promise<string> {
        const placeBidPromise = promiseTimeout(
            new Promise<WSPlaceBidRes>((resolve) => {
                console.log('addBidSocketIO: before socket emit', new Date().toJSON());
                socket.emit(
                    'bid:placeBid',
                    {
                        regionId,
                        auctionId,
                        itemId,
                        amount,
                        paddleNumber,
                        type: BidTypeEnum.AUCTION,
                    } as WSPlaceBidReq,
                    (callbackData: WSPlaceBidRes) => {
                        console.log('addBidSocketIO: after socket emit', new Date().toJSON());
                        resolve(callbackData);
                    }
                );
            }),
            PLACE_BID_TIMEOUT_IN_MS
        );

        const placeBidResult = await placeBidPromise;
        if (isSuccessful<WSPlaceBidResSuccess>(placeBidResult) && placeBidResult.bidRef) {
            const bidKey = placeBidResult.bidRef;
            const bidRef = `/${regionId}/bids/${auctionId}/${itemId}/${bidKey}`;
            let callback: (bidSnapshot: any) => any = () => {};
            let unsubscriber: Unsubscribe | null = null;
            const bidPromise = new Promise<string>((resolve) => {
                callback = (bidSnapshot: any) => {
                    if (bidSnapshot.child('bidInfo/accepted').exists()) {
                        resolve(bidKey);
                    }
                };
                unsubscriber = this.firebase.database.subscribeToSnapshot(bidRef, callback);
            });
            const result = await Promise.race([delay(PLACE_BID_TIMEOUT_IN_MS).then(() => bidKey), bidPromise]).finally(
                () => {
                    if (unsubscriber) {
                        unsubscriber();
                        unsubscriber = null;
                    }
                }
            );
            return result;
        } else {
            return Promise.reject(placeBidResult);
        }
    }

    async addOffer(
        uid: string,
        regionId: string,
        auctionId: string,
        itemId: string,
        amount: number,
        paddleNumber?: string
    ): Promise<string> {
        const bidsRef = `/${regionId}/offers/${auctionId}/${itemId}/${uid}`;
        const offer: BidDto = {
            uid,
            amount,
            paddleNumber: paddleNumber || undefined,
            type: BidTypeEnum.OFFER,
            authType: 'USER',
            autobidId: undefined,
            bidInfo: undefined,
            createdAt: this.firebase.database.serverTimestamp(),
        };
        const newBidRef = await this.firebase.database.pushValues(bidsRef, offer);
        return newBidRef!.key!;
    }

    async cancelAutoBid(regionId: string, auctionId: string, itemId: string): Promise<void> {
        try {
            await this.firebase.rpcService.call(FeReqRouteEnum.API_BID_CANCEL_AUTO_BID)({regionId, auctionId, itemId});
        } catch (e: any) {
            this.logger.log(e.message);
        }
    }

    async checkAuctionBiddingEnded(regionId: string, auctionId: string): Promise<void> {
        try {
            await this.firebase.rpcService.call(FeReqRouteEnum.API_BID_CHECK_AUCTION_BIDDING_ENDED)({
                regionId,
                auctionId,
            });
        } catch (e: any) {
            this.logger.log(e.message);
        }
    }

    async checkItemBiddingEnded(regionId: string, auctionId: string, itemId: string): Promise<boolean> {
        const _ref = `/${regionId}/bidEndLocks/${auctionId}/${itemId}`;
        const snapshot = await this.firebase.database.fetchOnce<any>(_ref);
        return promiseTimeout(
            new Promise<boolean>((resolve) => {
                if (!snapshot?.exists()) {
                    this.firebase.database
                        .runTransaction(_ref, (snapshot) => {
                            if (!snapshot) {
                                return {
                                    sv: this.firebase.database.serverTimestamp(),
                                };
                            }
                            return snapshot;
                        })
                        .then(() => {
                            let unsubscriber: Unsubscribe | null = null;
                            const callback = (s: DataSnapshot) => {
                                if (!s.exists()) {
                                    //console.log('unsubscribed from ', ref.toString())
                                    if (unsubscriber != null) {
                                        unsubscriber();
                                        unsubscriber = null;
                                    }
                                    resolve(true);
                                }
                            };
                            //too much noise and seems to be working (subscribe/unsubscribe) correctly
                            //console.log('subscribed to ', ref.toString())
                            unsubscriber = this.firebase.database.subscribeToSnapshot(_ref, callback);
                        })
                        .catch((e) => console.log(e));
                } else {
                    // only once
                    let unsubscriber: Unsubscribe | null = null;
                    const childRemovedCallback = () => {
                        if (unsubscriber != null) {
                            unsubscriber();
                            unsubscriber = null;
                        }
                        resolve(true);
                    };
                    unsubscriber = this.firebase.database.onChildRemoved(_ref, childRemovedCallback);
                }
            }),
            1000
        );
    }

    async createNewAutoBid(
        uid: string,
        {regionId, auctionId, itemId}: ItemPath,
        amount: number,
        multibid?: boolean
    ): Promise<string | null | undefined> {
        const createdAt = this.firebase.database.serverTimestamp();
        const autoBid: AutoBidDto = multibid
            ? {
                  uid,
                  amount,
                  multibid,
                  authType: 'USER',
                  createdAt,
              }
            : {
                  uid,
                  amount,
                  authType: 'USER',
                  createdAt,
              };
        const newBidRef = await this.firebase.database.pushValues(
            `/${regionId}/autobids/${auctionId}/${itemId}`,
            autoBid
        );
        return newBidRef?.key;
    }

    async createNewAutoBidSocketIO(
        socket: Socket,
        {regionId, auctionId, itemId}: ItemPath,
        amount: number,
        multibid?: boolean
    ) {
        const autoBid: WSPlaceAutoBidReq = multibid
            ? {
                  regionId,
                  auctionId,
                  itemId,
                  amount,
                  multibid,
              }
            : {
                  regionId,
                  auctionId,
                  itemId,
                  amount,
              };
        const placeAutoBidPromise = promiseTimeout(
            new Promise<WSPlaceAutoBidRes>((resolve) => {
                console.log('createNewAutoBidSocketIO: before socket emit', new Date().toJSON());
                socket.emit('bid:placeAutoBid', autoBid, (callbackData: WSPlaceAutoBidRes) => {
                    console.log('createNewAutoBidSocketIO: after socket emit', new Date().toJSON());
                    resolve(callbackData);
                });
            }),
            PLACE_BID_TIMEOUT_IN_MS
        );

        const placeAutoBidResult = await placeAutoBidPromise;
        if (isSuccessful<WSPlaceAutoBidResSuccess>(placeAutoBidResult) && placeAutoBidResult.key) {
            const key = placeAutoBidResult.key;
            let unsubscriber: Unsubscribe | null = null;
            const bidPromise = new Promise<string>((resolve) => {
                const callback = (bidSnapshot: any) => {
                    if (bidSnapshot.child('bidInfo/accepted').exists()) {
                        resolve(key);
                    }
                };
                unsubscriber = this.firebase.database.subscribeToSnapshot(
                    `/${regionId}/autobids/${auctionId}/${itemId}/${key}`,
                    callback
                );
            });
            await Promise.race([delay(PLACE_BID_TIMEOUT_IN_MS).then(() => key), bidPromise]).finally(() => {
                if (unsubscriber) {
                    unsubscriber();
                    unsubscriber = null;
                }
            });
        } else if (isFailed(placeAutoBidResult)) {
            throw new Error(placeAutoBidResult.reason ?? 'Unexpected error during creating an auto bid.');
        } else {
            throw new Error('Unexpected error during creating an auto bid.');
        }
        return placeAutoBidResult.key;
    }

    fetchBid({regionId, auctionId, itemId, bidId}: BidPath) {
        return this.firebase.database.fetchOnce<WithKey<BidDto>>(`/${regionId}/bids/${auctionId}/${itemId}/${bidId}`);
    }

    async fetchBidsMap(item: ItemPath, isManager: boolean): Promise<Map<string, BidDto>> {
        const {regionId, auctionId, itemId} = item;
        const snapshot = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/bids/${auctionId}/${itemId}`);
        return this.firebase.database.snapshotToMap(snapshot, convertFirebaseBidSnapshot(item, isManager));
    }

    async fetchPersistedBidsMap(item: WithKey<PersistedItem>): Promise<Map<string, BidDto>> {
        const result = await this.firebase.rpcService.call(FeReqRouteEnum.API_BID_GET_PERSISTED_BIDS)<
            {
                itemPersistenceKey: string;
            },
            Record<string, any>
        >({
            itemPersistenceKey: item.key,
        });
        const {regionId, auctionId} = item;
        return Object.keys(result).reduce((bids, key) => {
            bids.set(key, {...result[key], key, persistedItem: item.key, regionId, auctionId});
            return bids;
        }, new Map<string, BidDto>());
    }

    async fetchWinningBids(regionId: string, auctionId: string): Promise<{[key: string]: WinningBidDto & ItemPath}> {
        try {
            const values: {[key: string]: WinningBidDto & ItemPath} = {};
            const winningBidConverter = convertSnapshotToWinningBid(regionId, auctionId);
            const snapshot = await this.firebase.database.fetchOnceSnapshot(`/${regionId}/results/${auctionId}`);
            if (snapshot) {
                snapshot.forEach((itemSnapshot: DataSnapshot) => {
                    values[itemSnapshot.key!] = winningBidConverter(itemSnapshot);
                });
            }

            return values;
        } catch (e) {
            this.logger.log(e);
            return {};
        }
    }

    async revertBid(regionId: string, auctionId: string, itemId: string, bidKey: string) {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_BID_REVERT_BID)<
            RevertBidRPCReqData,
            RevertBidRPCResData
        >({
            regionId,
            auctionId,
            itemId,
            bidKey,
        });
    }

    subscribeToAutoBid(itemPath: ItemPath, listener: (obj: Map<string, AutoBidDto>) => void): Unsubscribe {
        const currentUser = this.firebase.auth.currentUser;
        const {regionId, auctionId, itemId} = itemPath;

        const dbRef = this.firebase.database
            .ref(`/${regionId}/autobids/${auctionId}/${itemId}`)
            .orderByChild('bidInfo/userId')
            .equalTo(currentUser?.uid || null);

        const bidValueCallback = (snapshot: DataSnapshot) => {
            listener(this.firebase.database.snapshotToMap(snapshot, convertFirebaseAutoBidSnapshot(itemPath)));
        };

        return this.firebase.database.subscribeToSnapshot(dbRef, bidValueCallback);
    }

    subscribeToBids(
        itemPath: ItemPath,
        listener: (bids: Map<string, BidDto>) => void,
        options: {isManager: boolean; limit?: number}
    ): Unsubscribe {
        const {regionId, auctionId, itemId} = itemPath;
        let ref = this.firebase.database.ref(`/${regionId}/bids/${auctionId}/${itemId}`).orderByKey();
        if (options.limit) {
            ref = ref.limitToLast(options.limit);
        }

        return this.firebase.database.subscribeToSnapshot(ref, (snapshot) =>
            listener(
                this.firebase.database.snapshotToMap(snapshot, convertFirebaseBidSnapshot(itemPath, options.isManager))
            )
        );
    }

    subscribeToInventoryBids(
        itemKey: string,
        listener: (bids: Map<string, BidDto>) => void,
        options: {limit?: number}
    ): Unsubscribe {
        let query = this.firebase.firestore.collectionRef<BidDto>(`/items/${itemKey}/bids`);
        if (options.limit) {
            query = query.orderBy('createdAt').limitToLast(options.limit);
        }

        return this.firebase.firestore.subscribeToCollection<BidDto>(query, (result) => {
            listener(
                result
                    ? Object.keys(result).reduce<Map<string, BidDto>>((bids, bidId) => {
                          bids.set(bidId, result[bidId]);
                          return bids;
                      }, new Map())
                    : new Map()
            );
        });
    }

    subscribeToOffers(
        item: ItemPath,
        isManager: boolean,
        uid: string | undefined,
        listener: (obj: Map<string, BidDto>) => void
    ): Unsubscribe {
        const {regionId, auctionId, itemId} = item;
        let dbRef: string;
        if (uid && !isManager) {
            dbRef = `/${regionId}/offers/${auctionId}/${itemId}/${uid}`;
        } else {
            dbRef = `/${regionId}/offers/${auctionId}/${itemId}`;
        }

        const bidValueCallback = (snapshot: DataSnapshot) => {
            listener(this.firebase.database.snapshotToMap(snapshot, convertFirebaseOfferSnapshot(item, isManager)));
        };

        const valueDisposer = this.firebase.database.subscribeToSnapshot(dbRef, bidValueCallback);

        return () => {
            valueDisposer();
        };
    }

    subscribeToWinningBids(
        {regionId, auctionId}: AuctionPath,
        listeners: {
            allBidsListener?: WinningBidsListenerType;
            singleBidListener: WinningBidAddedListenerType;
        }
    ): Unsubscribe {
        const winningBidsRef = `/${regionId}/results/${auctionId}`;

        const callback = (snapshot: DataSnapshot | null) => {
            if (snapshot) {
                listeners.singleBidListener(convertSnapshotToWinningBid(regionId, auctionId)(snapshot));
            }
        };
        let cancel = false;
        let childAddedUnsubscribe: Unsubscribe | null = null;
        let childChangedUnsubscribe: Unsubscribe | null = null;
        if (listeners.allBidsListener) {
            this.fetchWinningBids(regionId, auctionId)
                .then((winningBids) => {
                    if (cancel) {
                        return;
                    }
                    listeners.allBidsListener!(winningBids);

                    childAddedUnsubscribe = this.firebase.database.subscribeToSnapshot(
                        winningBidsRef,
                        callback,
                        'child_added'
                    );
                    childChangedUnsubscribe = this.firebase.database.subscribeToSnapshot(
                        winningBidsRef,
                        callback,
                        'child_changed'
                    );
                })
                .catch((e: any) => {
                    console.log(e.message);
                    listeners.allBidsListener!();
                });
        } else {
            childAddedUnsubscribe = this.firebase.database.subscribeToSnapshot(winningBidsRef, callback, 'child_added');
            childChangedUnsubscribe = this.firebase.database.subscribeToSnapshot(
                winningBidsRef,
                callback,
                'child_changed'
            );
        }

        // TODO: add special callback for this event if needed
        // const childRemovedUnsubscriber = subscribeToSnapshot(winningBidsRef, callback, 'child_removed');
        return () => {
            cancel = true;
            childAddedUnsubscribe && childAddedUnsubscribe();
            childChangedUnsubscribe && childChangedUnsubscribe();
            // childRemovedUnsubscriber();
        };
    }

    async getAllBidsCount({regionId, auctionId, itemId}: ItemPath): Promise<number> {
        return this.makeCancelable<number>(`get-all-bids-count-${regionId}-${auctionId}-${itemId}`)(async () => {
            const persistenceKeySnap = await this.database.fetchOnceSnapshot(
                `/${regionId}/items/${auctionId}/${itemId}/persistenceKey`
            );
            const persistenceKey = persistenceKeySnap.val();
            if (!persistenceKey) {
                return 0;
            }
            return this.firestore.fetchCollectionCount<BidDto>(
                this.firestore.collectionRef(`/items/${persistenceKey}/bids`)
            );
        });
    }
}
