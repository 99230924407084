import {applySnapshot, flow, Instance, types, getSnapshot, getEnv, destroy} from 'mobx-state-tree';
import AuthUser, {AuthUserAddressType, AuthUserType, PhoneVerificationStatusEnum} from '../types/UserInfo';
import {LoadingStatus} from '../utils/LoadingStatus';
import {GeoLocationStore} from './GeoLocationStore';
import {UserDto, UserInfo} from '@joyrideautos/auction-core/src/dtos/UserDto';
import {mapDBUserToUserInfo} from '@joyrideautos/auction-core/src/mappers/mapDBUserToUserInfo';
import BaseStore from './BaseStore';
import {StripeIdentityVerificationSessionStatusEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import {AuditEventTypeEnum} from '@joyrideautos/auction-core/src/types/Audit';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import {IDisposer} from 'mobx-utils';
import {observable, reaction, when} from 'mobx';
import {v4} from 'uuid';

export function createUserDisplayName(firstName: string, lastName: string): string {
    return `${firstName} ${lastName}`;
}

type UserChangedListener = {
    id: string;
    action: (user?: UserInfo) => void;
};

const AuthUserStore = BaseStore.named('AuthUserStore')
    .props({
        userInfo: types.maybe(AuthUser),
        // We keep geoLocationStore inside AuthUserStore since geoLocationStore stores info about the currently
        // logged-in user and this info is private, so should be cleared when the user logs out.
        geoLocationStore: GeoLocationStore,
    })
    .volatile(() => ({
        disposer: null as null | IDisposer,
        loadingStatus: new LoadingStatus(),
        signOutLoadingStatus: new LoadingStatus(),
        userChangedListeners: observable.array<UserChangedListener>(),
        disposers: [] as IDisposer[],
    }))
    .views((self) => ({
        get needsEmailVerification() {
            if (!self.userInfo) {
                return false;
            }
            return !self.userInfo.emailVerified;
        },
        get isIdentityVerified() {
            return (
                self.userInfo?.identityVerifiedByStripe?.status ===
                    StripeIdentityVerificationSessionStatusEnum.VERIFIED ||
                Boolean(self.userInfo?.identityVerifiedByAdmin)
            );
        },
        needsPhoneVerification() {
            return self.userInfo && !self.userInfo.phoneVerified;
        },
    }))
    .actions((self) => ({
        isAdmin: flow(function* (uid?: string) {
            try {
                const id = uid || (self.userInfo && self.userInfo.uid);
                if (id) {
                    return !!(yield self.userService.userIsAdmin(id));
                }
                return false;
            } catch (e) {
                return false;
            }
        }),
        addUserChangedListener(listener: UserChangedListener) {
            self.userChangedListeners.push(listener);
            if (self.userInfo) {
                listener.action(self.userInfo && getSnapshot(self.userInfo));
            }
        },
        removeUserChangedListener(listener: UserChangedListener) {
            self.userChangedListeners.replace(self.userChangedListeners.filter((l) => l.id !== listener.id));
        },
        subscribeToUserChanged(cb: (params: {before: {user?: UserInfo}; after: {user?: UserInfo}}) => void) {
            let before: {user?: UserInfo} = {user: undefined};
            const onUserChanged = (user?: UserInfo) => {
                cb({before, after: {user}});
                before = {user};
            };
            const listener: UserChangedListener = {
                id: v4(),
                action: onUserChanged,
            };
            this.addUserChangedListener(listener);
            return () => this.removeUserChangedListener(listener);
        },
        load() {
            const userInfo: AuthUserType = self.userInfo!;

            self.loadingStatus.setInProgress();
            self.disposer && self.disposer();
            self.disposer = self.userService.subscribeToUserInfo(userInfo.uid, async (dbUser: UserDto) => {
                if (dbUser) {
                    const isAdmin = await this.isAdmin(userInfo.uid);
                    const {
                        winningItems: _winningItems,
                        unpaidItems: _unpaidItems,
                        ...cachedUserData
                    } = (self.userInfo && getSnapshot(self.userInfo)) ?? {};
                    const {winningItems, unpaidItems, ...userDataFromDb} = mapDBUserToUserInfo({
                        ...dbUser,
                        uid: userInfo.uid,
                    });
                    const refreshedData = {
                        ...cachedUserData,
                        ...userDataFromDb,
                        winningItems,
                        unpaidItems,
                        emailVerified: !!dbUser.emailVerifiedAt,
                        isAnonymous: userInfo.isAnonymous,
                        isAdmin,
                    };

                    applySnapshot<any>(self.userInfo!, refreshedData);
                    self.userInfo!.status.setReady();
                    await self.analyticsService.setUserId(userInfo.uid);
                    self.fullstoryService.identify(userInfo.uid, {
                        displayName: `${dbUser.firstName} ${dbUser.lastName}`,
                        email: dbUser.email,
                    });
                    self.sentryService.configureScope(function (scope) {
                        scope.setUser({id: userInfo.uid, email: dbUser.email});
                    });
                }
                self.loadingStatus.setReady();
            });
        },
    }))
    .actions((self) => ({
        clearUserData: flow(function* () {
            if (!self.userInfo || self.userInfo.isAnonymous) {
                return;
            }
            self.fullstoryService.anonymize();
            self.disposer && self.disposer();
            self.rootStore.sellerRoleStore.clear();
            self.rootStore.eventsStore?.clear();
            self.rootStore.favoritesStore.unsubscribe();
            self.rootStore.wonItemsStore.unsubscribe();
            yield self.sellerSessionStorage.clear();
            destroy(self.userInfo);
            self.userInfo = undefined;
            self.loadingStatus = new LoadingStatus();
        }),
        async signOut() {
            if (!self.userInfo || self.userInfo.isAnonymous) {
                return;
            }
            try {
                self.signOutLoadingStatus.setInProgress();
                if (self.userInfo.adminEmail) {
                    await self.rootStore.auditService
                        .createEvent({
                            type: AuditEventTypeEnum.IMPERSONATE_SESSION_END,
                            payload: {
                                adminUid: self.userInfo.adminUid!,
                                uid: self.userInfo.uid,
                                sessionId: self.userInfo.impersonatedSessionId!,
                            },
                        })
                        .catch(logError());
                }
                await this.clearUserData();
                await self.authUserService.signOut();
                self.signOutLoadingStatus.setReady();
            } catch (e: any) {
                self.signOutLoadingStatus.setError(e.message);
            }
        },
    }))
    .actions((self) => {
        return {
            onAuthentication(authUser: UserInfo) {
                if (
                    self.loadingStatus.isInProgress ||
                    (self.userInfo?.uid === authUser.uid && !self.userInfo?.isAnonymous)
                ) {
                    return;
                }
                self.userInfo = AuthUser.create({...authUser}, getEnv(self));
                self.load();
            },
            onMissingAuthentication(anonymousUser: UserInfo) {
                self.userInfo = AuthUser.create({...anonymousUser});
                self.loadingStatus.setReady();
            },
            updateEmail: flow(function* (newEmail: string): Generator<Promise<any>, any, any> {
                if (self.userInfo === undefined || self.userInfo.isAnonymous) {
                    return false;
                }
                let isUpdated = false;
                const emailLowerCase = newEmail.toLocaleLowerCase();
                try {
                    isUpdated = yield self.authUserService.updateUserEmail(emailLowerCase);
                } catch (e: any) {
                    // To set a user's email address, the user must have signed in recently.
                    // https://firebase.google.com/docs/auth/web/manage-users#set_a_users_email_address
                    // https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
                    if (e.code === 'auth/requires-recent-login') {
                        console.log('need to re-authenticate the user');
                        yield self.signOut();
                    } else {
                        throw e;
                    }
                }
                if (isUpdated) {
                    self.userInfo!.updateEmail(emailLowerCase);
                    yield self.userService.updateUserInfo({
                        uid: self.userInfo!.uid,
                        email: emailLowerCase,
                        emailVerifiedAt: '',
                    });
                    yield self.userService.sendEmailVerificationForUser(self.userInfo.uid);
                    return true;
                } else {
                    return false;
                }
            }),
            updatePassword: flow(function* (
                currentPassword: string,
                newPassword: string
            ): Generator<Promise<any>, any, any> {
                if (self.userInfo === undefined || self.userInfo.isAnonymous) {
                    return false;
                }

                const result = yield self.authUserService.reauthenticateUser(currentPassword);
                if (!result) {
                    return false;
                }
                return yield self.userService.updateUserPassword(newPassword);
            }),
            updateName: flow(function* (firstName: string, lastName: string): Generator<Promise<any>, any, any> {
                if (self.userInfo === undefined || self.userInfo.isAnonymous) {
                    return false;
                }
                yield self.authUserService.updateUserProfile({displayName: createUserDisplayName(firstName, lastName)});
                yield self.userService.updateUserInfo({uid: self.userInfo!.uid, firstName, lastName});
                return true;
            }),
            updateUserInfo: flow(function* (
                userInfo: Partial<UserDto> & {uid: string}
            ): Generator<Promise<any>, any, any> {
                if (self.userInfo === undefined || self.userInfo.isAnonymous) {
                    return false;
                }
                const currentUserInfo = getSnapshot(self.userInfo);
                try {
                    const optimisticUpdate = {...currentUserInfo, ...userInfo} as AuthUserType;
                    applySnapshot(self.userInfo, optimisticUpdate as any);
                    yield self.userService.updateUserInfo(userInfo);
                    return true;
                } catch (e) {
                    applySnapshot(self.userInfo, currentUserInfo);
                    throw e;
                }
            }),
            updateAddress: flow(function* (zipcode: string, address: AuthUserAddressType) {
                if (self.userInfo === undefined || self.userInfo.isAnonymous) {
                    return false;
                }
                yield self.userService.updateUserInfo({
                    uid: self.userInfo!.uid,
                    zipcode,
                    settings: {
                        address: address,
                    },
                });
            }),
            sendPhoneVerificationCode: flow(function* (phoneNumber?: string, uid?: string) {
                if (!self.userInfo) {
                    console.error('trying to send verification code for not authenticated user');
                    return;
                }
                console.log('send code', self.userInfo);
                const phone = phoneNumber || self.userInfo.phone;
                const userId = uid || self.userInfo.uid;
                if (phone) {
                    const result = yield self.userService.sendVerificationCode(phone, userId);
                    console.log('send code response', result);
                }
            }),
            checkPhoneVerificationCode: flow(function* (code: string, updateUserProfile: boolean) {
                if (!code) {
                    throw Error('Illegal argument: code argument is undefined');
                }
                if (!self.userInfo) {
                    console.error('trying to send verification code for not authenticated user');
                    throw Error('Illegal status: user is not authenticated');
                }
                console.log('check code', code, self.userInfo);

                const result = yield self.userService.checkVerificationCode(code, updateUserProfile);
                console.log('check code response', result);
                return result;
            }),
            cancelPhoneVerification: flow(function* (uid: string) {
                const result = yield self.userService.cancelPhoneVerification(uid);
                console.log('cancel response', result);
            }),
        };
    })
    .views((self) => ({
        phoneVerificationStatus() {
            const {userInfo} = self;
            if (!userInfo) {
                return;
            }
            if (userInfo.phone && !userInfo.phoneVerified && !userInfo.phoneVerificationRequestId) {
                return PhoneVerificationStatusEnum.NOT_VERIFIED;
            }
            if (userInfo.phoneVerificationRequestId) {
                return PhoneVerificationStatusEnum.REQUEST_SENT;
            }
            if (!userInfo.phone) {
                return PhoneVerificationStatusEnum.NO_PHONE;
            }
            if (userInfo.phoneVerified && !userInfo.phoneVerificationRequestId) {
                return PhoneVerificationStatusEnum.VERIFIED;
            }
            return;
        },
        get isLoggedInUserAnonymous(): boolean | undefined {
            return self.userInfo?.isAnonymous;
        },
        get isCurrentUserAdmin(): boolean {
            return self.userInfo?.isAdmin || false;
        },
    }))
    .actions((self) => ({
        afterCreate() {
            self.disposers.push(
                self.authUserService.onAuthUserListener(self.onAuthentication, self.onMissingAuthentication)
            );
            self.disposers.push(
                when(
                    () => !!self.userInfo,
                    () =>
                        self.disposers.push(
                            reaction(
                                () => self.userInfo,
                                (userInfo) => {
                                    self.userChangedListeners.forEach((l) =>
                                        l.action(userInfo && getSnapshot(userInfo))
                                    );
                                }
                            )
                        )
                )
            );
        },
        beforeDestroy() {
            self.disposer && self.disposer();
            self.disposers.forEach((d) => d());
        },
    }));

export default AuthUserStore;

export interface AuthUserStoreType extends Instance<typeof AuthUserStore> {}

export interface HasAuthUserStore {
    authUserStore: AuthUserStoreType;
}

export const WithAuthUserStore = types.model('WithAuthUserStore', {
    authUserStore: AuthUserStore,
});
