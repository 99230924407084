import {Instance, types} from 'mobx-state-tree';
import BaseStore from '../BaseStore';
import {createRefsCodeAndName} from './RefsCodeAndName';
import RefsMake from './RefsMake';
import RefsSellerIntegration from './RefsSellerIntegration';
import {LicensePlaceStateDto} from '@joyrideautos/auction-core/src/dtos/RefsDto';

const licensePlaceToCodeAndName = ({id, name}: LicensePlaceStateDto) => ({
    code: id,
    name,
});

const RefsStore = BaseStore.named('RefsStore').props({
    makesAndModels: types.optional(RefsMake, {}),
    sellerIntegrations: types.optional(RefsSellerIntegration, {}),
    licensePlateStates: createRefsCodeAndName(
        (rootStore) => rootStore.refsService.fetchLicensePlateStates.bind(rootStore.refsService),
        licensePlaceToCodeAndName
    ),
    catalyticConverters: createRefsCodeAndName((rootStore) =>
        rootStore.refsService.fetchCatalyticConverters.bind(rootStore.refsService)
    ),
    transmissions: createRefsCodeAndName((rootStore) =>
        rootStore.refsService.fetchTransmissions.bind(rootStore.refsService)
    ),
});

export default RefsStore;

export interface RefsStoreType extends Instance<typeof RefsStore> {}

export interface HasRefsStore {
    refsStore: RefsStoreType;
}
