import React, {ComponentType, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Unsubscribe} from 'firebase/auth';
import type {AuthUserService} from '@joyrideautos/ui-services/src/services/AuthUserService';
import {AuthUserStoreType} from '@joyrideautos/ui-models/src/stores/AuthUserStore';
import {autorun} from 'mobx';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import {UserInfo} from '@joyrideautos/auction-core/src/dtos/UserDto';
import {Router, useRouter} from '../router/Router';

/*
import {IReactComponent} from 'mobx-react';

type WithAuthorization<T extends IReactComponent> = (
    authUserStore: AuthUserStoreType,
    fallback?: (props: any) => void
) => (Component: ComponentType<T>) => FunctionComponent<T>;
*/

export const useAuthorization = (allowAnonymous?: boolean) => {
    const {authUserStore, authUserService} = useRootStore();
    const [isUserAuthorized, setUserAuthorized] = useState<boolean | undefined>(undefined);
    const [authUser, setAuthUser] = useState(authUserStore.userInfo);

    const checkUserAuthorized = useCallback(
        (authUser: UserInfo) => {
            return allowAnonymous ? true : !!authUser && !authUser.isAnonymous;
        },
        [allowAnonymous]
    );

    useEffect(() => {
        return autorun(() => {
            const {userInfo: authUser, loadingStatus} = authUserStore;
            if (!loadingStatus.isReady || !authUser) {
                return;
            }
            setUserAuthorized(checkUserAuthorized(authUser));
            setAuthUser(authUserStore.userInfo);
        });
    }, [authUserStore, checkUserAuthorized, authUserService]);

    return {isUserAuthorized, authUser};
};

export function withAuthorization<T = any>(
    {authUserStore, authUserService}: {authUserStore: AuthUserStoreType; authUserService: AuthUserService},
    fallback?: (router: Router) => void,
    notAuthComponent: JSX.Element | null = null
): (Component: ComponentType<T>) => FunctionComponent<T> {
    return (Component) => {
        const allowAnonymous = !fallback;
        let listener: Unsubscribe | undefined;

        const checkUserAuthorized = () => {
            const {userInfo: authUser} = authUserStore;
            return allowAnonymous ? true : !!authUser && !authUser.isAnonymous;
        };

        const checkCondition = (router: Router) => {
            if (!checkUserAuthorized()) {
                fallback && fallback(router);
            }
        };

        const signOut = () => {
            return authUserStore.signOut();
        };

        return (props: any) => {
            const [isUserAuthorized, setUserAuthorized] = useState(false);
            const [authUser, setAuthUser] = useState(authUserStore.userInfo);
            const router = useRouter();
            useEffect(() => {
                listener = authUserService.onAuthUserListener(authUserStore.onAuthentication, (authUser) => {
                    authUserStore.onMissingAuthentication(authUser);
                    checkCondition(router);
                });
                const disposer = autorun(() => {
                    setUserAuthorized(checkUserAuthorized());
                    setAuthUser(authUserStore.userInfo);
                });
                return () => {
                    listener && listener();
                    listener = undefined;
                    disposer();
                };
            }, []);

            return isUserAuthorized ? (
                <Component
                    {...props}
                    authUser={authUser}
                    signOut={(callback: () => void) => {
                        signOut()
                            .then(() => callback())
                            .catch((e) => console.log(e));
                    }}
                />
            ) : (
                notAuthComponent
            );
        };
    };
}
