import {getRoot, Instance, types} from 'mobx-state-tree';
import type {ItemType} from './item/Item';
import {WonItemDto} from '@joyrideautos/auction-core/src/dtos/WonItemDto';

export const WonItem = types
    .model('WonItem', {
        id: types.identifier,
        itemId: types.string,
        sellerId: types.maybe(types.string),
        auctionId: types.string,
        regionId: types.string,
    })
    .views((self) => ({
        getItemsStore(parent: any) {
            const {regionId, auctionId} = parent;
            const root = getRoot(parent) as any;
            return root.itemsStoreFactory.getItemsStoreForAuction({regionId, auctionId});
        },

        get item(): ItemType | undefined {
            return this.getItemsStore(self).fetchItemAsync({...self});
        },
    }));
export interface WonItemType extends Instance<typeof WonItem> {}

export function fromDto(item: WonItemDto): WonItemType {
    return item as WonItemType;
}
