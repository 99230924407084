import {WinningBidFees} from '../dtos/WinningBidDto';
import {ImpoundFees} from '../dtos/ItemDto';
import {FeeSchedule, StepFunctionFee} from '../dtos/SellerDto';
import {AuctionOccurrenceSettings} from '../dtos/AuctionOccurrenceDto';
import {FeePriceDto} from '../dtos/FeePriceDto';
import {BidIncludesFeesBuyerFeeStepFunctionBreakdownCalculator} from './BidIncludesFeesBuyerFeeStepFunctionBreakdownCalculator';
import {DEFAULT_IS_STORAGE_TAX_ENABLED} from '../dtos/AuctionSeriesDto';
import {createStorageTaxCalculator, StorageTaxCalculator} from './storageTaxCalculator/StorageTaxCalculator';
import {mapObjectValues} from '@joyrideautos/auction-utils/src/objectUtils';
import {BidIncludesFeesBuyerFeePercentBreakdownCalculator} from './BidIncludesFeesBuyerFeePercentBreakdownCalculator';
import {BidExcludesFeesBreakdownCalculator} from './BidExcludesFeesBreakdownCalculator';
import {StepFunctionBuyerFeeCalculator} from './StepFunctionBuyerFeeCalculator';
import {PercentBuyerFeeCalculator} from './PercentBuyerFeeCalculator';
import {PlatformFeeCalculator} from './platformFeeCalculator/types';
import {WinningBidFeesInMoney} from './types';
import {getDepositCalculationStrategy} from './DepositCalculationStrategy';
import {SalesTaxCalculator} from './SalesTaxCalculator';
import {AdminFeeCalculator} from './AdminFeeCalculator';
import {PerVehicleFee} from './PerVehicleFee';
import {Money} from '../types/Money';
import {moneyToDollars} from './utils';
import createPlatformFeeCalculator from './platformFeeCalculator/createPlatformFeeCalculator';
import {PlatformFeeScheduleDto} from '../dtos/PlatformFeeScheduleDto';

export function calculateBuyerPercentFeesSalePrice({
    platformFeeCalculator,
    adminFeeCalculator,
    buyerFeePerc,
    feePrice,
    salesTaxCalculator,
    isChargeBuyerFee = true,
    storageTaxCalculator,
    isExcludeSellerFeesFromSalesTax,
}: {
    platformFeeCalculator: PlatformFeeCalculator;
    adminFeeCalculator: AdminFeeCalculator;
    buyerFeePerc: number;
    feePrice?: FeePriceDto | null;
    salesTaxCalculator: SalesTaxCalculator;
    isChargeBuyerFee?: boolean;
    storageTaxCalculator: StorageTaxCalculator;
    isExcludeSellerFeesFromSalesTax: boolean;
}) {
    const bidIncludesFeesBreakdownCalculator = new BidIncludesFeesBuyerFeePercentBreakdownCalculator(
        new PercentBuyerFeeCalculator(buyerFeePerc),
        platformFeeCalculator,
        storageTaxCalculator,
        salesTaxCalculator,
        adminFeeCalculator,
        new PerVehicleFee(feePrice),
        {
            isChargeBuyerFee,
            isExcludeSellerFeesFromSalesTax,
        }
    );

    const bidExcludesFeesBreakdownCalculator = new BidExcludesFeesBreakdownCalculator(
        new PercentBuyerFeeCalculator(buyerFeePerc),
        platformFeeCalculator,
        storageTaxCalculator,
        salesTaxCalculator,
        adminFeeCalculator,
        new PerVehicleFee(feePrice),
        {
            isChargeBuyerFee,
            isExcludeSellerFeesFromSalesTax,
        }
    );
    return function (bidAmount: Money, bidIncludesFee = true): WinningBidFeesInMoney {
        return bidIncludesFee
            ? bidIncludesFeesBreakdownCalculator.calculate(bidAmount)
            : bidExcludesFeesBreakdownCalculator.calculate(bidAmount);
    };
}

export function calculateBuyerStepFeeSalePrice({
    platformFeeCalculator,
    adminFeeCalculator,
    stepFunctionFeeArr,
    feePrice,
    salesTaxCalculator,
    isChargeBuyerFee = true,
    minBidAmount,
    storageTaxCalculator,
    isExcludeSellerFeesFromSalesTax,
}: {
    platformFeeCalculator: PlatformFeeCalculator;
    adminFeeCalculator: AdminFeeCalculator;
    stepFunctionFeeArr: StepFunctionFee[];
    feePrice?: FeePriceDto | null;
    salesTaxCalculator: SalesTaxCalculator;
    isChargeBuyerFee?: boolean;
    minBidAmount?: Money;
    storageTaxCalculator: StorageTaxCalculator;
    isExcludeSellerFeesFromSalesTax: boolean;
}) {
    const bidIncludesFeesBuyerFeesBreakdownCalculator = new BidIncludesFeesBuyerFeeStepFunctionBreakdownCalculator(
        StepFunctionBuyerFeeCalculator.getInstance(stepFunctionFeeArr),
        platformFeeCalculator,
        storageTaxCalculator,
        salesTaxCalculator,
        adminFeeCalculator,
        new PerVehicleFee(feePrice),
        {
            isChargeBuyerFee,
            minBidAmount,
            isExcludeSellerFeesFromSalesTax,
        }
    );
    const bidExcludesFeesBreakdownCalculator = new BidExcludesFeesBreakdownCalculator(
        StepFunctionBuyerFeeCalculator.getInstance(stepFunctionFeeArr),
        platformFeeCalculator,
        storageTaxCalculator,
        salesTaxCalculator,
        adminFeeCalculator,
        new PerVehicleFee(feePrice),
        {
            isChargeBuyerFee,
            isExcludeSellerFeesFromSalesTax,
        }
    );
    return function (bidAmount: Money, bidIncludesFee: boolean): WinningBidFeesInMoney {
        return bidIncludesFee
            ? bidIncludesFeesBuyerFeesBreakdownCalculator.calculate(bidAmount)
            : bidExcludesFeesBreakdownCalculator.calculate(bidAmount);
    };
}

/**
 * @param amount
 * @param feeSchedule
 * @param feePrice
 * @param auctionSettings
 * @param impoundFees
 * @param settings {isUserPreApproved: boolean, isLicensedBuyer: boolean, isManagerForSeller: number}
 * @param platformFeeSchedule
 */
export function calculateFees({
    amount,
    feeSchedule,
    feePrice,
    auctionSettings,
    settings,
    impoundFees,
    platformFeeSchedule,
}: {
    amount: number;
    feeSchedule?: FeeSchedule;
    feePrice?: FeePriceDto | null;
    auctionSettings: AuctionOccurrenceSettings;
    settings?: {
        isUserPreApproved?: boolean;
        isLicensedBuyer?: boolean;
        isManagerForSeller?: boolean;
        isExemptFromSalesTax?: boolean;
    };
    impoundFees?: ImpoundFees;
    platformFeeSchedule?: PlatformFeeScheduleDto | null;
}): WinningBidFees {
    const isUserPreApproved = settings?.isUserPreApproved ?? false;
    const isLicensedBuyer = settings?.isLicensedBuyer ?? false;
    const isExemptFromSalesTax = settings?.isExemptFromSalesTax ?? false;
    const isExcludeSellerFeesFromSalesTax = auctionSettings?.isExcludeSellerFeesFromSalesTax ?? false;
    const isChargeBuyerFee = auctionSettings?.isChargeBuyerFee ?? true;
    const isStorageTaxEnabled = auctionSettings.isStorageTaxEnabled ?? DEFAULT_IS_STORAGE_TAX_ENABLED;
    const bidIncludesFee = auctionSettings.bidIncludesFees !== false;

    const storageTaxCalculator = createStorageTaxCalculator({
        auctionSettings,
        impoundFees,
        isStorageTaxEnabled,
    });

    const platformFeeCalculator = createPlatformFeeCalculator({
        auctionSettings,
        platformFeeSchedule,
        isManagerForSeller: settings?.isManagerForSeller,
    });

    const salesTaxCalculator = SalesTaxCalculator.getInstanceWithAuctionSettings(auctionSettings, {
        isExemptFromSalesTax,
    });

    let fees: WinningBidFeesInMoney;

    if (
        typeof feeSchedule !== 'undefined' &&
        (!isLicensedBuyer || !feeSchedule.licensedBuyerExcludeFixedFee || !feeSchedule.licensedBuyerExcludeVariableFee)
    ) {
        const adminFeeCalculator = AdminFeeCalculator.getInstanceWithFeeSchedule(feeSchedule, {isLicensedBuyer});

        if (isLicensedBuyer && feeSchedule.licensedBuyerExcludeVariableFee) {
            fees = calculateBuyerPercentFeesSalePrice({
                platformFeeCalculator,
                adminFeeCalculator,
                buyerFeePerc: 0,
                feePrice,
                salesTaxCalculator,
                isChargeBuyerFee,
                storageTaxCalculator,
                isExcludeSellerFeesFromSalesTax,
            })(Money.fromDollars(amount), bidIncludesFee);
        } else {
            fees =
                typeof feeSchedule.stepFunctionFee !== 'undefined'
                    ? calculateBuyerStepFeeSalePrice({
                          platformFeeCalculator,
                          adminFeeCalculator,
                          stepFunctionFeeArr: feeSchedule.stepFunctionFee,
                          feePrice,
                          salesTaxCalculator,
                          isChargeBuyerFee,
                          minBidAmount: auctionSettings.minimumBid
                              ? Money.fromDollars(auctionSettings.minimumBid)
                              : undefined,
                          storageTaxCalculator,
                          isExcludeSellerFeesFromSalesTax,
                      })(Money.fromDollars(amount), bidIncludesFee)
                    : calculateBuyerPercentFeesSalePrice({
                          platformFeeCalculator,
                          adminFeeCalculator,
                          buyerFeePerc: feeSchedule.percentFee ?? 0,
                          feePrice,
                          salesTaxCalculator,
                          isChargeBuyerFee,
                          storageTaxCalculator,
                          isExcludeSellerFeesFromSalesTax,
                      })(Money.fromDollars(amount), bidIncludesFee);
        }
    } else {
        fees = calculateBuyerPercentFeesSalePrice({
            platformFeeCalculator,
            adminFeeCalculator: AdminFeeCalculator.getEmptyInstance(),
            buyerFeePerc: 0,
            feePrice,
            salesTaxCalculator,
            isChargeBuyerFee,
            storageTaxCalculator,
            isExcludeSellerFeesFromSalesTax,
        })(Money.fromDollars(amount), bidIncludesFee);
    }

    // deposit
    const depositCalculator = getDepositCalculationStrategy(auctionSettings, isUserPreApproved);
    if (depositCalculator) {
        fees.deposit = depositCalculator(fees);
    }

    return mapObjectValues(fees, moneyToDollars);
}
