import moment from 'moment';
import 'moment-timezone';

export const ONE_SEC = 1000;
export const ONE_MIN = 60 * ONE_SEC;
export const TWO_MINS = 2 * ONE_MIN;
export const ONE_HOUR = 60 * ONE_MIN;
export const TWO_HOURS = 2 * ONE_HOUR;
export const ONE_DAY = 24 * ONE_HOUR;
export const TWO_DAYS = 2 * ONE_DAY;
export const ONE_WEEK = 7 * ONE_DAY;

export const LONG_DATE_FORMAT = 'LL';
export const LONG_DATE_AT_FORMAT = 'ddd, MMM D [at] h:mm A';
export const DATE_YYMMDD = 'YY/MM/DD';
export const DATE_MMDDYY = 'MM/DD/YY';

export function hoursFrom(now: number, startTimeMs: number | null | undefined) {
    if (!startTimeMs || startTimeMs < 0) {
        throw Error(`Illegal argument: time is undefined or negative`);
    }
    const from = now < 0 ? Date.now() : now;

    const diffMs = from - startTimeMs;

    return diffMs / ONE_HOUR;
}

export function hoursToMs(hours: number): number {
    return hours * 60 * 60 * 1000;
}

export function isDateValid(date: string | null | undefined) {
    return Boolean(date && moment(Date.parse(date)).isValid());
}

export function dateFromSeconds(seconds: number | null | undefined): Date | undefined {
    return seconds != null ? new Date(seconds * 1000) : undefined;
}

export function dateFromMs(ms: number | null | undefined): Date | undefined {
    return ms != null ? new Date(ms) : undefined;
}

export function formatDateToIsoString(date: Date | undefined | null) {
    return date ? new Date(date).toISOString() : undefined;
}

export function incrementDate(date: string | Date, stepInMs: number) {
    const dateInMs = typeof date === 'string' ? Date.parse(date) : date.getTime();
    return new Date(dateInMs + stepInMs);
}

export function parseDate(
    date: string | null | undefined,
    onInvalidDate: (arg: string | null | undefined) => Date | null = () => null
): Date | null {
    if (!date) {
        return null;
    }
    const dateNum = Date.parse(date);
    if (isNaN(dateNum)) {
        return onInvalidDate(date);
    }
    return new Date(dateNum);
}

export const fallbackToPast = () => new Date(0);

export function formatAMPM(date: Date | null): string | undefined {
    if (!date) {
        return;
    }
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function monthAndDate(date: Date | null): string | undefined {
    if (!date) {
        return;
    }
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const d = date.getDate();
    const m = date.getMonth();
    return `${months[m]} ${d}`;
}

export function isDateWithinHours(date: string, hours: number) {
    const then = new Date(date);
    const now = new Date();
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    const hoursBetweenDates = msBetweenDates / ONE_HOUR;
    return hoursBetweenDates < hours;
}

export const hoursFromNow = (timeMs: number | null | undefined) => {
    if (!timeMs || timeMs < 0) {
        throw Error(`Illegal argument: time is undefined or negative`);
    }
    return Math.ceil((Date.now() - timeMs) / ONE_HOUR);
};

export const minutesFromNow = (timeMs: number | null | undefined) => {
    return hoursFromNow(timeMs) * 60;
};

export const hoursDiffPeriodAndTimestamp = (period: number, timestamp: Date | string): number => {
    return period - Math.floor(hoursFromNow(new Date(timestamp).getTime()));
};

export const formatDate = (date: moment.MomentInput | moment.MomentInput[] | null | undefined, format?: string) => {
    return typeof date !== 'undefined' && date !== null
        ? moment(Array.isArray(date) ? date[0] : date).format(format)
        : '';
};

enum TimeTokenEnum {
    s = 's',
    m = 'm',
    mm = 'mm',
    h = 'h',
    hh = 'hh',
    d = 'd',
    dd = 'dd',
    M = 'M',
    MM = 'MM',
    y = 'y',
    yy = 'yy',
}

const thresholds: {[token in TimeTokenEnum]: number} = {
    s: 60, // seconds to minute
    m: 1, // from one minute to two mins (120 secs)
    mm: 2, // from two mins to hour
    h: 1, // from one hour to two hours
    hh: 2, // hours to day
    d: 1, // from one day to two days
    dd: 2, // days to month
    M: 1, // from one month to two
    MM: 2, // months to year
    y: 1, // from one year to two years
    yy: 2,
};

export type RelativeTime = {[token in TimeTokenEnum]: string};

export const defaultRelativeTime: RelativeTime = {
    s: '%ds',
    m: '1m %ds',
    mm: '%d minutes',
    h: '1 hour %dm',
    hh: '%d hours',
    d: '1 day %dh',
    dd: '%d days',
    M: '1 month %dd',
    MM: '%d months',
    y: '1 year %d months',
    yy: '%d years',
};

const {round} = Math;

export const formatTime = (durationMs: number | null | undefined, relativeTime: RelativeTime = defaultRelativeTime) => {
    if (!durationMs) {
        return '';
    }
    if (durationMs < 0) {
        throw Error(`Illegal argument: negative time ${durationMs}`);
    }
    const duration = moment.duration(durationMs);

    const seconds = round(duration.seconds());
    const minutes = round(duration.minutes());
    const hours = round(duration.hours());
    const days = round(duration.days());
    const months = round(duration.months());
    const years = round(duration.years());

    const [relTimeToken, time]: [TimeTokenEnum, number] = (years >= thresholds.yy && [TimeTokenEnum.yy, years]) ||
        (years >= thresholds.y && [TimeTokenEnum.y, months]) ||
        (months >= thresholds.MM && [TimeTokenEnum.MM, months]) ||
        (months >= thresholds.M && [TimeTokenEnum.M, days]) ||
        (days >= thresholds.dd && [TimeTokenEnum.dd, days]) ||
        (days >= thresholds.d && [TimeTokenEnum.d, hours]) ||
        (hours >= thresholds.hh && [TimeTokenEnum.hh, hours]) ||
        (hours >= thresholds.h && [TimeTokenEnum.h, minutes]) ||
        (minutes >= thresholds.mm && [TimeTokenEnum.mm, minutes]) ||
        (minutes >= thresholds.m && [TimeTokenEnum.m, seconds]) || [TimeTokenEnum.s, seconds];

    return substituteTime(relativeTime[relTimeToken], time);
};

function substituteTime(tmpl: string | undefined, time: number) {
    return tmpl ? tmpl.replace(/(%d)/i, String(time)) : '';
}

export const formatTimeCountdown = (duration: number | null | undefined) => {
    if (!duration) {
        return '';
    }
    const durationMs = duration * ONE_SEC;
    const durationObj = moment.duration(durationMs).as('milliseconds');
    const daysInMonth = moment().daysInMonth();
    const ONE_MONTH = ONE_DAY * daysInMonth;
    if (durationMs >= ONE_MONTH) {
        const days = moment.duration(durationMs).asDays();
        return `${Math.floor(days)} d `;
    } else if (durationMs >= ONE_DAY) {
        return moment.utc(durationObj).format('D[d ]');
    } else if (durationMs >= ONE_HOUR) {
        return moment.utc(durationObj).format('H[h ]m[m ]s[s]');
    } else if (durationMs >= ONE_MIN) {
        return moment.utc(durationObj).format('m[m ]s[s]');
    } else if (durationMs >= ONE_SEC) {
        return moment.utc(durationObj).format('s[s]');
    } else {
        return '';
    }
};

export const hoursFromStartTime = (startTimeMs: number | null | undefined) => {
    if (!startTimeMs || startTimeMs < 0) {
        throw Error(`Illegal argument: time is undefined or negative`);
    }
    return moment.duration(moment().diff(moment(startTimeMs))).asHours();
};

/**
 * Format time from now to the given point of time in future
 *
 * @param timeInFutureMs timestamp in future
 */
export const formatTimeToX = (timeInFutureMs: number | null | undefined) => {
    if (!timeInFutureMs) {
        return '';
    }
    return formatTime(timeInFutureMs - Date.now());
};

/**
 * Format time passed from the given point in the past
 *
 * @param timeInPastMs timestamp in past
 */
export const formatTimeFromX = (timeInPastMs: number | null | undefined) => {
    if (!timeInPastMs) {
        return '';
    }
    if (timeInPastMs < 0) {
        throw Error(`Illegal argument: negative time duration ${timeInPastMs}`);
    }
    return formatTime(Date.now() - timeInPastMs);
};

export const compareDatesAsString = (first?: string, second?: string, reverse = false) => {
    if (!first && !second) {
        return 0;
    }
    if (!first) {
        return reverse ? -1 : 1;
    }
    if (!second) {
        return reverse ? 1 : -1;
    }
    return compareDateAsMoment(moment(first), moment(second), reverse);
};

export const compareDates = (first: Date | undefined, second: Date | undefined, reverse = false) => {
    const direction = reverse ? -1 : 1;
    if (!first && !second) {
        return 0;
    } else if (first == null) {
        return -direction;
    } else if (second == null) {
        return direction;
    }
    return compareDateAsMoment(moment(first), moment(second), reverse);
};

export const compareDateAsMoment = (first: moment.Moment, second: moment.Moment, reverse = false) => {
    if (first.isAfter(second)) {
        return reverse ? -1 : 1;
    } else if (second.isAfter(first)) {
        return reverse ? 1 : -1;
    } else {
        return 0;
    }
};

export function isDateInPast(date: string | Date) {
    return moment(date).isBefore(moment());
}

export function localTimeToUtc(time: string, tz: string, format: string) {
    return moment.tz(time, format, tz).utc().format(format);
}

export function utcTimeToLocal({
    time,
    tz,
    format,
    baseUtcDate,
}: {
    time: string;
    tz: string;
    format: string;
    baseUtcDate?: string;
}) {
    if (baseUtcDate) {
        const baseDateInTimezone = moment.tz(baseUtcDate, tz);
        const utcTimeWithOffset = moment.tz(time, format, tz).utcOffset(baseDateInTimezone.utcOffset()).format(format);
        return moment.utc(utcTimeWithOffset, format).tz(tz).format(format);
    }
    return moment.utc(time, format).tz(tz).format(format);
}

export function localTimeToLocal(time: string, tz: string, format: string) {
    return moment.tz(time, format, tz).format(format);
}

/**
 * @param: date - date to update with time in UTC
 * @param: localTime - time to add to date in timezone
 * @param: timeFormat - format of time value
 * @param: timezone - target timezone (e.g. region's timezone)
 * @return: date as string in UTC
 */
export function setTimeValueForUTCDate({
    utcDate,
    localTime,
    timeFormat,
    timezone,
}: {
    utcDate: string;
    localTime: string;
    timeFormat: string;
    timezone: string;
}) {
    // create a time object in timezone
    const timeObj = moment.tz(localTime, timeFormat, timezone);

    // calculate new date in timezone
    const dateObj = moment.tz(utcDate, timezone).set({
        h: timeObj.hours(),
        m: timeObj.minutes(),
        s: timeObj.seconds(),
        ms: timeObj.milliseconds(),
    });
    // convert new date to utc
    return dateObj.toISOString();
}

export function resetTimeUnitsInDate(date: number, units: string[], options?: {format: string}) {
    return resetTimeUnits(date, units, options).valueOf();
}

export function resetTimeUnits(date: number, units: string[], options?: {format: string}) {
    const utcDateObj = moment.utc(new Date(date), options?.format ?? 'LT');
    return utcDateObj.set(
        units.reduce<Record<string, number>>((value, next) => {
            value[next] = 0;
            return value;
        }, {})
    );
}

// Check that dates are equal based on year.month.day, skipping time.
export function areDatesEqual(date1: string, date2: string) {
    if (Date.parse(date1) === Date.parse(date2)) {
        return true;
    }
    const year1 = new Date(date1).getFullYear();
    const year2 = new Date(date2).getFullYear();

    const month1 = new Date(date1).getMonth();
    const month2 = new Date(date2).getMonth();

    const day1 = new Date(date1).getDate();
    const day2 = new Date(date2).getDate();
    return year1 === year2 && month1 === month2 && day1 === day2;
}

export function isOlderThan(timestamp: number, milliseconds: number) {
    return Date.now() - timestamp > milliseconds;
}

export function stringToDate(inputDate: string | undefined) {
    if (inputDate) {
        const dateMs = Date.parse(inputDate);
        if (!isNaN(dateMs)) {
            return new Date(dateMs);
        }
    }
}
