import {ArchiveReasonEnum} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {Instance, types} from 'mobx-state-tree';
import {FeePrice} from './FeePrice';
import {FirestoreTimestamp} from './FirestoreTimestamp';
import {ItemInfo} from './ItemInfo';
import {ItemImageMap} from './ItemMedia';

export const ItemCustomField = types.model('ItemCustomFieldModel', {
    key: types.identifier,
    value: types.string,
});

export interface ItemCustomFieldType extends Instance<typeof ItemCustomField> {}

export const ImpoundFeeItem = types.model('ImpoundFeeItem', {
    feeDate: types.string,
    feeAmountInCents: types.number,
    taxAmountInCents: types.maybe(types.number),
});

export const BaseItem = types
    .model('BaseItem', {
        info: ItemInfo,
        feePrice: types.maybeNull(FeePrice),
        reservePrice: types.maybeNull(types.number),
        reserveUpdatedBy: types.maybeNull(types.string),
        reserveUpdatedAt: types.maybeNull(FirestoreTimestamp),
        startingBid: types.maybeNull(types.number),
        startingBidUpdatedBy: types.maybeNull(types.string),
        startingBidUpdatedAt: types.maybeNull(FirestoreTimestamp),
        buyNowAmount: types.maybeNull(types.number),
        isAcceptingOffers: types.maybe(types.boolean),
        itemId: types.union(types.string, types.number),
        sellerId: types.string,
        sellerVehicleId: types.maybe(types.string),
        locationId: types.maybe(types.string),
        image: types.maybe(ItemImageMap),
        imagesCount: types.maybe(types.number),
        geoHash: types.maybe(types.string),
        zipCode: types.maybe(types.string),
        transId: types.maybe(types.string),
        archiveReason: types.maybe(types.enumeration('ArchiveReason', Object.values(ArchiveReasonEnum))),
        relisted: types.maybe(types.boolean),
        sellerNotes: types.maybeNull(types.string),
        customFields: types.map(ItemCustomField),
        impoundFees: types.maybe(types.map(types.array(ImpoundFeeItem))),
    })
    .views((self) => ({
        get itemTitle() {
            return self.info.yearMakeModel;
        },
        get itemMakeModel() {
            const {make, model} = self.info;
            return `${make || ''} ${model || ''}`;
        },
    }));

export interface BaseItemType extends Instance<typeof BaseItem> {}
