import {PersistedItem} from '../dtos/ItemDto';
import {Diff} from './common';

export enum StatusActionEnum {
    STORE = 'store',
    CANDIDATE = 'candidate',
    PUBLISH = 'publish',

    SOLD = 'sold',
    UNSOLD = 'unsold',
    PAID = 'paid',

    RELEASE = 'release',
    CLAIM = 'claim',
    ARCHIVE = 'archive',
}

export function mapPersistedStatusToStatusActionEnum(status: PersistedItemStatusEnum): StatusActionEnum {
    return {
        [PersistedItemStatusEnum.STORED]: StatusActionEnum.STORE,
        [PersistedItemStatusEnum.CANDIDATE]: StatusActionEnum.CANDIDATE,
        [PersistedItemStatusEnum.PUBLISHED]: StatusActionEnum.PUBLISH,
        [PersistedItemStatusEnum.SOLD]: StatusActionEnum.SOLD,
        [PersistedItemStatusEnum.UNSOLD]: StatusActionEnum.UNSOLD,
        [PersistedItemStatusEnum.PAID]: StatusActionEnum.PAID,
        [PersistedItemStatusEnum.CLAIMED]: StatusActionEnum.CLAIM,
        [PersistedItemStatusEnum.ARCHIVED]: StatusActionEnum.ARCHIVE,
    }[status];
}

export enum ItemStatusEnum {
    PUBLISHED = 'published',
    SOLD = 'sold',
    UNSOLD = 'unsold',
    PAID = 'paid',
    CLAIMED = 'claimed',
}

export enum PersistedItemStatusEnum {
    STORED = 'stored',
    CANDIDATE = 'candidate',
    PUBLISHED = 'published',
    SOLD = 'sold',
    UNSOLD = 'unsold',
    PAID = 'paid',
    CLAIMED = 'claimed',
    ARCHIVED = 'archived',
}

export const persistedItemStatuses: Readonly<PersistedItemStatusEnum[]> = [
    PersistedItemStatusEnum.STORED,
    PersistedItemStatusEnum.CANDIDATE,
    PersistedItemStatusEnum.PUBLISHED,
    PersistedItemStatusEnum.SOLD,
    PersistedItemStatusEnum.UNSOLD,
    PersistedItemStatusEnum.PAID,
    PersistedItemStatusEnum.CLAIMED,
    PersistedItemStatusEnum.ARCHIVED,
];

export type PersistedItemActiveStatus = Diff<PersistedItemStatusEnum, PersistedItemStatusEnum.ARCHIVED>;

const itemsStatuses = Object.values(ItemStatusEnum);

// TODO: (Future) consider to remove ItemStatusEnum and use only PersistedItemStatusEnum
export function mapItemStatusToPersistedStatus(status: ItemStatusEnum): PersistedItemStatusEnum {
    return {
        [ItemStatusEnum.PUBLISHED]: PersistedItemStatusEnum.PUBLISHED,
        [ItemStatusEnum.SOLD]: PersistedItemStatusEnum.SOLD,
        [ItemStatusEnum.UNSOLD]: PersistedItemStatusEnum.UNSOLD,
        [ItemStatusEnum.PAID]: PersistedItemStatusEnum.PAID,
        [ItemStatusEnum.CLAIMED]: PersistedItemStatusEnum.CLAIMED,
    }[status];
}

export function isAuctionItemStatus(status: any): status is ItemStatusEnum {
    return itemsStatuses.includes(status);
}

const prePublishStatuses = ['stored', 'candidate'];

export function isPrePublishItemStatus(status: PersistedItemStatusEnum) {
    return prePublishStatuses.includes(status);
}

const auctionResultStatuses = [
    PersistedItemStatusEnum.SOLD,
    PersistedItemStatusEnum.UNSOLD,
    PersistedItemStatusEnum.PAID,
    PersistedItemStatusEnum.CLAIMED,
];

export function isAuctionResultStatus(status: PersistedItemStatusEnum): boolean {
    return auctionResultStatuses.includes(status);
}

export const afterAuctionStatus = [
    PersistedItemStatusEnum.SOLD,
    PersistedItemStatusEnum.UNSOLD,
    PersistedItemStatusEnum.PAID,
    PersistedItemStatusEnum.CLAIMED,
    PersistedItemStatusEnum.ARCHIVED,
];

export function isAfterAuctionStatus(status: PersistedItemStatusEnum): boolean {
    return afterAuctionStatus.includes(status);
}

const itemWonStatues: PersistedItemStatusEnum[] = [
    PersistedItemStatusEnum.SOLD,
    PersistedItemStatusEnum.PAID,
    PersistedItemStatusEnum.CLAIMED,
];

export function isWonStatus(status: PersistedItemStatusEnum): boolean {
    return itemWonStatues.includes(status);
}

// = ultimate/finished statuses (=the auction item finished its way in our system, but can be added again in the future)
// in these statuses the items can have several records for history (e.g. an owner sold a vehicle, later the winner sold it too)
export const terminalAuctionItemStatuses: PersistedItemStatusEnum[] = [
    PersistedItemStatusEnum.CLAIMED,
    PersistedItemStatusEnum.ARCHIVED,
];
export const nonTerminalAuctionItemStatuses: PersistedItemStatusEnum[] = [
    PersistedItemStatusEnum.STORED,
    PersistedItemStatusEnum.CANDIDATE,
    PersistedItemStatusEnum.PUBLISHED,
    PersistedItemStatusEnum.SOLD,
    PersistedItemStatusEnum.UNSOLD,
    PersistedItemStatusEnum.PAID,
];

export function isTerminalAuctionItemStatus(status: PersistedItemStatusEnum): boolean {
    return terminalAuctionItemStatuses.includes(status);
}

export type VehicleVin = {
    vin: string;
};

interface ItemKeyCandidate {
    sellerId: string;
    itemId: string | number;
}

export function makeItemKey(compoundKey: ItemKeyCandidate) {
    const {sellerId, itemId} = compoundKey;
    const itemKeyTokens = [];
    itemKeyTokens.push(sellerId, normalizeItemId(itemId, sellerId));
    return itemKeyTokens.join('-');
}

function normalizeItemId(itemId: string | number, sellerId: string) {
    itemId = String(itemId).trim();
    const sellerPrefix = sellerId + '-';
    return itemId.startsWith(sellerPrefix) ? itemId.substring(sellerPrefix.length) : itemId;
}

export function isValidItemStatusForImageUpdate(status: PersistedItemStatusEnum) {
    return ['published'].includes(status);
}

export function isValidItemStatusForUpdate(status: PersistedItemStatusEnum) {
    return !['stored', 'candidate'].includes(status);
}

export const timestampByStatus: {[status in PersistedItemStatusEnum]: keyof PersistedItem} = {
    [PersistedItemStatusEnum.STORED]: 'storedAt',
    [PersistedItemStatusEnum.CANDIDATE]: 'candidateAt',
    [PersistedItemStatusEnum.PUBLISHED]: 'publishedAt',
    [PersistedItemStatusEnum.SOLD]: 'soldAt',
    [PersistedItemStatusEnum.UNSOLD]: 'unsoldAt',
    [PersistedItemStatusEnum.PAID]: 'paidAt',
    [PersistedItemStatusEnum.CLAIMED]: 'claimedAt',
    [PersistedItemStatusEnum.ARCHIVED]: 'archivedAt',
};
