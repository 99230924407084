import React, {FC, ReactElement} from 'react';
import {InfoCircleOutlined} from '@ant-design/icons';
import styles from './BannerTemplate.module.less';

interface BannerTemplateProps {
    title: string;
    icon?: React.ReactNode;
    subTitle?: ReactElement<any, any>;
    button?: React.ReactNode;
    className?: string;
}

const BannerTemplate: FC<BannerTemplateProps> = ({
    icon = <InfoCircleOutlined />,
    title,
    subTitle,
    button,
    className,
}) => {
    return (
        <div className={`${styles['banner']} ${className ? styles[className] : null}`}>
            <div>
                <div className={styles['centered-icon-text']}>
                    <span className={styles['footer-icon']}>{icon}</span>
                    <div>
                        <div className={styles['main-title']}>{title}</div>
                        {subTitle && <div className={styles['sub-title']}>{subTitle}</div>}
                    </div>
                </div>
            </div>
            {button ? <span className={styles['banner-btn']}>{button}</span> : null}
        </div>
    );
};
export default BannerTemplate;
