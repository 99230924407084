import RootStoreAwareViewModel from '@joyrideautos/web-common-components/src/models/RootStoreAwareViewModel';
import {Instance} from 'mobx-state-tree';

export const ExpiredBannerViewModel = RootStoreAwareViewModel.named('ExpiredBannerViewModel')
    .props({})
    .views((self) => ({
        get isActive() {
            return self.rootStore.biddersStatuses.isGloballyBannedByExpiredPayment;
        },
    }));

export interface ExpiredBannerViewModelType extends Instance<typeof ExpiredBannerViewModel> {}
