import {
    FavoriteAuctionSeriesDto,
    FavoriteAuctionDto,
    FavoriteItemDto,
    FavoriteRegionDto,
} from '@joyrideautos/auction-core/src/dtos/FavoriteDto';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {BaseService} from './BaseService';

type FavoriteAuctionsListener = (favorites: {[auctionId: string]: FavoriteAuctionDto}) => void;
type FavoriteAuctionSeriesListener = (favorites: {[auctionSeriesId: string]: FavoriteAuctionSeriesDto}) => void;
type FavoriteItemsListener = (favorites: {[itemKey: string]: FavoriteItemDto}) => void;
type FavoriteRegionsListener = (favorites: {[regionId: string]: FavoriteRegionDto}) => void;

export class FavoritesService extends BaseService {
    subscribeToFavoriteRegions(uid: string, listener: FavoriteRegionsListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/regions`, (snapshot) => {
            const favorites: {[regionId: string]: FavoriteRegionDto} = {};

            if (snapshot.exists()) {
                snapshot.forEach((regionSnapshot) => {
                    const regionId = regionSnapshot.key!;
                    favorites[regionId] = {
                        regionId,
                        sortableKey: regionSnapshot.val(),
                    } as FavoriteRegionDto;
                });
            }

            listener(favorites);
        });
    }

    subscribeToFavoriteAuctionSeries(uid: string, listener: FavoriteAuctionSeriesListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/auctionSeries`, (snapshot) => {
            const favorites: {[auctionSeriesId: string]: FavoriteAuctionSeriesDto} = {};

            if (snapshot.exists()) {
                snapshot.forEach((auctionSeriesSnapshot) => {
                    const auctionSeriesId = auctionSeriesSnapshot.key!;
                    favorites[auctionSeriesId] = {
                        auctionSeriesId,
                        ...auctionSeriesSnapshot.val(),
                    } as FavoriteAuctionSeriesDto;
                });
            }

            listener(favorites);
        });
    }

    subscribeToFavoriteAuctions(uid: string, listener: FavoriteAuctionsListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/auctions`, (snapshot) => {
            const favorites: {[auctionId: string]: FavoriteAuctionDto} = {};

            if (snapshot.exists()) {
                snapshot.forEach((auctionSnapshot) => {
                    const auctionId = auctionSnapshot.key!;
                    favorites[auctionId] = {
                        auctionId,
                        ...auctionSnapshot.val(),
                    } as FavoriteAuctionDto;
                });
            }

            listener(favorites);
        });
    }

    subscribeToFavoriteItems(uid: string, listener: FavoriteItemsListener) {
        return this.firebase.database.subscribeToSnapshot(`/users/${uid}/favorites/items`, (snapshot) => {
            const favorites: {[itemKey: string]: FavoriteItemDto} = {};

            if (snapshot.exists()) {
                snapshot.forEach((itemSnapshot) => {
                    const itemKey = itemSnapshot.key!;
                    favorites[itemKey] = {
                        itemKey,
                        ...itemSnapshot.val(),
                    } as FavoriteItemDto;
                });
            }

            listener(favorites);
        });
    }

    async toggleFavoriteRegion(uid: string, favorite: FavoriteRegionDto): Promise<boolean> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_REGION)({uid, ...favorite});
    }

    async toggleFavoriteAuctionSeries(uid: string, favorite: FavoriteAuctionSeriesDto): Promise<boolean> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_AUCTION_SERIES)({
            uid,
            ...favorite,
        });
    }

    async toggleFavoriteAuction(uid: string, favorite: FavoriteAuctionDto): Promise<boolean> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_AUCTION)({uid, ...favorite});
    }

    async toggleFavoriteItem(uid: string, favorite: FavoriteItemDto): Promise<boolean> {
        return await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_TOGGLE_FAVORITE_ITEM)({uid, ...favorite});
    }
}
