import {Unsubscribe} from '../types';
import {UserInfo, UserRoleEnum} from '@joyrideautos/auction-core/src/dtos/UserDto';
import {BaseService} from './BaseService';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {
    RemoveRoleForUserRPCReqData,
    RemoveRoleForUserRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/removeRoleForUser';
import {
    AddRoleForUserRPCReqData,
    AddRoleForUserRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/addRoleToUser';

export type ManagerListenerType = (sellerIds: string[]) => void;

export class SellerRoleService extends BaseService {
    fetchAssociatedUsersForSeller(
        sellerId: string,
        adminUid: string
    ): Promise<{uid: string; user: UserInfo; manager: boolean}[]> {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_USER_ROLES_FIND_ASSOCIATED_USERS_FOR_SELLER)({
            sellerId,
            adminUid,
        });
    }

    async addSellerRoleToUser(data: Omit<AddRoleForUserRPCReqData, 'role'>) {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_ROLES_ADD_ROLE_TO_USER)<
            AddRoleForUserRPCReqData,
            AddRoleForUserRPCResData
        >({
            ...data,
            role: UserRoleEnum.SELLERS,
        });
    }

    async removeSellerRoleForUser(data: Omit<RemoveRoleForUserRPCReqData, 'role'>) {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_ROLES_REMOVE_ROLE_FOR_USER)<
            RemoveRoleForUserRPCReqData,
            RemoveRoleForUserRPCResData
        >({
            ...data,
            role: UserRoleEnum.SELLERS,
        });
    }

    async fetchRoleSellers(uid: string): Promise<{[uid: string]: string[]} | undefined> {
        const sellers = await this.firebase.database.fetchOnceArray<any>(`/userRoles/sellers/${uid}/sellers`);
        return {[uid]: sellers.map(({key}) => key)};
    }

    subscribeToRoleSellers(userId: string, listener: ManagerListenerType): Unsubscribe {
        return this.firebase.database.subscribe<{
            [sellerId: string]: boolean;
        }>(`/userRoles/sellers/${userId}/sellers`, (sellers) => {
            listener(sellers ? Object.keys(sellers) : []);
        });
    }
}
