import {BaseService} from './BaseService';
import {PlatformFeeScheduleDto} from '@joyrideautos/auction-core/src/dtos/PlatformFeeScheduleDto';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';

export class PlatformFeeScheduleService extends BaseService {
    async fetchPlatformFeeSchedules(): Promise<WithKey<PlatformFeeScheduleDto>[] | undefined> {
        const platformFeeSnapshots = await this.firebase.database.fetchOnceSnapshot(`/platformFeeSchedules`);
        if (!platformFeeSnapshots.exists()) {
            return;
        }
        const result: WithKey<PlatformFeeScheduleDto>[] = [];
        platformFeeSnapshots.forEach((snapshot) => {
            if (snapshot.key) {
                result.push({...snapshot.val(), key: snapshot.key});
            }
        });
        return result;
    }

    subscribeToPlatformFeeSchedules(cb: (platformFeeSchedules: WithKey<PlatformFeeScheduleDto>[]) => void) {
        return this.firebase.database.subscribeToSnapshot(`/platformFeeSchedules`, (snapshot) => {
            const values: WithKey<PlatformFeeScheduleDto>[] = [];
            if (snapshot) {
                snapshot.forEach((snapshot: any) => {
                    values.push({...snapshot.val(), key: snapshot.key});
                });
            }
            cb(values);
        });
    }

    async createPlatformFeeSchedule(platformFeeSchedule: PlatformFeeScheduleDto): Promise<string | null | undefined> {
        return this.rpcService.call(FeReqRouteEnum.API_AUCTIONS_CREATE_OR_UPDATE_PLATFORM_FEE_SCHEDULE)(
            platformFeeSchedule
        );
    }

    async updatePlatformFeeSchedule(key: string, platformFeeSchedule: PlatformFeeScheduleDto): Promise<void> {
        return this.rpcService.call(FeReqRouteEnum.API_AUCTIONS_CREATE_OR_UPDATE_PLATFORM_FEE_SCHEDULE)({
            ...platformFeeSchedule,
            key,
        });
    }
}
