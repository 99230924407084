import {flow, types, getRoot, applySnapshot, Instance} from 'mobx-state-tree';
import AuthUser, {AuthUserType} from '../types/UserInfo';
import {UserDto} from '@joyrideautos/auction-core/src/dtos/UserDto';
import AsyncLoaderQueue from '@joyrideautos/auction-core/src/utils/AsyncLoaderQueue';
import {mapDBUserToUserInfo} from '@joyrideautos/auction-core/src/mappers/mapDBUserToUserInfo';
import BaseStore from './BaseStore';

export const UserStore = BaseStore.named('UserStore')
    .props({
        users: types.map(AuthUser),
    })
    .volatile(() => ({
        asyncLoaderQueue: new AsyncLoaderQueue<(UserDto & {uid: string}) | undefined>('UserStore'),
    }))
    .views((self) => ({
        findUserByEmail(email: string): AuthUserType | undefined {
            for (const user of self.users.values()) {
                if (user.email === email) {
                    return user;
                }
            }
            return undefined;
        },
        findUserById(uid: string): AuthUserType | undefined {
            return self.users.get(uid);
        },
    }))
    .actions((self) => ({
        addUser(user: UserDto & {uid: string}): AuthUserType | undefined {
            return self.users.put(mapDBUserToUserInfo(user));
        },
    }))
    .actions((self) => ({
        fetchUserByEmail: flow(function* (email: string, force = false) {
            const existing = self.findUserByEmail(email);
            if (!existing || force) {
                const user:
                    | (UserDto & {
                          uid: string;
                      })
                    | undefined = yield self.userService.findUserByEmail(email);
                if (user) {
                    self.addUser(user);
                }
                return user;
            }
            return existing;
        }),
        fetchUserById: function (id: string, _force = false) {
            const rootStore = getRoot(self) as any;
            const {authUserStore} = rootStore;
            const byUid = authUserStore.userInfo.uid;
            self.asyncLoaderQueue.enqueue({
                key: id,
                loader: async () => {
                    const user = await self.userService.findUserById(id, byUid);
                    if (user) {
                        self.addUser(user);
                    }
                    return user;
                },
            });
            self.asyncLoaderQueue.checkQueue();
        },
        fetchUsersById: flow(function* (ids: string[], _force = false) {
            const rootStore = getRoot(self) as any;
            const {authUserStore} = rootStore;
            // TODO (Future): fetch all the users in 1 request
            const users = yield Promise.all(
                ids.map((uid) => {
                    return self.userService.findUserById(uid, authUserStore.userInfo.uid);
                })
            );
            users.forEach((user: UserDto & {uid: string}) => {
                try {
                    self.addUser(user);
                } catch (e: any) {
                    self.logger.critical('error of saving user dto', e.message);
                }
            });
            return users;
        }),
        updateCachedUser(email: string, userInfo: UserDto & {uid: string}) {
            const cachedUser = self.findUserByEmail(email);
            if (cachedUser) {
                applySnapshot(cachedUser, {...mapDBUserToUserInfo(userInfo), uid: cachedUser.uid});
            }
        },
    }))
    .actions((self) => {
        return {
            getUsersById: flow(function* (ids: string[], _force = false) {
                const notCachachedUsers = ids.filter((id) => !self.users.get(id));
                yield self.fetchUsersById(notCachachedUsers);
                // TODO: (Future) handle the case when some user wasn found for uid
                return ids.map((id) => self.users.get(id)!);
            }),
        };
    });

export interface UserStoreType extends Instance<typeof UserStore> {}

export interface HasUserStore {
    userStore: UserStoreType;
}
