export const DEFAULT_DURATION = 60;
export const DEFAULT_SELLER_PAUSE = 60;

export type WinningBidFees = {
    adminFee: number;
    bid: number;
    buyerFee: number;
    platformFee: number;
    salePrice: number;
    total: number;
    deposit?: number;
    storageTax: number;
    salesTax?: number;
    variableFee: number;
    vehicleKeyFee?: number;
    vehicleOtherFixedFee?: number;
};

export function subtotalFees(fees: WinningBidFees): number {
    return fees.platformFee + fees.adminFee + fees.buyerFee;
}

export function getDepositAmount(winningBid: Partial<WinningBidDto>): number {
    return (winningBid.fees && (winningBid.fees.deposit || 0) + winningBid.fees.platformFee) || 0;
}

export enum SoldTypeEnum {
    BUY_NOW = 'buy_now',
    OFFER = 'offer',
    SOLD_OFFLINE = 'sold_offline',
    AUCTION = 'auction',
}

export interface WinningBidDto {
    amount?: number;
    start: string;
    ended?: boolean;
    deleted?: boolean; // the vehicle was deleted from the LIVE auction
    skipped?: boolean; // the vehicle was skipped from the LIVE auction
    isUserPreApproved?: boolean;
    expiration: string;
    timestamp?: string;
    autobid?: {
        amount: number;
        prevAmount?: number;
        bidId: string;
        prevBidId?: string;
        timestamp: string;
        uid: string | null;
        prevUid?: string | null;
    };
    fees?: WinningBidFees;
    bidId?: string;
    uid?: string;
    billingUid?: string | null;
    reserveMet?: boolean;
    reservePrice?: number | null;
    payInFullAvailable?: boolean;
    soldType?: SoldTypeEnum;
    isExemptFromSalesTax?: boolean;
}
