import {getEnv, getSnapshot, Instance} from 'mobx-state-tree';
import {PayInFullBannerViewModel} from './PayInFullBannerViewModel';
import {UserBalanceThresholdModel} from '@joyrideautos/auction-core/src/models/UserBalanceThresholdModel';
import {Routes} from '../../../Routes';

const AuctionOccurrencePath = Routes.AUCTION_OCCURRENCE({
    pathParams: {regionId: ':regionId', auctionId: ':auctionId'},
});
const VehicleProfilePath = Routes.VEHICLE_PROFILE({
    pathParams: {regionId: ':regionId', auctionId: ':auctionId', itemId: ':itemId'},
});

const allowedRoutes = [AuctionOccurrencePath, VehicleProfilePath];

export const BiddingLimitReachedBannerViewModel = PayInFullBannerViewModel.named('BiddingLimitReachedBannerViewModel')
    .props({})
    .views((self) => ({
        get path() {
            return getEnv(self).path;
        },
        get regionId(): string | undefined {
            return self.rootStore.presentationStore.routeModel.getRouteParamByName('regionId');
        },
        get auctionId(): string | undefined {
            return self.rootStore.presentationStore.routeModel.getRouteParamByName('auctionId');
        },
        get itemId(): string | undefined {
            return self.rootStore.presentationStore.routeModel.getRouteParamByName('itemId');
        },
        get auction() {
            if (allowedRoutes.includes(this.path)) {
                const regionId = self.rootStore.presentationStore.routeModel.getRouteParamByName('regionId');
                const auctionId = self.rootStore.presentationStore.routeModel.getRouteParamByName('auctionId');
                if (regionId && auctionId) {
                    return self.auctionsStore.findAuction(regionId, auctionId);
                }
            }
            return;
        },
        get sellersIds() {
            return this.auction?.sellers ? [...this.auction.sellers.keys()] : [];
        },
        get isUserPreApproved(): boolean {
            if (!self.authUser) {
                return false;
            }
            return this.sellersIds.some((sellerId) =>
                self.biddersStatuses.isPreApprovedUser(self.authUser!.uid, sellerId)
            );
        },
        get loggedInUserModel(): UserBalanceThresholdModel | undefined {
            return (
                self.authUser &&
                UserBalanceThresholdModel.fromDto(
                    getSnapshot(self.authUser) as any,
                    this.isUserPreApproved,
                    self.globalConfigStore.payments && getSnapshot<any>(self.globalConfigStore.payments)
                )
            );
        },
        get loggedInUserHasOutstandingPayments() {
            if (!this.loggedInUserModel) {
                return false;
            }
            return this.loggedInUserModel.totalOutstandingBalance > 0;
        },
        get hasUserReachedBalanceThreshold() {
            if (!this.loggedInUserModel) {
                return false;
            }
            return this.loggedInUserModel.hasUserReachedBalanceThreshold;
        },
        get isPayInFullEnabled(): boolean {
            if (!allowedRoutes.includes(this.path)) {
                return false;
            }

            if (VehicleProfilePath === this.path) {
                return self.checkPayInFullForItem({
                    regionId: this.regionId!,
                    auctionId: this.auctionId!,
                    itemId: this.itemId!,
                });
            }

            if (AuctionOccurrencePath === this.path) {
                return self.checkPayInFullForAuction({regionId: this.regionId!, auctionId: this.auctionId!});
            }

            return false;
        },
        get isActive() {
            if (this.loggedInUserModel && allowedRoutes.includes(this.path)) {
                return this.isPayInFullEnabled && this.hasUserReachedBalanceThreshold;
            }
            return false;
        },
    }));

export interface BiddingLimitReachedBannerViewModelType extends Instance<typeof BiddingLimitReachedBannerViewModel> {}
