import {getRoot, Instance, isAlive, types} from 'mobx-state-tree';
import {LoadingStatus, withStatus} from '../utils/LoadingStatus';
import {GeoLocationDto} from '@joyrideautos/auction-core/src/dtos/GeoLocationDto';
import {makeCancelable} from '@joyrideautos/auction-utils/src/cancelable';
import {getUILocalStorage} from '../sessionStores/UILocalStorage';
import {when} from 'mobx';
import {DEFAULT_ZIPCODE} from '@joyrideautos/auction-core/src/constants/Constants';
import primitives from '../Primitives';
import BaseStore from './BaseStore';
import {ZipCodeByIpInfo} from '@joyrideautos/ui-services/src/types';
import {subscribeAsyncModels} from '../SubscribeAsyncModels';

async function getCoordinatesFromBrowser() {
    return new Promise<GeoLocationDto>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.debug('Successfully retrieved geolocation: ', position);
                const {longitude: lon, latitude: lat} = position.coords;
                resolve({lon, lat});
            },
            (err) => {
                console.log('Failed to retrieve geolocation: ', err);
                reject(err);
            },
            {enableHighAccuracy: true}
        );
    });
}

export const GeoCoordinates = types.model('GeoCoordinates', {
    lat: types.number,
    lon: types.number,
});

export const GeoLocationStore = BaseStore.named('GeoLocationStore')
    .props({
        // TODO (Future): keep only coordinates from browser here. Coordinates by zipCodes should be stored in a separate
        //  map to avoid different viewModels overwriting each other
        coordinates: types.maybe(GeoCoordinates),
        zipCodeByIp: primitives.string(),
        authUserCoordinates: subscribeAsyncModels.value(
            GeoCoordinates,
            (self) => (events) =>
                self.authUserStore.subscribeToUserChanged(({before, after}: any) => {
                    if (after.user?.isAnonymous) {
                        events.onReset();
                        events.onValue();
                        return;
                    }
                    const isUserChanged =
                        before.user?.uid !== after.user?.uid || before.user?.zipcode !== after.user?.zipcode;
                    if (!after.user?.uid) {
                        events.onReset();
                    } else if (isUserChanged) {
                        events.onReset();
                        self.rootStore.userService
                            .getCoordinatesByZipCode({
                                zipCode: self.authUserStore.userInfo.zipcode,
                            })
                            .then(events.onValue);
                    }
                })
        ),
    })
    .volatile(() => ({
        isGeoLocationSupported: !!navigator.geolocation,
        // Warning! These statuses are global.
        // In most cases viewModels should have own loading statuses to avoid affecting each other
        status: new LoadingStatus(),
        zipCodeByIpStatus: new LoadingStatus(),
    }))
    .actions((self) => {
        return {
            clearCoordinates() {
                self.coordinates = undefined;
            },
            setCoordinates(coordinates: GeoLocationDto) {
                self.status.setReady();
                self.coordinates = coordinates;
            },
        };
    })
    .actions((self) => {
        const cancelableFetchCoordinates = makeCancelable<GeoLocationDto>('fetch coordinates');
        const rootStore: any = getRoot(self);
        return {
            async getCoordinatesFromBrowser(): Promise<GeoLocationDto | undefined> {
                if (!self.isGeoLocationSupported) {
                    return;
                }
                self.status.setInProgress();
                try {
                    const coordinates = await getCoordinatesFromBrowser();
                    self.setCoordinates(coordinates);
                    return coordinates;
                } catch (err: any) {
                    const message = err.message || 'Failed to retrieve geolocation';
                    self.status.setError(message);
                }
            },
            async getCoordinatesForZipCode(zipCode: string): Promise<GeoLocationDto | undefined> {
                try {
                    self.status.setInProgress();

                    const coordinates = await cancelableFetchCoordinates(() =>
                        self.userService.getCoordinatesByZipCode({zipCode})
                    );

                    if (!isAlive(self)) {
                        return;
                    }
                    if (coordinates) {
                        self.setCoordinates(coordinates);
                        return coordinates;
                    }

                    let error = `Failed to find coordinates for zipCode ${zipCode}`;
                    if (zipCode !== DEFAULT_ZIPCODE) {
                        error += `. Will try to get coordinates for the default zip code ${DEFAULT_ZIPCODE}`;
                        await this.getCoordinatesForZipCode(DEFAULT_ZIPCODE);
                    }
                    self.status.setError(error);
                } catch (err: any) {
                    console.error(err);
                    self.status.setError(err.message);
                }
            },
            async getZipCodeByIP(uid: string): Promise<string | undefined> {
                const geoLocation = await withStatus(self.zipCodeByIpStatus)(() =>
                    self.userService.getZipCodeByIP()
                ).catch(self.logger.error.bind(self.logger));
                const {zipCode} = (geoLocation || {}) as ZipCodeByIpInfo;
                if (!isAlive(self)) {
                    return;
                }
                self.zipCodeByIp.setValue(zipCode);
                getUILocalStorage(uid, {
                    sessionStore: self.sessionStore,
                    localStorageStore: self.localStorageStore,
                }).setZipCode(zipCode);
                return zipCode;
            },
            getZipCodeFromUserInfo(): string | undefined {
                return rootStore.authUserStore?.userInfo?.zipcode;
            },
            getZipCodeFromCache(): string | undefined {
                const {uid} = rootStore.authUserStore?.userInfo || {};
                return uid
                    ? getUILocalStorage(uid, {
                          sessionStore: self.sessionStore,
                          localStorageStore: self.localStorageStore,
                      }).getZipCode()
                    : undefined;
            },
            async fetchDefaultZipCode(): Promise<string | undefined> {
                await when(() => rootStore.authUserStore.loadingStatus.isReady);
                const {
                    userInfo: {uid},
                } = rootStore.authUserStore;
                return this.getZipCodeFromCache() || this.getZipCodeFromUserInfo() || this.getZipCodeByIP(uid);
            },
            async fetchCoordinates(): Promise<GeoLocationDto | undefined> {
                const zipCode = await this.fetchDefaultZipCode();
                if (!zipCode) {
                    return;
                }
                return await this.getCoordinatesForZipCode(zipCode);
            },
        };
    });

export interface GeoLocationStoreType extends Instance<typeof GeoLocationStore> {}
