import {types, Instance} from 'mobx-state-tree';
import {
    ItemPayment,
    ItemPaymentType,
    PaymentStatistics,
    PaymentStatisticsType,
    fromItemPaymentDto,
    fromPaymentStatisticsDto,
} from '../types/ItemPayment';
import {values} from 'mobx';
import {PaymentTypeEnum} from '@joyrideautos/auction-core/src/dtos/PaymentsDto';
import BaseStore from './BaseStore';

function makeKey(p: {regionId: string; auctionId: string; itemId: string; type?: string}) {
    return `${p.regionId}-${p.auctionId}-${p.itemId}-${p.type}`;
}

export const ItemPaymentsStore = BaseStore.named('ItemPaymentsStore')
    .props({
        payments: types.map(ItemPayment),
        paymentStatistics: types.map(types.maybeNull(PaymentStatistics)),
    })
    .actions((self) => ({
        setPayments(payments: ItemPaymentType[]) {
            payments.forEach((p) => {
                const {itemId, regionId, auctionId, type} = p;
                self.payments.set(makeKey({itemId: String(itemId), regionId, auctionId, type}), p);
            });
        },
        setStatistics(uid: string, statistics: PaymentStatisticsType | null) {
            self.paymentStatistics.set(uid, statistics);
        },
    }))
    .views((self) => ({
        getPayment(regionId: string, auctionId: string, itemId: string, type: PaymentTypeEnum) {
            return self.payments.get(makeKey({regionId, auctionId, itemId, type}));
        },
        get allPayments(): ItemPaymentType[] {
            return values(self.payments).flat();
        },
        getStatistics(uid: string) {
            return self.paymentStatistics.get(uid);
        },
    }))
    .actions((self) => {
        let paymentResultsDisposer = () => {};
        let paymentStatisticsDisposer = () => {};
        let paymentStatisticsDisposers: {[uid: string]: () => void} = {};

        return {
            subscribe(uid: string) {
                paymentResultsDisposer();
                self.setPayments([]);
                paymentResultsDisposer = self.itemPaymentService.subscribeToPaymentResults(uid, (payments) => {
                    self.setPayments(payments.map(fromItemPaymentDto));
                });
            },

            unsubscribe() {
                paymentResultsDisposer();
                this.unsubscribeFromPaymentStatistics();
            },

            subscribeToStatistics() {
                paymentStatisticsDisposer();
                paymentStatisticsDisposer = self.itemPaymentService.subscribeToPaymentStatistics((statistics) => {
                    Object.keys(statistics).forEach((uid) => {
                        self.setStatistics(uid, fromPaymentStatisticsDto(statistics[uid]));
                    });
                });
            },
            subscribeToStatisticsForUser(uid: string) {
                const disposer = paymentStatisticsDisposers[uid];
                if (!disposer) {
                    paymentStatisticsDisposers[uid] = self.itemPaymentService.subscribeToPaymentStatisticsForUser(
                        uid,
                        (statistics) => {
                            self.setStatistics(uid, statistics ? fromPaymentStatisticsDto(statistics) : null);
                        }
                    );
                }
            },
            unsubscribeFromPaymentStatistics() {
                paymentStatisticsDisposer();
                paymentStatisticsDisposers = {};

                Object.values(paymentStatisticsDisposers).forEach((disposer) => disposer());
                paymentStatisticsDisposer = () => {};

                self.paymentStatistics.clear();
            },

            beforeDestroy() {
                this.unsubscribe();
            },
        };
    });
export interface ItemPaymentStoreType extends Instance<typeof ItemPaymentsStore> {}

export interface HasItemPaymentsStore {
    paymentsStore: ItemPaymentStoreType;
}
