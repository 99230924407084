import {getRoot, Instance, SnapshotOrInstance, types} from 'mobx-state-tree';
import {now} from 'mobx-utils';
import {Item} from '../item/Item';
import type {ItemType} from '../item/Item';
import {compareDates, formatDate, parseDate} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import moment from 'moment';
import {PERMISSIBLE_DURATION_OF_VIEWING_FINANCIAL_INFO_IN_MS} from '@joyrideautos/auction-core/src/constants/Constants';
import {AuctionTypeEnum, isSequenceAuction, isListingAuction} from '@joyrideautos/auction-core/src/types/AuctionTypes';
import {AuctionTemplate} from './AuctionTemplate';
import {AuctionAccess, AuctionFormatType} from './types';
import {ItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {AuctionOccurrenceDto} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {ClockModelType} from '../../utils/Clock';
import {CC_HOLD_START_CHARGE_IN_MS} from '@joyrideautos/auction-core/src/constants/Constants';

export const AuctionSettings = AuctionTemplate.named('AuctionSettings')
    .props({
        start: types.string,
        startEvent: types.maybe(types.string),
        expiration: types.maybe(types.string),
    })
    .views((self) => ({
        get isSequence() {
            return isSequenceAuction(self.auctionType);
        },
        get isListing() {
            return isListingAuction(self.auctionType);
        },
        get rootStore() {
            return getRoot(self) as any;
        },
        get clock(): ClockModelType {
            return this.rootStore.clock;
        },
    }))
    .actions((self) => {
        return {
            setAuctionType(value: SnapshotOrInstance<AuctionFormatType>) {
                self.auctionType = value;
            },
            setExpiration(value: string | undefined) {
                if (self.isListing) {
                    self.expiration = value;
                } else {
                    throw new Error(`Expiration date is not applicable only for auction type ${self.auctionType}`);
                }
            },
            setStart(value: string) {
                self.start = value;
            },
            setStartEvent(value: string) {
                self.startEvent = value;
            },
            setDurationInSeconds(value: number | undefined) {
                if (self.isSequence) {
                    self.durationInSeconds = value;
                } else {
                    throw new Error(`Bidding duration is not applicable only for auction type ${self.auctionType}`);
                }
            },
        };
    })
    .views((self) => {
        return {
            get expirationAsDate() {
                return parseDate(self.expiration);
            },
            get startAsDate() {
                return parseDate(self.start);
            },
            get startEventAsDate() {
                return parseDate(self.startEvent);
            },
            get formattedExpiration() {
                const value = this.expirationAsDate;
                return value ? formatDate(value, 'llll') : '';
            },
            get formattedStart() {
                const value = this.startAsDate;
                return value ? formatDate(value, 'llll') : '';
            },
            get formattedStartEvent() {
                const value = this.startEventAsDate;
                return value ? formatDate(value, 'llll') : '';
            },
            get formattedAsMonthDayYear() {
                const value = this.startByAuctionTypeAsDate;
                return value ? formatDate(value, 'MMMM Do, YYYY') : '';
            },
            get startByAuctionType() {
                return self.isSequence ? self.startEvent : self.start;
            },
            get startByAuctionTypeAsDate() {
                return self.isSequence ? this.startEventAsDate : this.startAsDate;
            },
            get formattedStartByAuctionType() {
                const date = self.isSequence ? self.startEvent : self.expiration;
                let format = 'MMMM D | h:mm a';
                const withoutMinutes =
                    moment(date).startOf('second').valueOf() === moment(date).startOf('hour').valueOf();
                if (withoutMinutes) {
                    format = 'MMMM D | h a';
                }
                return formatDate(moment(date), format);
            },
            get formattedEndDateListing() {
                let format = 'MMMM D | h:mm a';
                const withoutMinutes =
                    moment(self.expiration).startOf('second').valueOf() ===
                    moment(self.expiration).startOf('hour').valueOf();
                if (withoutMinutes) {
                    format = 'MMMM D | h a';
                }
                return formatDate(moment(self.expiration), format);
            },
            get isStarted() {
                return this.startByAuctionTypeAsDate && this.startByAuctionTypeAsDate.valueOf() < Date.now();
            },
            get daysBeforeStart() {
                if (!this.startByAuctionType) {
                    return 0;
                }
                const DAYS = 24 * 60 * 60 * 1000;
                const daysBeforeStart = Math.ceil((Date.parse(this.startByAuctionType).valueOf() - Date.now()) / DAYS);
                return daysBeforeStart;
            },
            get daysToScheduleVehicles() {
                const schedulingEndDate = self.isSequence ? self.startEvent : self.expiration;
                if (!schedulingEndDate) {
                    return 0;
                }
                const DAYS = 24 * 60 * 60 * 1000;
                const daysBeforeStart = Math.ceil((Date.parse(schedulingEndDate).valueOf() - Date.now()) / DAYS);
                return daysBeforeStart;
            },
            get shouldUseReservePriceAsStartingBid() {
                return false;
            },
            get sortingDateByAuctionType() {
                return self.isSequence ? this.startEventAsDate : this.expirationAsDate;
            },
        };
    })
    .views((self) => {
        function isExtendExpirationBySecondsValid() {
            const extendExpirationBySeconds = self.extendExpirationBySeconds;
            return extendExpirationBySeconds === undefined || extendExpirationBySeconds > 0;
        }

        return {
            get isExpirationDateValid() {
                return self.expirationAsDate;
            },
            get isStartDateValid() {
                return self.startAsDate;
            },
            get isStartEventDateValid() {
                return self.startEventAsDate;
            },
            get isValid() {
                if (self.isListing) {
                    if (self.startAsDate && this.isExpirationDateValid && isExtendExpirationBySecondsValid()) {
                        return true;
                    }
                } else if (self.isSequence) {
                    if (
                        self.startAsDate &&
                        isExtendExpirationBySecondsValid() &&
                        self.durationInSeconds &&
                        self.durationInSeconds > 0
                    ) {
                        return true;
                    }
                }
                return false;
            },
        };
    });

export interface AuctionSettingsType extends Instance<typeof AuctionSettings> {}

export const AuctionPhoto = types
    .model('AuctionPhoto', {
        region: types.string,
        auctionId: types.maybe(types.string),
        photoId: types.maybe(types.string),
        thumbId: types.maybe(types.string),
        bucket: types.maybe(types.string),
        metadata: types.map(types.string),
    })
    .views((self) => ({
        baseUrl() {
            return `https://firebasestorage.googleapis.com/v0/b/${self.bucket}/o/${self.region}%2Foccurrence_headline%2F${self.auctionId}`;
        },
        get url() {
            return (
                self.photoId &&
                self.auctionId &&
                self.bucket &&
                `${this.baseUrl()}%2Foriginal%2F${self.photoId}?alt=media`
            );
        },
        get thumbUrl() {
            return (
                self.thumbId &&
                self.auctionId &&
                self.bucket &&
                `${this.baseUrl()}%2Fthumb_200%2F${self.thumbId}?alt=media`
            );
        },
    }));

export interface AuctionPhotoType extends Instance<typeof AuctionPhoto> {}

export const Auction = types
    .model('Auction', {
        auctionId: types.identifier,
        regionId: types.string,
        access: AuctionAccess,
        title: types.string,
        text: types.maybe(types.string),
        auctionSeries: types.string,
        ended: types.maybe(types.boolean),
        endedAt: types.maybe(types.number),
        deleted: types.maybe(types.boolean),
        settings: AuctionSettings,
        paused: types.maybe(types.enumeration(['paused', 'suspended'])),
        startItem: types.maybe(types.string),
        sellers: types.maybe(types.map(types.boolean)),
        photo: types.maybe(AuctionPhoto),
        name: types.maybe(types.string),
    })
    .views((self) => ({
        get rootStore() {
            return getRoot(self) as any;
        },
        get clock(): ClockModelType {
            return this.rootStore.clock;
        },
    }))
    .views((self) => {
        return {
            get isTitleValid() {
                return self.title.trim().length > 0;
            },
            get isBidderInitials() {
                return self.settings.isBidderInitials;
            },
            get isChargeBuyerFee() {
                return self.settings.isChargeBuyerFee;
            },
            get isValid() {
                return this.isTitleValid && self.settings.isValid;
            },
            get isPaused() {
                return self.paused === 'paused';
            },
            get isSuspended() {
                return self.paused === 'suspended';
            },
            get photoUrl() {
                return (
                    (self.photo && self.photo.thumbUrl) ||
                    (self.photo && self.photo.url) ||
                    'https://react.semantic-ui.com/images/wireframe/image.png'
                );
            },
            get isRestricted() {
                return self.access === 'RESTRICTED';
            },
            get isBiddingStarted() {
                if (self.settings.isSequence) {
                    return now() > self.clock.parseDate(self.settings.startEvent!);
                }
                if (self.settings.isListing) {
                    return now() > self.clock.parseDate(self.settings.start);
                }
                return false;
            },
            get isBiddingAllowed() {
                return now() > self.clock.parseDate(self.settings.start);
            },
            get isPreBiddingStarted() {
                if (self.settings.isSequence) {
                    return now() > self.clock.parseDate(self.settings.start);
                }
                return false;
            },
            get isSequenceAuctionType() {
                return self.settings.auctionType === AuctionTypeEnum.SEQUENCE;
            },
            get isListingAuctionType() {
                return self.settings.auctionType === AuctionTypeEnum.LISTING;
            },
            get isBuyNowAuction() {
                return self.settings.auctionType === AuctionTypeEnum.LISTING && self.settings.isBuyNowEnabled;
            },
            get isHybridAuction() {
                return Boolean(self.settings.hybrid);
            },
            get biddingStartAt() {
                if (self.settings.isSequence) {
                    return self.clock.parseDate(self.settings.startEvent!);
                }
                if (self.settings.isListing) {
                    return self.clock.parseDate(self.settings.start);
                }
                return -1;
            },
            get shouldPlaceAuthHold() {
                let timestamp: number;
                if (self.settings.isSequence) {
                    timestamp = self.clock.parseDate(self.settings.startEvent!);
                }
                if (self.settings.isListing) {
                    timestamp = self.clock.parseDate(self.settings.expiration!);
                }
                return now() + CC_HOLD_START_CHARGE_IN_MS >= timestamp!;
            },
            get endAsDate(): Date | undefined {
                return self.endedAt ? new Date(self.endedAt) : undefined;
            },
            get isAllowedToViewFinancialInfo() {
                if (self.endedAt) {
                    return self.endedAt > Date.now() - PERMISSIBLE_DURATION_OF_VIEWING_FINANCIAL_INFO_IN_MS;
                }
                return this.biddingStartAt > Date.now() - PERMISSIBLE_DURATION_OF_VIEWING_FINANCIAL_INFO_IN_MS;
            },
            get liveAuctionTagText() {
                return self.settings.isSequence ? 'Live' : 'Open';
            },
            get isAllowedToScheduleVehicles() {
                return this.isSequenceAuctionType ? !this.isBiddingStarted : !self.ended;
            },
            get shouldHideAuctionDate(): boolean {
                return !!self.settings.shouldHideAuctionDate;
            },
            get shouldHidePastAuctions(): boolean {
                return !!self.settings.shouldHidePastAuctions;
            },
            get sellerIds() {
                return self.sellers ? Array.from(self.sellers.keys()) : [];
            },
            get auctionPath() {
                return {
                    regionId: self.regionId,
                    auctionId: self.auctionId,
                };
            },
        };
    });

export interface AuctionType extends Instance<typeof Auction> {}

export const AuctionWithItems = Auction.named('AuctionWithItems').props({
    items: types.array(Item),
});

export type AuctionStartDateComparator = (a: AuctionType, b: AuctionType) => number;

export type SortingDateFieldName =
    | 'sortingDateByAuctionType'
    | 'startByAuctionTypeAsDate'
    | 'startAsDate'
    | 'expirationAsDate';

export const compareAuctionsByDate: (
    reverse?: boolean,
    fieldName?: SortingDateFieldName
) => AuctionStartDateComparator =
    (reverse = false, fieldName = 'sortingDateByAuctionType') =>
    (auction1, auction2) => {
        const aStartDate = auction1.settings[fieldName];
        const bStartDate = auction2.settings[fieldName];

        return aStartDate && bStartDate ? compareDates(aStartDate, bStartDate, reverse) : -1;
    };

export interface AuctionWithItemsType extends Instance<typeof AuctionWithItems> {}

export const AuctionAwareModel = types.model('AuctionAwareModel', {
    regionId: types.string,
    auctionId: types.string,
});

export interface AuctionAwareModelType extends Instance<typeof AuctionAwareModel> {}

export function filterVehiclesOnListingAuction(auction: AuctionType | undefined) {
    return function (item: ItemType) {
        return auction?.isBuyNowAuction && !auction.ended ? item.status === ItemStatusEnum.PUBLISHED : true;
    };
}

export function fromAuctionDto(auction: AuctionOccurrenceDto): AuctionType {
    return auction as AuctionType;
}

export function toPartialAuctionDto(auction: Partial<AuctionType>): Partial<AuctionOccurrenceDto> {
    return auction as Partial<AuctionOccurrenceDto>;
}

export function toAuctionDto(auction: AuctionType): AuctionOccurrenceDto {
    return auction as AuctionOccurrenceDto;
}
