import {LDUser} from 'launchdarkly-js-client-sdk';

import {UserInfo} from '@joyrideautos/auction-core/src/dtos/UserDto';

export function authUserToLdUser(authUser?: UserInfo): LDUser {
    if (authUser?.uid) {
        return {
            key: authUser.uid,

            name: authUser.fullName,

            email: authUser.email ?? undefined,
        };
    }

    return {
        anonymous: true,
    };
}
