import {FeeTypeEnum} from '@joyrideautos/auction-core/src/dtos/FeePriceDto';
import {types} from 'mobx-state-tree';
import {FirestoreTimestamp} from './FirestoreTimestamp';

export const FeeTypes = types.enumeration('feeTypes', [FeeTypeEnum.KEY, FeeTypeEnum.OTHER]);

export const FeePrice = types.model('FeePrice', {
    amount: types.number,
    feeType: FeeTypes,
    description: types.maybe(types.string),
    feeUpdatedAt: types.maybe(FirestoreTimestamp),
});
