import {BaseService} from './BaseService';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {PreApprovedUserType} from '@joyrideautos/auction-core/src/types/PreApprovedUser';
import {RestrictedAuctionSeriesAccessDto} from '@joyrideautos/auction-core/src/dtos/RestrictedAuctionSeriesAccessDto';
import {
    SendBuyerCommunicationsRPCReqData,
    SendBuyerCommunicationsRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/sendBuyerCommunicationsReqTypes';
import {
    NotifyAuctionBiddingOpenRPCReqData,
    NotifyAuctionBiddingOpenRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/notifyAuctionBiddingOpenReqTypes';
import {
    GetBuyerCommunicationsRPCReqData,
    GetBuyerCommunicationsRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/bidders/getBuyerCommunicationsReqTypes';
import {
    GrantRestrictedAccessToAuctionSeriesReqData,
    GrantRestrictedAccessToAuctionSeriesResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/bidders/grantRestrictedAccessToAuctionSeriesTypes';
import {
    GetUserActiveRestrictedAccessAuctionSeriesReqData,
    GetUserActiveRestrictedAccessAuctionSeriesResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/bidders/getUserActiveRestrictedAccessAuctionSeriesReqTypes';

export class BiddersService extends BaseService {
    subscribeToCheckIfCurrentUserHasRestrictedAuctionSeriesAccess(
        uid: string | null,
        auctionSeriesKey: string,
        listener: (result: RestrictedAuctionSeriesAccessDto | null) => void
    ): () => void {
        if (!uid) {
            listener(null);
            return () => {};
        }

        return this.firebase.firestore.subscribeToDocument<RestrictedAuctionSeriesAccessDto>(
            this.firebase.firestore.documentRef(`/auctionSeries/${auctionSeriesKey}/restrictedAccess/${uid}`),
            (result) => {
                result ? listener(result) : listener(null);
            }
        );
    }

    async grantAccessToAuctionSeriesForUser(
        data: GrantRestrictedAccessToAuctionSeriesReqData
    ): Promise<GrantRestrictedAccessToAuctionSeriesResData> {
        return await this.firebase.rpcService.call(
            FeReqRouteEnum.API_USER_GRANT_RESTRICTED_ACCESS_TO_AUCTION_SERIES_FOR_USER
        )(data);
    }

    async getUserActiveRestrictedAccessAuctionSeries(
        data: GetUserActiveRestrictedAccessAuctionSeriesReqData
    ): Promise<GetUserActiveRestrictedAccessAuctionSeriesResData> {
        return await this.firebase.rpcService.call(
            FeReqRouteEnum.API_USER_GET_USER_ACTIVE_RESTRICTED_ACCESS_AUCTION_SERIES
        )(data);
    }

    async fetchPreApprovedUser(uid: string): Promise<PreApprovedUserType | null> {
        const result = await this.firebase.database.fetchOnceSnapshot(`/userPreApproved/${uid}`);
        if (!result.exists()) {
            return null;
        }
        const preApprovedUser = result.val();
        if (preApprovedUser.preApprovedAt && preApprovedUser.preApprovedBy) {
            return {forAllSellers: {...preApprovedUser}};
        }
        const sellers = Object.keys(preApprovedUser).map((sellerId) => {
            return {sellerId, ...preApprovedUser[sellerId]};
        });
        return {sellers};
    }

    subscribeToCheckIfUserIsPreApproved(uid: string, listener: (value: PreApprovedUserType | null) => void) {
        const path = `/userPreApproved/${uid}`;
        return this.firebase.database.subscribeToSnapshot(path, (snapshot) => {
            let result: PreApprovedUserType | null;
            if (!snapshot.exists()) {
                result = null;
            } else {
                const preApprovedUser = snapshot.val();
                if (preApprovedUser.preApprovedAt && preApprovedUser.preApprovedBy) {
                    result = {forAllSellers: {...preApprovedUser}};
                } else {
                    const sellers = Object.keys(preApprovedUser).map((sellerId) => ({
                        sellerId,
                        ...preApprovedUser[sellerId],
                    }));
                    result = {sellers};
                }
            }
            listener(result);
        });
    }

    sendBuyerCommunication(data: SendBuyerCommunicationsRPCReqData) {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_USER_SEND_BUYER_COMMUNICATION)<
            SendBuyerCommunicationsRPCReqData,
            SendBuyerCommunicationsRPCResData
        >(data);
    }

    fetchBuyerCommunications(data: GetBuyerCommunicationsRPCReqData) {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_USER_FETCH_BUYER_COMMUNICATIONS)<
            GetBuyerCommunicationsRPCReqData,
            GetBuyerCommunicationsRPCResData
        >(data);
    }

    sendNotificationBiddingOpen(data: NotifyAuctionBiddingOpenRPCReqData) {
        return this.firebase.rpcService.call(FeReqRouteEnum.API_USER_SEND_NOTIFICATION_BIDDING_OPEN)<
            NotifyAuctionBiddingOpenRPCReqData,
            NotifyAuctionBiddingOpenRPCResData
        >(data);
    }
}
