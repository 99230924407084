import {getEnv, getRoot, types} from 'mobx-state-tree';
import {Auction} from './Auction';
import {LoggerStatusEnum} from '@joyrideautos/ui-logger/src/Logger';
import {AuctionSeries, AuctionSeriesType} from './AuctionSeries';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';

function toAuctionIdOrAuctionPath(auctionRef: string): AuctionPath | string {
    try {
        return JSON.parse(auctionRef);
    } catch (e) {
        return auctionRef;
    }
}

export const AuctionReference = types.reference(Auction, {
    get(auctionRef: any, parent: any): any {
        const rootStore = getEnv(parent).rootStore || getRoot(parent);
        rootStore
            ?.getLogger('AuctionReference', LoggerStatusEnum.DISABLED)
            ?.log({rootStore, parent: parent.toString()});
        const auctionIdOrAuctionPath = toAuctionIdOrAuctionPath(auctionRef);
        if (typeof auctionIdOrAuctionPath === 'string') {
            return rootStore.auctionsStore.findAuctionFor(auctionIdOrAuctionPath);
        }
        const {auctionId, regionId} = auctionIdOrAuctionPath;
        return rootStore.auctionsStore.findAuction(regionId, auctionId);
    },

    set(auction: any): string {
        if (typeof auction === 'string') {
            return auction;
        }
        return JSON.stringify(auction);
    },
});

export const AuctionSeriesReferenceById = types.reference(AuctionSeries, {
    get(id: number, parent: any): AuctionSeriesType {
        const rootStore = getEnv(parent).rootStore || getRoot(parent);
        rootStore
            ?.getLogger('AuctionSeriesReferenceById', LoggerStatusEnum.DISABLED)
            ?.log({rootStore, parent: parent.toString(), id});
        return rootStore.auctionSeriesStore.findSeries(id)!;
    },
    set(serie: AuctionSeriesType) {
        return serie.id;
    },
});
export const AuctionSeriesReferenceByKey = types.reference(AuctionSeries, {
    get(key: string, parent: any): any {
        const rootStore = getEnv(parent).rootStore || getRoot(parent);
        rootStore
            ?.getLogger('AuctionSeriesReferenceByKey', LoggerStatusEnum.DISABLED)
            ?.log({rootStore, parent: parent.toString(), key});
        return rootStore.auctionSeriesStore.findSeriesByKey(key);
    },

    set(value: AuctionSeriesType): string {
        return value.key;
    },
});
