import React from 'react';
import {Layout} from 'antd';
import {FC, PropsWithChildren, ReactNode, useEffect, useRef} from 'react';
import {useRootStore} from '@joyrideautos/ui-models/src/stores/rootStore/useRootStore';
import {Observer} from 'mobx-react-lite';
import {RootStoreType} from '@models/stores/RootStore';
import Footer from './footer/Footer';
import BottomBanner from '../components/banner/bottomBanner/BottomBanner';

interface Props extends PropsWithChildren {
    renderHeader: () => ReactNode;
    isFooterVisible?: boolean;
}

export const MainLayoutResizeDetectionContextProvider: FC<Props> = ({renderHeader, isFooterVisible, children}) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const targetFooterRef = useRef<HTMLDivElement>(null);
    const rootStore = useRootStore<RootStoreType>();

    useEffect(() => {
        const top = Math.ceil(targetFooterRef.current?.getBoundingClientRect().top || 0);
        rootStore.presentationStore.footerTop.setValue(top);

        const handleScroll = (_ev: Event) => {
            if (targetFooterRef.current) {
                const top = Math.ceil(targetFooterRef.current.getBoundingClientRect().top);
                rootStore.presentationStore.footerTop.setValue(top);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [rootStore]);

    useEffect(() => {
        const header = headerRef.current;
        const observer = new ResizeObserver((entries) => {
            if (entries.length > 0) {
                const entry = entries[0];
                const headerHeight = Math.ceil(entry.target.clientHeight - 1);
                rootStore.presentationStore.headerHeight.setValue(headerHeight);
            }
        });
        if (header) {
            observer.observe(header);
        }
        return () => {
            if (header) {
                observer.disconnect();
            }
        };
    }, [rootStore]);

    return (
        <>
            <Layout className="wrapper">
                <div id="main-layout-resize-detector" ref={headerRef}>
                    <Observer>{() => <>{renderHeader()}</>}</Observer>
                </div>
                {children}
                <BottomBanner />
            </Layout>

            {isFooterVisible && <Footer anchor={<div ref={targetFooterRef}></div>} />}
        </>
    );
};
