import * as s from 'superstruct';
import {
    AuctionSeriesAnnouncementValidation,
    AuctionSeriesValidation,
    AuctionTemplateValidation,
} from '../types/validations/auctionSeriesValidation';

export type AuctionSeriesAnnouncement = s.Infer<typeof AuctionSeriesAnnouncementValidation>;
export type AuctionTemplate = s.Infer<typeof AuctionTemplateValidation>;
export type AuctionSeriesDto = s.Infer<typeof AuctionSeriesValidation>;

// Path (composite primary key) to an AuctionSeries document in RTDB
export interface AuctionSeriesPath {
    seriesId: string;
    regionId: string;
}

export const DEFAULT_IS_STORAGE_TAX_ENABLED = false;
export const DEFAULT_VEHICLE_TRANSITION_INTERVAL_SEC = 10;
export const DEFAULT_IS_EXCLUDE_SELLER_FEES_FROM_SALES_TAX = false;
