import {PayInFullConfigDto} from '../dtos/GlobalConfigDto';
import {UserDto} from '../dtos/UserDto';

export class UserBalanceThresholdModel {
    constructor(
        private dto: UserDto,
        private isUserPreApproved: boolean,
        private payInFullConfig?: PayInFullConfigDto
    ) {}

    static fromDto(dto: UserDto, isUserPreApproved: boolean, payInFullConfig?: PayInFullConfigDto) {
        return new UserBalanceThresholdModel(dto, isUserPreApproved, payInFullConfig);
    }

    get isVip() {
        return this.isUserPreApproved;
    }

    get isTrusted() {
        return this.dto.isTrusted;
    }

    get totalOutstandingBalance() {
        return Object.keys(this.dto.unpaidItems || {}).reduce(
            (totalOutstandingBalance, inventoryItemKey) =>
                totalOutstandingBalance + ((this.dto.unpaidItems || {})[inventoryItemKey]?.amountDueOnlineCents || 0),
            0
        );
    }

    get activeBalance() {
        return Object.keys(this.dto.winningItems || {}).reduce(
            (totalOutstandingBalance, inventoryItemKey) =>
                totalOutstandingBalance + ((this.dto.winningItems || {})[inventoryItemKey]?.amountDueOnlineCents || 0),
            0
        );
    }

    get dueBalanceCents() {
        return this.getDueBalanceCentsForNextBidAmount();
    }

    getDueBalanceCentsForNextBidAmount(amountCents = 0) {
        return this.isTrusted || this.isUserPreApproved
            ? this.totalOutstandingBalance
            : this.totalOutstandingBalance + this.activeBalance + amountCents;
    }

    get balanceThresholdCents(): number | undefined | null {
        if (!this.payInFullConfig?.balanceThresholdCents) {
            return;
        }
        const {vip, trusted, regular} = this.payInFullConfig?.balanceThresholdCents || {};
        if (this.isVip) {
            return vip;
        }
        if (this.isTrusted) {
            return trusted;
        }
        return regular;
    }

    get hasUserReachedBalanceThreshold() {
        return this.checkUserReachedBalanceThresholdForNextBidAmount();
    }

    checkUserReachedBalanceThresholdForNextBidAmount(amountCents = 0) {
        return this.balanceThresholdCents
            ? this.getDueBalanceCentsForNextBidAmount(amountCents) >= this.balanceThresholdCents
            : false;
    }
}
