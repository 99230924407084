import {Instance, types} from 'mobx-state-tree';
import BaseViewModel from '@joyrideautos/web-common-components/src/models/BaseViewModel';
import {OverdueBannerViewModel} from './OverdueBannerViewModel';
import {ExpiredBannerViewModel} from './ExpiredBannerViewModel';
import {AddPaymentMethodBannerViewModel} from './AddPaymentMethodBannerViewModel';
import {BiddingLimitReachedBannerViewModel} from './BiddingLimitReachedBannerViewModel';
import {CompletePurchaseBannerViewModel} from './CompletePurchaseBannerViewModel';

export enum BottomBannerEnum {
    COMPLETE_PURCHASE = 'COMPLETE_PURCHASE',
    OVERDUE = 'OVERDUE',
    EXPIRED = 'EXPIRED',
    ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
    BIDDING_LIMIT_REACHED = 'BIDDING_LIMIT_REACHED',
}

export const BottomBannerViewModel = BaseViewModel.named('BottomBannerViewModel')
    .props({
        completePurchaseBannerViewModel: types.optional(CompletePurchaseBannerViewModel, {}),
        overdueBannerViewModel: types.optional(OverdueBannerViewModel, {}),
        expiredBannerViewModel: types.optional(ExpiredBannerViewModel, {}),
        addPaymentMethodBannerViewModel: types.optional(AddPaymentMethodBannerViewModel, {}),
        biddingLimitReachedBannerViewModel: types.optional(BiddingLimitReachedBannerViewModel, {}),
    })
    .views((self) => ({
        get currentBanner(): BottomBannerEnum | null {
            // priorities
            if (self.expiredBannerViewModel.isActive) {
                return BottomBannerEnum.EXPIRED;
            }
            if (self.overdueBannerViewModel.isActive) {
                return BottomBannerEnum.OVERDUE;
            }
            if (self.biddingLimitReachedBannerViewModel.isActive) {
                return BottomBannerEnum.BIDDING_LIMIT_REACHED;
            }
            if (self.addPaymentMethodBannerViewModel.isActive) {
                return BottomBannerEnum.ADD_PAYMENT_METHOD;
            }
            if (self.completePurchaseBannerViewModel.isActive) {
                return BottomBannerEnum.COMPLETE_PURCHASE;
            }
            return null;
        },
        get currentViewModel() {
            if (!this.currentBanner) {
                return;
            }
            return {
                [BottomBannerEnum.COMPLETE_PURCHASE]: self.completePurchaseBannerViewModel,
                [BottomBannerEnum.OVERDUE]: self.overdueBannerViewModel,
                [BottomBannerEnum.EXPIRED]: self.expiredBannerViewModel,
                [BottomBannerEnum.ADD_PAYMENT_METHOD]: self.addPaymentMethodBannerViewModel,
                [BottomBannerEnum.BIDDING_LIMIT_REACHED]: self.biddingLimitReachedBannerViewModel,
            }[this.currentBanner];
        },
    }));

export interface BottomBannerViewModelType extends Instance<typeof BottomBannerViewModel> {}
