import {Timestamp} from '../types/common';

export enum FeeTypeEnum {
    KEY = 'key',
    OTHER = 'other',
}

export type FeePriceDto = {
    amount: number;
    description?: string;
    feeType?: string;
    feeUpdatedAt?: Timestamp;
    feeUpdatedBy?: string;
};

export function toFeePrice(feePrice: FeePriceDto | undefined) {
    if (!feePrice) {
        return;
    }
    const {amount, feeType, description} = feePrice;
    return {
        amount,
        feeType: toFeeType(feeType || description),
    };
}

export function toFeeType(description?: string) {
    return description && description.toLowerCase().includes('key') ? FeeTypeEnum.KEY : FeeTypeEnum.OTHER;
}
