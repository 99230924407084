import {Instance, destroy, getRoot} from 'mobx-state-tree';
import {LiveSequenceAuctionStateModel} from '../liveAuctionStateModel/LiveSequenceAuctionStateModel';
import {LiveListingAuctionStateModel} from '../liveAuctionStateModel/LiveListingAuctionStateModel';
import {BaseLiveAuctionStateModelType} from '../liveAuctionStateModel/BaseLiveAuctionStateModel';
import BaseStore from './BaseStore';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {observable} from 'mobx';

function makeKey({regionId, auctionId}: AuctionPath) {
    return `${regionId}-${auctionId}`;
}

export const LiveAuctionStateStore = BaseStore.named('LiveAuctionStateStore')
    .volatile(() => ({
        models: observable.map<string, BaseLiveAuctionStateModelType>(),
    }))
    .actions((self) => ({
        setModel(auctionPath: AuctionPath, model: BaseLiveAuctionStateModelType) {
            self.models.set(makeKey(auctionPath), model);
        },
        deleteModel(auctionPath: AuctionPath) {
            self.models.delete(makeKey(auctionPath));
        },
        modelFor(auctionPath: AuctionPath): BaseLiveAuctionStateModelType {
            let model = self.models.get(makeKey(auctionPath));
            if (!model) {
                const rootStore = getRoot(self) as any;
                const {auctionsStore} = rootStore;
                const {regionId, auctionId} = auctionPath;
                const auction = auctionsStore.findAuction(regionId, auctionId);
                if (!auction) {
                    throw new Error(`Can't find an auction for ${regionId}, ${auctionId}`);
                }
                // TODO: move to factory
                if (auction.isSequenceAuctionType) {
                    model = LiveSequenceAuctionStateModel.create(auctionPath, {rootStore});
                } else {
                    model = LiveListingAuctionStateModel.create(auctionPath, {rootStore});
                }
                this.setModel(auctionPath, model);
            }
            return model;
        },
        destroyModel(auctionPath: AuctionPath) {
            const model = self.models.get(makeKey(auctionPath));
            if (model) {
                this.deleteModel(auctionPath);
                requestAnimationFrame(() => {
                    destroy(model);
                });
            }
        },
    }))
    .views((self) => ({
        modelForAsync(auctionPath: AuctionPath): BaseLiveAuctionStateModelType | undefined {
            const model = self.models.get(makeKey(auctionPath));
            if (!model) {
                const rootStore = getRoot(self) as any;
                const {auctionsStore} = rootStore;
                const {regionId, auctionId} = auctionPath;
                const auction = auctionsStore.findAuction(regionId, auctionId);
                if (!auction) {
                    return;
                }
                setTimeout(() => self.modelFor(auctionPath), 0);
            }
            return model;
        },
    }));

export interface LiveAuctionStateStoreType extends Instance<typeof LiveAuctionStateStore> {}

export interface HasLiveAuctionStateStore {
    liveAuctionStateStore: LiveAuctionStateStoreType;
}
