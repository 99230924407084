import {Unsubscribe} from '../types';
import {BaseService} from './BaseService';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';
import {
    RemoveRoleForUserRPCReqData,
    RemoveRoleForUserRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/removeRoleForUser';
import {
    AddRoleForUserRPCReqData,
    AddRoleForUserRPCResData,
} from '@joyrideautos/auction-core/src/types/requests/rpcReqTypes/users/addRoleToUser';
import {UserRoleEnum} from '@joyrideautos/auction-core/src/dtos/UserDto';

export type ManagerListenerType = (sellerIds: string[]) => void;

export class ManagerRoleService extends BaseService {
    async removeManagerRoleForUser(data: Omit<RemoveRoleForUserRPCReqData, 'role'>) {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_ROLES_REMOVE_ROLE_FOR_USER)<
            RemoveRoleForUserRPCReqData,
            RemoveRoleForUserRPCResData
        >({
            ...data,
            role: UserRoleEnum.MANAGERS,
        });
    }

    async addManagerRoleToUser(data: Omit<AddRoleForUserRPCReqData, 'role'>) {
        await this.firebase.rpcService.call(FeReqRouteEnum.API_USER_ROLES_ADD_ROLE_TO_USER)<
            AddRoleForUserRPCReqData,
            AddRoleForUserRPCResData
        >({
            ...data,
            role: UserRoleEnum.MANAGERS,
        });
    }

    async fetchManagerSellers(uid: string): Promise<string[]> {
        const sellers = await this.firebase.database.fetchOnceArray<any>(`/userRoles/managers/${uid}/sellers`);
        return sellers.map((seller) => seller.key);
    }

    subscribeToManagerSellers(userId: string, listener: ManagerListenerType): Unsubscribe {
        return this.firebase.database.subscribe<{
            [sellerId: string]: boolean;
        }>(`/userRoles/managers/${userId}/sellers`, (sellers) => {
            this.logger.log('sellers', sellers);
            listener(sellers ? Object.keys(sellers) : []);
        });
    }
}
