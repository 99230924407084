import moment from 'moment';
import {BanReasonEnum, UserDto} from '../dtos/UserDto';

export const getBanReasonDescription = (reason: BanReasonEnum) => {
    const map = {
        [BanReasonEnum.FAILED_DEPOSIT]: 'Failed Deposit',
        [BanReasonEnum.PREVIOUSLY_BANNED_PHONE_OR_EMAIL]: 'Previously Banned Phone Or Email',
        [BanReasonEnum.PREVIOUSLY_BANNED_PAYMENT_METHOD]: 'Previously Banned Credit Card',
        [BanReasonEnum.FRAUD_OR_BAD_ACTOR]: 'Fraud Or Bad Actor',
        [BanReasonEnum.OTHER]: 'Other',
        [BanReasonEnum.BANNED_FOR_MULTIPLE_AUCTIONS]: 'Banned for multiple Auctions',
        [BanReasonEnum.EXPIRED_PAYMENT]: 'Banned for expired payments',
    };
    return map[reason];
};

/**
 * @deprecated use /core/src/models/UserModel.ts instead
 */
export const getUserFullName = (user: {firstName: string; lastName: string}) => {
    if (!user) {
        throw Error('Illegal argument: user param is undefined');
    }
    return `${user.firstName} ${user.lastName}`;
};

/**
 * @deprecated use /core/src/models/UserModel.ts instead
 */
export const getUserAddress = (user: UserDto): string => {
    if (!user.settings?.address) {
        return '';
    }
    const {street, apartment, city, state} = user.settings.address;
    const {zipcode} = user;
    const apartmentInfo = apartment ? `, ${apartment}` : '';
    return `${street}${apartmentInfo} ${city}, ${state} ${zipcode}}`.trim();
};

export const isUserPaymentInfoValid = (user: UserDto): boolean => {
    return userHasPaymentCustomerId(user) && user.settings!.paymentInfo!.paymentProvider === 'stripe';
};

export const userHasPaymentCustomerId = (user: UserDto): boolean => {
    return !!user?.settings?.paymentInfo?.paymentCustomerId;
};

/**
 * @deprecated use /core/src/models/UserModel.ts instead
 */
export const getPaymentCustomerId = (user: UserDto): string => {
    if (!user) {
        throw Error('Illegal argument: user param is undefined');
    }
    if (!userHasPaymentCustomerId(user)) {
        throw Error('paymentCustomerId is undefined');
    }
    return user.settings!.paymentInfo!.paymentCustomerId!;
};

export const isUsersEqual = (user1: UserDto, user2: UserDto) => {
    const sameFirstName = user1.firstName === user2.firstName;
    const sameLastName = user1.lastName === user2.lastName;
    const sameCompanyName = user1.companyName === user2.companyName;
    const sameEmail = user1.email === user2.email;

    return sameFirstName && sameLastName && sameCompanyName && sameEmail;
};

export function isDateValid(date: string | null | undefined) {
    return Boolean(date && moment(Date.parse(date)).isValid());
}

/**
 * @deprecated use /core/src/models/UserModel.ts instead
 */
export const isPhoneVerified = (user: UserDto) => {
    return isDateValid(user.phoneVerifiedAt);
};

/**
 * @deprecated use /core/src/models/UserModel.ts instead
 */
export const isEmailVerified = (user: UserDto) => {
    return isDateValid(user.emailVerifiedAt);
};

export function areUsersEqual(
    user1: Partial<UserDto>,
    user2: Partial<UserDto>,
    options?: {
        shouldCheckEmail?: boolean;
        shouldCheckPhone?: boolean;
    }
) {
    const shouldCheckEmail = options?.shouldCheckEmail ?? true;
    const shouldCheckPhone = options?.shouldCheckPhone ?? true;
    return (
        user1.firstName === user2.firstName &&
        user1.lastName === user2.lastName &&
        user1.settings?.address?.street === user2.settings?.address?.street &&
        user1.settings?.address?.apartment === user2.settings?.address?.apartment &&
        user1.settings?.address?.city === user2.settings?.address?.city &&
        user1.settings?.address?.state === user2.settings?.address?.state &&
        user1.companyName === user2.companyName &&
        user1.bidderNumber === user2.bidderNumber &&
        user1.zipcode === user2.zipcode &&
        user1.buyerTypeCode === user2.buyerTypeCode &&
        user1.businessRoleTypeCode === user2.businessRoleTypeCode &&
        (shouldCheckEmail ? user1.email === user2.email : true) &&
        (shouldCheckPhone ? user1.phone === user2.phone : true) &&
        !!user1.hasMultiBidAccess === !!user2.hasMultiBidAccess &&
        !!user1.isTrusted === !!user2.isTrusted &&
        !!user1.isExemptFromSalesTax === !!user2.isExemptFromSalesTax
    );
}
