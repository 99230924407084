import {Instance, isAlive, types} from 'mobx-state-tree';
import {formatDate, LONG_DATE_FORMAT} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {ListingAuctionStateEnum, SequenceAuctionStateEnum} from '../../liveAuctionStateModel/statemachines/types';
import {AuctionSettingsType, AuctionType} from './Auction';
import LoggerAwareViewModel from '../LoggerAwareViewModel';
import {RegionType} from '../Region';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {AuctionSeriesType} from './AuctionSeries';
import {BaseLiveAuctionStateModelType} from '../../liveAuctionStateModel/BaseLiveAuctionStateModel';
import primitives from '../../Primitives';

const AuctionAwareViewModel = LoggerAwareViewModel.named('AuctionAwareViewModel')
    .props({
        regionId: types.string,
        auctionId: types.string,
        searchInDeleted: primitives.boolean(),
    })
    .views((self) => {
        return {
            get auctionPath(): AuctionPath {
                const {regionId, auctionId} = self;
                return {regionId, auctionId};
            },
            get region(): RegionType | undefined {
                return self.regionsStore.findRegion(self.regionId);
            },
            get regionTitle(): string {
                return this.region ? this.region.renderedDisplayName : '';
            },
            get auction(): AuctionType | undefined {
                if (!isAlive(self)) {
                    return;
                }
                return self.auctionsStore.findAuction(self.regionId, self.auctionId, {
                    searchInDeleted: self.searchInDeleted.value,
                });
            },
            get isAuctionDeleted(): boolean | undefined {
                if (!self.auctionsStore.status.isReady) {
                    return undefined;
                }
                return self.auctionsStore.isAuctionDeleted(self.regionId, self.auctionId);
            },
            get isSkipDisabled(): boolean {
                return !this.isSkipEnabled;
            },
            get isSkipEnabled(): boolean {
                return (
                    !!this.auction?.settings?.startEvent &&
                    Date.parse(this.auction.settings.startEvent) < Date.now() &&
                    this.auctionState === SequenceAuctionStateEnum.SUSPENDED
                );
            },
            get startDate(): string {
                const auction = this.auction;
                return auction ? formatDate(auction.settings.startByAuctionType, LONG_DATE_FORMAT) : '';
            },
            get endDateListing(): string {
                const auction = this.auction;
                return auction ? formatDate(auction.settings.expiration, LONG_DATE_FORMAT) : '';
            },
            get auctionSeries(): AuctionSeriesType | undefined {
                const auction = self.auctionsStore.findAuction(self.regionId, self.auctionId, {searchInDeleted: true});
                return auction && self.auctionSeriesStore.findSeriesByKey(auction.auctionSeries);
            },
            get liveAuctionStateModel(): BaseLiveAuctionStateModelType | undefined {
                return self.liveAuctionStateStore.modelForAsync(this.auctionPath);
            },
            get auctionState(): SequenceAuctionStateEnum | ListingAuctionStateEnum | null | undefined {
                return this.liveAuctionStateModel?.auctionState;
            },
            get isAuctionEnded(): boolean {
                return Boolean(this.auction?.ended);
            },
            get isSequenceAuction(): boolean {
                return !!this.auction?.settings.isSequence;
            },
            get stateName(): string {
                const region = this.region;
                return region ? region.state : '';
            },
            get seriesTitle(): string {
                return this.auctionSeries ? this.auctionSeries.title : '';
            },
            get shouldUseReservePriceAsStartingBid(): boolean {
                return !!this.auction?.settings.shouldUseReservePriceAsStartingBid;
            },
            get shouldShowReservePrice(): boolean {
                return !!this.auction?.settings.shouldShowReservePrice;
            },
            get auctionStoreIsLoading(): boolean {
                return self.auctionsStore.status.isInProgress;
            },
            get isBuyNowEnabled(): boolean {
                return !!this.auction?.settings.isBuyNowEnabled;
            },
            get isHybridAuction(): boolean {
                return !!this.auction?.isHybridAuction;
            },
            get auctionSettings(): AuctionSettingsType | undefined {
                return this.auction?.settings;
            },
            get hasSellerRoleForAuction() {
                return self.sellerRoleStore.hasSellerRoleForAuction(self.regionId, self.auctionId);
            },
            get sellerIds() {
                return Array.from(this.auction?.sellers?.keys() ?? []);
            },
        };
    });

export default AuctionAwareViewModel;

export interface AuctionAwareViewModelType extends Instance<typeof AuctionAwareViewModel> {}

export const WithAuctionAwareViewModel = types
    .model('WithAuctionAwareViewModel', {
        _auctionModel: AuctionAwareViewModel,
    })
    .views((self) => ({
        get auctionModel() {
            return self._auctionModel;
        },
        get regionId() {
            return this.auctionModel.regionId;
        },
        get auctionId() {
            return this.auctionModel.auctionId;
        },
        get auction() {
            return this.auctionModel.auction;
        },
        get auctionSeries() {
            return this.auctionModel.auctionSeries;
        },
        get liveAuctionStateModel() {
            return this.auctionModel.liveAuctionStateModel;
        },
    }));

export const WithOptionalAuctionAwareViewModel = types
    .model('WithOptionalAuctionAwareViewModel', {
        _auctionModel: types.maybe(AuctionAwareViewModel),
    })
    .views((self) => ({
        get auctionModel() {
            return self._auctionModel;
        },
        get regionId() {
            return this.auctionModel?.regionId;
        },
        get auctionId() {
            return this.auctionModel?.auctionId;
        },
        get auction() {
            return this.auctionModel?.auction;
        },
        get auctionSeries() {
            return this.auctionModel?.auctionSeries;
        },
        get liveAuctionStateModel() {
            return this.auctionModel?.liveAuctionStateModel;
        },
    }));
