import {FeePriceDto, FeeTypeEnum} from '../dtos/FeePriceDto';
import {Money} from '../types/Money';

export class PerVehicleFee {
    constructor(private feePrice: FeePriceDto | null | undefined) {}

    get keeFee(): Money {
        return this.feePrice?.feeType === FeeTypeEnum.KEY ? Money.fromDollars(this.feePrice.amount) : Money.zero;
    }

    get otherFee(): Money {
        return this.feePrice?.feeType === FeeTypeEnum.OTHER ? Money.fromDollars(this.feePrice.amount) : Money.zero;
    }

    get amount(): Money {
        return Money.fromCents(this.keeFee.cents || this.otherFee.cents || 0);
    }
}
