import {Auth as Service, AuthUser, AuthCredential, UpdateProfile} from '@joyrideautos/ui-services/src/firebase/Auth';
import Logger from '@joyrideautos/auction-core/src/utils/logger/Logger';
import {
    signInWithCredential,
    signInWithEmailAndPassword,
    signInAnonymously,
    onAuthStateChanged,
    sendPasswordResetEmail,
    confirmPasswordReset,
    signOut,
    linkWithCredential,
    reauthenticateWithCredential,
    createUserWithEmailAndPassword,
    updateEmail,
    updatePassword,
    updateProfile,
    EmailAuthProvider,
    getAuth,
    connectAuthEmulator,
    Auth as FirebaseAuth,
    RecaptchaVerifier,
    User as FirebaseUser,
    signInWithCustomToken,
    getIdToken,
} from 'firebase/auth';
import {AppConfig} from '@joyrideautos/ui-services/src/AppConfig';
import {FirebaseApp} from 'firebase/app';

class Auth implements Service {
    constructor(private auth: FirebaseAuth, private appConfig: AppConfig, private logger?: Logger) {
        if (this.appConfig.emulatorsConfig.authenticationUrl && this.appConfig.isEmulatedEnv) {
            this.logger?.log(
                '        -- hitting local auth emulator',
                this.appConfig.emulatorsConfig.authenticationUrl
            );
            connectAuthEmulator(this.auth, this.appConfig.emulatorsConfig.authenticationUrl, {disableWarnings: true});
        }
    }

    get currentUser() {
        return this.auth.currentUser;
    }

    async signInWithEmailAndPassword(email: string, password: string) {
        await signInWithEmailAndPassword(this.auth, email, password);
    }

    async signInWithCustomToken(customToken: string): Promise<void> {
        await signInWithCustomToken(this.auth, customToken);
    }

    async signInWithCredential(credential: AuthCredential) {
        const {user} = await signInWithCredential(this.auth, credential as any);
        return user;
    }

    async getIdTokenOfLoggedInUser() {
        const user = this.currentUser;
        if (!user) {
            return;
        }
        return getIdToken(this.currentUser);
    }

    getEmailAndPasswordCredential(email: string, password: string) {
        return EmailAuthProvider.credential(email, password) as any;
    }

    async createUserWithEmailAndPassword(email: string, password: string) {
        const {user} = await createUserWithEmailAndPassword(this.auth, email, password);
        return user;
    }

    async signInAnonymously() {
        const {user} = await signInAnonymously(this.auth);
        return user;
    }

    onAuthStateChanged(cb: (user: AuthUser | null) => void) {
        return onAuthStateChanged(this.auth, cb);
    }

    async sendPasswordResetEmail(email: string, url?: string) {
        const config = url ? {url} : undefined;
        await sendPasswordResetEmail(this.auth, email, config);
    }

    async confirmPasswordReset(code: string, newPassword: string) {
        await confirmPasswordReset(this.auth, code, newPassword);
    }

    async signOut() {
        await signOut(this.auth);
    }

    linkWithCredential(credential: AuthCredential) {
        if (!this.currentUser) {
            throw new Error('No current user');
        }
        return linkWithCredential(this.currentUser, credential as any);
    }

    reauthenticateWithCredential(credential: AuthCredential) {
        if (!this.currentUser) {
            throw new Error('No current user');
        }
        return reauthenticateWithCredential(this.currentUser, credential as any);
    }

    updateEmail(email: string): Promise<void> {
        if (!this.currentUser) {
            throw new Error('No current user');
        }
        return updateEmail(this.currentUser, email);
    }

    updatePassword(password: string, user?: FirebaseUser) {
        if (!this.currentUser || !user) {
            throw new Error('No current user');
        }
        return updatePassword(user ?? this.currentUser, password);
    }

    updateProfile(updates: UpdateProfile) {
        if (!this.currentUser) {
            throw new Error('No current user');
        }
        return updateProfile(this.currentUser, updates);
    }

    createRecaptchaVerifier(domElementId: string) {
        return new RecaptchaVerifier(this.auth, domElementId, {
            size: 'invisible',
        });
    }
}

export function createAuth(firebaseApp: FirebaseApp, appConfig: AppConfig, logger?: Logger): Service {
    return new Auth(getAuth(firebaseApp), appConfig, logger);
}
