import {detach, flow, getEnv, IDisposer, Instance, types} from 'mobx-state-tree';
import {when} from 'mobx';
import {ItemPath, ItemDto} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {Item, ItemSnapshotIn, mapAuctionItemDtoToModel} from '../types/item/Item';
import type {ItemType} from '../types/item/Item';
import {PersistedItem, PersistedItemSnapshotIn, PersistedItemStatus} from '../types/item/PersistedItem';
import type {PersistedItemType} from '../types/item/PersistedItem';
import {LoadingStatus, withStatus} from '../utils/LoadingStatus';
import {ItemStatusEnum, PersistedItemStatusEnum} from '@joyrideautos/auction-core/src/types/ItemTypes';
import {WithKey} from '@joyrideautos/auction-core/src/types/common';
import BaseStore from './BaseStore';
import {UIServicesAwareViewModelType} from '../common/UIServicesAwareViewModel';

type SetItemsFuncType<T> = (items: T[]) => void;
type ItemsSubscription<T> = (callback: SetItemsFuncType<T>) => IDisposer;

export interface ItemsContainerLike<T> {
    // TODO: (Future) use 'get allItems' instead for performance reason.
    getAllItems: () => T[];
    get allItems(): T[];
    getItem: (regionId: string | undefined, auctionId: string | undefined, itemKey: string) => T | undefined;
    getItemByKey: (itemKey: string) => T | undefined;
    loadingStatus: LoadingStatus;
    reload?: () => void;
}

export const ItemsStore = BaseStore.named('ItemsStore')
    .props({
        itemsByRegionByAuction: types.map(types.map(types.map(Item))),
    })
    .volatile(() => ({
        itemsSubscription: null as ItemsSubscription<ItemType> | null,
        loadingStatus: new LoadingStatus(),
        disposers: [] as IDisposer[],
    }))
    .views((self) => ({
        get rootStore() {
            return getEnv(self).rootStore;
        },
        getItem(regionId: string | undefined, auctionId: string | undefined, itemKey: string): ItemType | undefined {
            if (!regionId || !auctionId) {
                return undefined;
            }
            const itemsByRegion = self.itemsByRegionByAuction.get(regionId);
            if (itemsByRegion) {
                const itemsByAuction = itemsByRegion.get(auctionId);
                return itemsByAuction ? itemsByAuction.get(itemKey) : undefined;
            }
        },
        getItemById({regionId, auctionId, itemId}: ItemPath): ItemType | undefined {
            const itemsByRegion = self.itemsByRegionByAuction.get(regionId);
            if (itemsByRegion) {
                const itemsByAuction = itemsByRegion.get(auctionId);
                if (itemsByAuction) {
                    return Array.from(itemsByAuction.values()).filter((item) => String(item.itemId) === itemId)[0];
                }
            }
        },
        getItemByKey(persistenceKey: string): ItemType | undefined {
            return this.getAllItems().find((item) => item.persistenceKey === persistenceKey);
        },
        hasItem(regionId: string, auctionId: string, itemKey: string): boolean {
            return !!this.getItem(regionId, auctionId, itemKey);
        },
        getItems(items: ItemPath[]): ItemType[] {
            return items
                .filter((item) => this.hasItem(item.regionId, item.auctionId, item.itemId))
                .map((item) => this.getItem(item.regionId, item.auctionId, item.itemId)!);
        },
        getItemsForSeller(regionId: string, auctionId: string, sellerId: string): ItemType[] {
            const itemsByRegion = self.itemsByRegionByAuction.get(regionId);
            if (itemsByRegion) {
                const itemsByAuction = itemsByRegion.get(auctionId);
                return itemsByAuction
                    ? Array.from(itemsByAuction.values()).filter((item) => item.sellerId === sellerId)
                    : [];
            }
            return [];
        },
        // TODO: (Future) use 'get allItems' instead for performance reason.
        getAllItems(): ItemType[] {
            const allItems: ItemType[] = [];

            self.itemsByRegionByAuction.forEach((allItemsForRegion) => {
                allItemsForRegion.forEach((allItemsForAuction) => {
                    allItems.push(...allItemsForAuction.values());
                });
            });

            return allItems;
        },
        get allItems(): ItemType[] {
            const allItems: ItemType[] = [];

            self.itemsByRegionByAuction.forEach((allItemsForRegion) => {
                allItemsForRegion.forEach((allItemsForAuction) => {
                    allItems.push(...allItemsForAuction.values());
                });
            });

            return allItems;
        },
        getAllItemsExceptSold(): ItemType[] {
            return this.getAllItems().filter((i) => i.status !== ItemStatusEnum.SOLD);
        },
    }))
    .actions((self) => ({
        setItemsSubscription(subscriptionFun: ItemsSubscription<ItemType>) {
            self.itemsSubscription = subscriptionFun;
        },
        setItem(item: ItemType) {
            if (!self.itemsByRegionByAuction.has(item.regionId)) {
                self.itemsByRegionByAuction.set(item.regionId, {});
            }
            const itemsByRegion = self.itemsByRegionByAuction.get(item.regionId)!;

            if (!itemsByRegion.has(item.auctionId)) {
                itemsByRegion.set(item.auctionId, {});
            }
            const itemsByRegionByAuction = itemsByRegion.get(item.auctionId)!;

            const existingItem = itemsByRegionByAuction.get(item.key);
            if (existingItem) {
                detach(existingItem);
            }
            itemsByRegionByAuction.put(item);
        },
        setItems(items: ItemType[], removeMissing: boolean) {
            let regionId: string | null = null;
            let auctionId: string | null = null;
            let sameAuction = true;

            const updated = new Set<string>();
            items.forEach((item) => {
                try {
                    this.setItem(item);
                    if (!removeMissing) {
                        return;
                    }
                    updated.add(item.key);
                    if (!regionId) {
                        regionId = item.regionId;
                    }
                    if (!auctionId) {
                        auctionId = item.auctionId;
                    }
                    if (regionId !== item.regionId || auctionId !== item.auctionId) {
                        sameAuction = false;
                    }
                } catch (e: any) {
                    console.warn(`cannot process item ${JSON.stringify(item)}`, e.message);
                }
            });
            if (removeMissing && sameAuction && regionId && auctionId) {
                const itemsByRegion = self.itemsByRegionByAuction.get(regionId);
                const itemsByRegionByAuction = itemsByRegion && itemsByRegion.get(auctionId);
                if (itemsByRegionByAuction) {
                    try {
                        itemsByRegionByAuction.forEach((existingItem) => {
                            if (!updated.has(existingItem.key)) {
                                itemsByRegionByAuction.delete(existingItem.key);
                                detach(existingItem);
                            }
                        });
                    } catch (e) {
                        console.warn(`cannot remove deleted items for ${regionId}/${auctionId}`);
                    }
                }
            }
        },
    }))
    .actions((self) => ({
        fetchItems: flow(function* (items: ItemPath[]): Generator<Promise<ItemDto[]>, any, any> {
            const itemsToLoad = items.filter((item) => !self.getItem(item.regionId, item.auctionId, item.itemId));
            const newItems: ItemDto[] = yield self.itemsService.fetchItems(itemsToLoad);
            self.setItems(newItems.map(mapAuctionItemDtoToModel), false);

            return self.getItems(items);
        }),
        reload() {},
    }))
    .views((self) => ({
        fetchItemAsync(itemPath: ItemPath) {
            const item = self.getItem(itemPath.regionId, itemPath.auctionId, itemPath.itemId);
            if (!item) {
                setTimeout(() => self.fetchItems([itemPath]), 0);
            }
            return item;
        },
    }))
    .actions((self) => {
        return {
            afterCreate: function () {
                self.loadingStatus.setInProgress();
                self.disposers.push(
                    when(
                        () => !!self.itemsSubscription,
                        () => {
                            self.disposers.push(
                                self.itemsSubscription!((items) => {
                                    self.setItems(items, true);
                                    self.loadingStatus.setReady();
                                })
                            );
                        }
                    )
                );
            },
            beforeDestroy: function () {
                self.disposers.forEach((disposer) => disposer());
            },
        };
    });

export interface ItemsStoreType extends Instance<typeof ItemsStore> {}

export interface HasItemsStore {
    itemsStore: ItemsStoreType;
}

export function createItemsStore(
    props: {itemsByRegionByAuction?: Record<string, Record<string, Record<string, ItemSnapshotIn>>>},
    {rootStore}: {rootStore: UIServicesAwareViewModelType}
): ItemsStoreType {
    return ItemsStore.create(props, {rootStore});
}

const PersistedItemsStoreForSeller = BaseStore.named('PersistedItemsStoreForSeller')
    .props({
        sellerId: types.string,
        status: types.maybe(PersistedItemStatus),
        items: types.map(PersistedItem),
    })
    .volatile(() => ({
        loadingStatus: new LoadingStatus(),
    }))
    .actions((self) => {
        return {
            setItems(items: PersistedItemType[]) {
                items.forEach((item) => self.items.put(item));
            },
        };
    })
    .actions((self) => {
        return {
            fetchItems: flow(function* () {
                try {
                    const items = yield withStatus(self.loadingStatus)(() =>
                        self.itemsService.fetchItemsForSeller(self.sellerId, self.status)
                    );
                    self.setItems(items);
                } catch (e: any) {
                    self.loadingStatus.setError(e.message);
                }
            }),
            reload() {
                self.items.clear();
                this.fetchItems().catch((e) => console.log(e));
            },
        };
    })
    .views((self) => {
        return {
            getAllItems(): PersistedItemType[] {
                if (self.loadingStatus.isNew) {
                    setTimeout(self.fetchItems);
                }
                return Array.from(self.items.values());
            },
            getItem(regionId: string, auctionId: string, itemKey: string): PersistedItemType | undefined {
                return Array.from(self.items.values()).find(
                    (item) => item.regionId === regionId && item.auctionId === auctionId && item.key === itemKey
                );
            },
            getItemByKey(key: string): PersistedItemType | undefined {
                return self.items.get(key);
            },
            fetchItemAsync(key: string): PersistedItemType | undefined {
                const item = this.getItemByKey(key);
                if (!item) {
                    setTimeout(() => self.fetchItems(), 0);
                }
                return item;
            },
        };
    });

export interface PersistedItemsStoreForSellerType extends Instance<typeof PersistedItemsStoreForSeller> {}

export function createPersistedItemsStoreForSeller(
    props: {
        sellerId: string;
        status?: PersistedItemStatusEnum;
    },
    {rootStore}: {rootStore: UIServicesAwareViewModelType}
) {
    return PersistedItemsStoreForSeller.create(props, {rootStore});
}

function auctionItemKey(regionId: string, auctionId: string, itemId: string | number) {
    return `${regionId}-${auctionId}-${itemId}`;
}

const PersistedItemsStore = BaseStore.named('PersistedItemsStore')
    .props({
        items: types.map(PersistedItem),
    })
    .volatile(() => ({
        loadingStatuses: {} as Record<string, LoadingStatus>,
        keyMap: {} as Record<string, string>,
        fetchInProgress: {} as Record<string, boolean>,
        itemsSubscription: null as ItemsSubscription<PersistedItemType> | null,
        disposers: [] as IDisposer[],
        globalLoadingStatus: new LoadingStatus(),
    }))
    .views((self) => {
        return {
            getLoadingStatusForItem(persistedKey: string) {
                if (!self.loadingStatuses[persistedKey]) {
                    self.loadingStatuses[persistedKey] = new LoadingStatus();
                }
                return self.loadingStatuses[persistedKey];
            },
        };
    })
    .actions((self) => {
        return {
            fetchItemByKey: flow(function* (persistenceKey: string) {
                const item: PersistedItemType | undefined = yield self.itemsService.fetchItemKey(persistenceKey);
                if (!item) {
                    return;
                }
                self.items.set(persistenceKey, item);

                const {regionId, auctionId, itemId} = item;
                if (!regionId || !auctionId || !itemId) {
                    return;
                }
                self.keyMap[auctionItemKey(regionId, auctionId, itemId)] = persistenceKey;
            }),
            fetchItemForAuction: flow(function* (itemPath: ItemPath, sellerOrManagerCompanyId?: string) {
                const {regionId, auctionId, itemId} = itemPath;
                const item: WithKey<PersistedItemType> | undefined =
                    yield self.itemsService.fetchPersistedItemForAuction(itemPath, sellerOrManagerCompanyId);
                if (!item) {
                    return;
                }
                const persistenceKey = item.key;

                self.items.set(persistenceKey, item);
                self.keyMap[auctionItemKey(regionId, auctionId, itemId)] = persistenceKey;
            }),
            setItemsSubscription(subscriptionFun: ItemsSubscription<PersistedItemType>) {
                self.itemsSubscription = subscriptionFun;
            },
            setItems(items: PersistedItemType[]) {
                self.items.replace(items.reduce((acc, item) => ({...acc, [item.key]: item}), {}));
            },
        };
    })
    .views((self) => {
        return {
            getItemByKey(persistenceKey: string) {
                const item = self.items.get(persistenceKey);
                if (!item) {
                    requestAnimationFrame(() => {
                        self.fetchItemByKey(persistenceKey).catch((e) => console.log(e));
                    });
                }
                return item;
            },
            get sellerOrManagerCompanyId() {
                return self.sellerSessionStorage.sellerId;
            },
            getItemForAuction(itemPath: ItemPath): PersistedItemType | undefined {
                const {regionId, auctionId, itemId} = itemPath;
                const persistenceKey = self.keyMap[auctionItemKey(regionId, auctionId, itemId)];
                const inProgress = self.fetchInProgress[auctionItemKey(regionId, auctionId, itemId)];
                if (!persistenceKey && !inProgress) {
                    self.fetchInProgress[auctionItemKey(regionId, auctionId, itemId)] = true;
                    requestAnimationFrame(() => {
                        self.fetchItemForAuction(itemPath, this.sellerOrManagerCompanyId)
                            .then(() => {
                                self.fetchInProgress[auctionItemKey(regionId, auctionId, itemId)] = false;
                            })
                            .catch((e) => {
                                self.logger.log('ItemsStore.getItemForAuction', e);
                            });
                    });
                }
                return self.items.get(persistenceKey);
            },
            get wonItems(): PersistedItemType[] {
                return Array.from(self.items.values()).filter(
                    (item) =>
                        item.status &&
                        [
                            PersistedItemStatusEnum.SOLD,
                            PersistedItemStatusEnum.PAID,
                            PersistedItemStatusEnum.CLAIMED,
                        ].includes(item.status)
                );
            },
            getWonItemsForAuction(auctionId: string): PersistedItemType[] {
                return this.wonItems.filter((item) => item.auctionId === auctionId);
            },
        };
    })
    .actions((self) => ({
        afterCreate() {
            self.globalLoadingStatus.setInProgress();
            self.disposers.push(
                when(
                    () => !!self.itemsSubscription,
                    () => {
                        self.disposers.push(
                            self.itemsSubscription!((items) => {
                                self.setItems(items);
                                self.globalLoadingStatus.setReady();
                            })
                        );
                    }
                )
            );
        },
        beforeDestroy() {
            self.disposers.forEach((disposer) => disposer());
        },
    }));

export interface PersistedItemsStoreType extends Instance<typeof PersistedItemsStore> {}

export function createPersistedItemsStore(
    props: {items?: Record<string, PersistedItemSnapshotIn>},
    {rootStore}: {rootStore: UIServicesAwareViewModelType}
) {
    return PersistedItemsStore.create(props, {rootStore});
}
