import React, {FC, ReactNode, useEffect} from 'react';
import {UserInfo, isAnonymous} from '@joyrideautos/auction-core/src/dtos/UserDto';
import {observer} from 'mobx-react';
import {Routes} from '../../Routes';
import logo from '@joyrideautos/ui-common/src/images/Logo_black.svg';
import {Link, useRouter} from '@joyrideautos/web-common-components/src/router/Router';
import {MenuViewModel} from './MenuViewModel';
import {rootStore} from '@models/stores/RootStore';
import {Affix, Button, Menu as AntdMenu, Drawer} from 'antd';
import type {MenuProps} from 'antd';
import {createCurrentUserMenu} from './menus/createCurrentUserMenu';
import {createSellerToolsMenu} from './menus/createSellerToolsMenu';
import {createHelpMenu} from './menus/createHelpMenu';
import {createNotificationMenu} from './menus/createNotificationMenu';
import {createHomeMenu} from './menus/createHomeMenu';
import {createMyVehiclesMenu} from './menus/createMyVehiclesMenu';
import {createAuctionsMenu} from './menus/createAuctionsMenu';
import {createVehicleSearchMenu} from './menus/createVehicleSearchMenu';
import {isMobile} from 'react-device-detect';
import {CloseCircleOutlined, MenuOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import {useViewModel} from '@joyrideautos/ui-models/src/hooks/useViewModel';
import {createSignInMenu} from './menus/createSignInMenu';
import {createSignUpMenu} from './menus/createSignUpMenu';
import {COMPANY_NAME} from '@joyrideautos/auction-core/src/constants/Constants';
import './Header.less';

export interface AuctionMenuProps {
    hideMenu?: boolean;
    userInfo?: UserInfo;
    signOut?: (callback: () => void) => void;
    isEmailVerifiedStep?: boolean;
    top?: ReactNode | null;
    footer?: ReactNode | null;
}

const Header: FC<AuctionMenuProps> = observer(
    ({top, footer, hideMenu, userInfo, signOut, isEmailVerifiedStep = false}) => {
        const {viewModel} = useViewModel(MenuViewModel);
        const {location} = useRouter();

        useEffect(() => {
            if (viewModel?.visibleDrawer.value) {
                document.body.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
            }
            return () => {
                document.body.classList.remove('menu-open');
            };
        }, [viewModel?.visibleDrawer.value]);

        useEffect(() => {
            viewModel?.pathname.setValue(location.pathname);
        }, [viewModel?.pathname, location.pathname]);

        if (!viewModel) {
            return null;
        }
        const {visibleDrawer, currentMenu} = viewModel;
        const myVehiclesBadgeCount = viewModel.badgeCountForMenuItem('my_vehicles');

        const desktopItems: MenuProps['items'] = [
            createHomeMenu(),
            createAuctionsMenu(),
            createVehicleSearchMenu(),
            createMyVehiclesMenu(myVehiclesBadgeCount),
            rootStore.sellerRoleStore.hasSellerRoleForAnyCompany()
                ? createSellerToolsMenu({isGateCodesEnabled: viewModel.isGateCodesEnabled})
                : null,
            createHelpMenu(),
            createNotificationMenu(),
            createCurrentUserMenu(viewModel, isEmailVerifiedStep, signOut),
        ];

        const mobileItems: MenuProps['items'] = [
            createCurrentUserMenu(viewModel, isEmailVerifiedStep, signOut),
            createHomeMenu(),
            createAuctionsMenu(),
            createVehicleSearchMenu(),
            createMyVehiclesMenu(myVehiclesBadgeCount),
            rootStore.sellerRoleStore.hasSellerRoleForAnyCompany()
                ? createSellerToolsMenu({isGateCodesEnabled: viewModel.isGateCodesEnabled})
                : null,
            createHelpMenu(),
        ];

        const anonymousItems: MenuProps['items'] = [createSignInMenu(), createSignUpMenu()];

        const mobileHeaderItems: any = [createNotificationMenu()];

        const anonymous = isAnonymous(userInfo);
        const shouldHideMenu = hideMenu === undefined ? !isEmailVerifiedStep : hideMenu;

        const onClick: MenuProps['onClick'] = (menu) => {
            if (!['search', 'notification', 'help'].includes(menu.key)) {
                currentMenu.setValue(menu.key);
            }
            if (isMobile) {
                visibleDrawer.setValue(false);
            }
        };

        return (
            <Affix className="header-affix">
                <div className="header-wrapper">
                    {top}
                    <header
                        className={classNames('container main-header', {
                            'is-mobile': isMobile,
                            'is-anonymous': anonymous,
                        })}>
                        {!anonymous && (
                            <Button
                                className="btn-mobile-menu"
                                type="link"
                                onClick={() => {
                                    visibleDrawer.toggle();
                                }}>
                                <span className="open-item">
                                    <MenuOutlined />
                                    <em>Menu</em>
                                </span>
                                <span className="close-item">
                                    <CloseCircleOutlined />
                                </span>
                            </Button>
                        )}
                        <Link to={anonymous ? Routes.AUCTIONS() : Routes.HOME()} className="logo">
                            <img src={logo} width="103" height="34" alt={COMPANY_NAME} />
                        </Link>

                        {/* Mobile header items */}
                        <AntdMenu
                            onClick={onClick}
                            selectedKeys={[currentMenu.value]}
                            mode="horizontal"
                            items={anonymous ? anonymousItems : mobileHeaderItems}
                            className="mobile-header-menu"
                        />

                        {isMobile && (
                            <Drawer
                                placement="top"
                                closable={false}
                                onClose={() => visibleDrawer.setValue(false)}
                                open={visibleDrawer.value}
                                rootClassName={classNames({'mobile-drawer': true, 'with-top-view-mobile': !!top})}>
                                {/* Mobile items */}
                                <AntdMenu
                                    onClick={onClick}
                                    selectedKeys={[currentMenu.value]}
                                    mode="inline"
                                    items={mobileItems}
                                />
                            </Drawer>
                        )}

                        {/* Desktop items */}
                        {!shouldHideMenu && !isMobile && (
                            <AntdMenu
                                triggerSubMenuAction="click"
                                onClick={onClick}
                                selectedKeys={[currentMenu.value]}
                                mode="horizontal"
                                items={anonymous ? anonymousItems : desktopItems}
                            />
                        )}
                    </header>
                </div>
                {footer}
            </Affix>
        );
    }
);

export default Header;
