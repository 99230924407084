import {types, IDisposer} from 'mobx-state-tree';
import RootStoreAwareViewModel from './stores/rootStore/RootStoreAwareViewModel';
import SingletonAwareViewModel from './common/SingletonAwareViewModel';
import {AuthUserType} from './types/UserInfo';
import {isMobile} from 'react-device-detect';
import i18n from 'i18next';

const BaseViewModel = types
    .compose(RootStoreAwareViewModel, SingletonAwareViewModel)
    .named('BaseViewModel')
    .volatile(() => ({
        disposers: [] as IDisposer[],
    }))
    .views((self) => ({
        get authUser(): AuthUserType | undefined {
            return self.authUserStore.userInfo;
        },
        get isUserAuthenticated(): boolean {
            return this.authUser != null && !this.authUser.isAnonymous;
        },
        get isMobile(): boolean {
            if (typeof isMobile === 'undefined') {
                self.logger.log('isMobile is not set for ', self.toString());
            }
            return isMobile;
        },
        get isSellerOrManager() {
            return Boolean(self.sellerSessionStorage.sellerId);
        },
        t(...params: Parameters<typeof i18n.t>) {
            return self.rootStore.t(...params);
        },
    }))
    .actions((self) => ({
        afterCreate() {},
        beforeDestroy() {
            self.disposers.forEach((d) => d());
        },
    }));

export default BaseViewModel;
