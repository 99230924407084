import {WinningBidFeesInMoney} from './types';
import {zeroWinningBidFees} from './utils';
import type {StorageTaxCalculator} from './storageTaxCalculator/StorageTaxCalculator';
import {FeesBreakdownCalculator} from './FeesBreakdownCalculator';
import {StepFunctionBuyerFeeCalculator} from './StepFunctionBuyerFeeCalculator';
import {PercentPlatformFeeCalculator, PlatformFeeType} from './platformFeeCalculator/PercentPlatformFeeCalculator';
import {FeePriceDto} from '../dtos/FeePriceDto';
import {StepFunctionFee} from '../dtos/SellerDto';
import {SalesTaxCalculator} from './SalesTaxCalculator';
import {AdminFeeCalculator} from './AdminFeeCalculator';
import {Money} from '../types/Money';
import {PerVehicleFee} from './PerVehicleFee';
import {PlatformFeeTypeEnum} from '../dtos/PlatformFeeScheduleDto';

/**
 * --------------------------------------------------------------------------------------------
 * version 1 (EXT-264):
 * bid = (X + af + bsf(X)) * (1 + pf);
 * X + bsf(X) = bid / (1 + pf);
 * --------------------------------------------------------------------------------------------
 * version 2 (EXT-1045):
 * bid = (X + af + bsf(X) + fp) * (1 + pf);
 * X + bsf(X) = bid / (1 + pf) - (af + fp);
 * --------------------------------------------------------------------------------------------
 * version 3 (EXT-1463 after fixes):
 * isPlatformFeeTakesOfSellerFee=true
 * bid = (X + af + bsf(X) + fp) * (1 + pf) + X * stPerc;
 *
 * bid = (X + bsf(X)) * (1 + pf) + (af + fp) * (1 + pf) + X * stPerc;
 * bid = X * (1 + pf)  + bsf(X) * (1 + pf) + (af + fp) * (1 + pf) + X * stPerc;
 * bid = X * (1 + pf) + X * stPerc + bsf(X) * (1 + pf) + (af + fp) * (1 + pf);
 * bid = X * (1 + pf + stPerc) + bsf(X) * (1 + pf) + (af + fp) * (1 + pf);
 * bid - (af + fp) * (1 + pf) = X * (1 + pf + stPerc) + bsf(X) * (1 + pf) ;
 * X * (1 + pf + stPerc) + bsf(X) * (1 + pf) = bid - (af + fp) * (1 + pf);
 * Final equation (EXT-3064):
 * X + bsf(X) * (1 + pf) / (1 + pf + stPerc) = (bid - (af + fp) * (1 + pf)) / (1 + pf + stPerc);
 *
 * --------------------------------------------------------------------------------------------
 * version 4 (EXT-2677 after fixes):
 * isPlatformFeeTakesOfSellerFee=false
 * bid = (X + af + fp) * (1 + pf) + bsf(X)  + X * stPerc;
 *
 * bid = X * (1 + pf) + (af + fp) * (1 + pf) + bsf(X)  + X * stPerc;
 * bid - (af + fp) * (1 + pf) = X * (1 + pf) + bsf(X)  + X * stPerc;
 * bid - (af + fp) * (1 + pf) = X * (1 + pf + stPerc) + bsf(X);
 * X * (1 + pf + stPerc) + bsf(X) = bid - (af + fp) * (1 + pf);
 * Final equation (EXT-3064):
 * X + bsf(X) / (1 + pf + stPerc) = (bid - (af + fp) * (1 + pf)) / (1 + pf + stPerc);
 * a = (1 + pf + stPerc);
 * b = (bid - (af + fp) * (1 + pf)) / a;
 * X + bsf(X) / a = b;
 * --------------------------------------------------------------------------------------------
 * version 5 (MP-7):
 *
 * isPlatformFeeTakesOfSellerFee=false
 * bid = (X + af + fp) * (1 + pf) + bsf(X)  + (X + af + fp + bsf(X)) * stPerc;
 *
 * bid = X * (1 + pf) + (af + fp) * (1 + pf) + bsf(X) + (X + bsf(X)) * stPerc + (af + fp) * stPerc;
 * bid - (af + fp) * (1 + pf) - (af + fp) * stPerc = X * (1 + pf) + bsf(X) + (X + bsf(X)) * stPerc;
 * bid - (af + fp) * (1 + pf) - (af + fp) * stPerc = X * (1 + pf + stPerc) + (1 + stPerc) * bsf(X);
 * X * (1 + pf + stPerc) + (1 + stPerc) * bsf(X) = bid - (af + fp) * (1 + pf) - (af + fp) * stPerc;
 * X + ((1 + stPerc) / (1 + pf + stPerc)) * bsf(X)  = (bid - (af + fp) * (1 + pf + stPerc)) / (1 + pf + stPerc);
 *
 * isPlatformFeeTakesOfSellerFee=true
 * bid = (X + bsf(X) + af + fp) * (1 + pf + stPerc);
 *
 * bid = (X + bsf(X)) * (1 + pf + stPerc) + (af + fp) * (1 + pf + stPerc);
 * bid - (af + fp) * (1 + pf + stPerc) = (X + bsf(X)) * (1 + pf + stPerc);
 * (X + bsf(X)) * (1 + pf + stPerc) = bid - (af + fp) * (1 + pf + stPerc);
 * X + bsf(X) = (bid - (af + fp) * (1 + pf + stPerc)) / (1 + pf + stPerc);
 *
 * --------------------------------------------------------------------------------------------
 *
 * X - sales price
 * af - admin fee
 * pf - platform fee
 * bsf - buyer step function fee
 * fp - vehicle fixed fee
 * stPerc - sales tax percent
 *
 * EXT-4008
 *
 * if X >= total_unpaid_bill
 * X + bsf(X)*(1 + pf)/(1 + pf + stPerc) = (bid - (af + fp) * (1 + pf) - impoundStorageTax)/(1 + pf + stPerc)
 * X * (1 + pf + stPerc) + bsf(X)*(1 + pf) = bid - (af + fp) * (1 + pf) - impoundStorageTax
 * bid - (af + fp) * (1 + pf) - impoundStorageTax = X * (1 + pf + stPerc) + bsf(X)*(1 + pf)
 * bid = X * (1 + pf + stPerc) + bsf(X) * (1 + pf) + (af + fp) * (1 + pf) + impoundStorageTax
 * bid = X * (1 + pf) + X * stPerc + bsf(X) * (1 + pf) + (af + fp) * (1 + pf) + impoundStorageTax
 * bid = X * (1 + pf) + bsf(X) * (1 + pf) + (af + fp) * (1 + pf) + X * stPerc  + impoundStorageTax
 *
 * isPlatformFeeTakesOfSellerFee=true
 * // caclulate new
 * bid = (X + bsf(X) + af + fp) * (1 + pfPerc) + (X + bsf(X) + af + fp) * stPerc  + impoundStorageTax
 * bid = (X + bsf(X) + af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax
 * bid = (X + bsf(X)) * (1 + pfPerc + stPerc) + (af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax
 * bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax) = (X + bsf(X)) * (1 + pfPerc + stPerc)
 * (X + bsf(X)) * (1 + pfPerc + stPerc) = bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)
 * X + bsf(X) = (bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)) / (1 + pfPerc + stPerc)
 *
 * // final result
 * X + bsf(X) = (bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)) / (1 + pfPerc + stPerc)
 *
 * isPlatformFeeTakesOfSellerFee=false
 * // caclulate new
 * bid = (X + bsf(X) + af + fp) + X * pfPerc + (X + bsf(X) + af + fp) * stPerc  + impoundStorageTax
 * bid = (X + bsf(X) + af + fp) + (X + bsf(X) + af + fp) * stPerc + X * pfPerc + impoundStorageTax
 * bid = (X + bsf(X) + af + fp) * (1 + stPerc) + X * pfPerc + impoundStorageTax
 * bid = (X + bsf(X)) * (1 + stPerc) + (af + fp) * (1 + stPerc) + X * pfPerc + impoundStorageTax
 * bid = (X + bsf(X)) * (1 + stPerc) + X * pfPerc + impoundStorageTax + (af + fp) * (1 + stPerc)
 * bid - (impoundStorageTax + (af + fp) * (1 + stPerc)) = (X + bsf(X)) * (1 + stPerc) + X * pfPerc
 * (X + bsf(X)) * (1 + stPerc) + X * pfPerc = bid - (impoundStorageTax + (af + fp) * (1 + stPerc))
 * X * (1 + pfPerc) + bsf(X) * (1 + stPerc) = bid - (impoundStorageTax + (af + fp) * (1 + stPerc))
 * X + bsf(X) * (1 + stPerc) / (1 + pfPerc) = (bid - (impoundStorageTax + (af + fp) * (1 + stPerc))) / (1 + pfPerc)
 *
 * // final result
 * X + bsf(X) * (1 + stPerc) / (1 + pfPerc) = (bid - (impoundStorageTax + (af + fp) * (1 + stPerc))) / (1 + pfPerc)
 *
 * if X < total_unpaid_bill
 *
 * isPlatformFeeTakesOfSellerFee=true
 * // old
 * bid = (X + bsf(X) + af + fp) * (1 + pfPerc) + X * stPerc + X * impoundStorageTax/total_unpaid_bill)
 * X + bsf(X)*(1 + pf)/(1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + pfPerc))/(1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * // calculate new
 * bid = (X + bsf(X) + af + fp) * (1 + pfPerc) + (X + bsf(X) + af + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill)
 * bid = (X + bsf(X) + af + fp) * (1 + pfPerc + stPerc) + X * impoundStorageTax/total_unpaid_bill
 * bid = (X + bsf(X)) * (1 + pfPerc + stPerc) + (af + fp) * (1 + pfPerc + stPerc) + X * impoundStorageTax/total_unpaid_bill)
 * bid = (X + bsf(X)) * (1 + pfPerc + stPerc) + X * impoundStorageTax/total_unpaid_bill + (af + fp) * (1 + pfPerc + stPerc)
 * (X + bsf(X)) * (1 + pfPerc + stPerc) + X * impoundStorageTax/total_unpaid_bill) = bid - (af + fp) * (1 + pfPerc + stPerc)
 * X * (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) + bsf(X) * (1 + pfPerc + stPerc) = bid - (af + fp) * (1 + pfPerc + stPerc)
 * X  + bsf(X) * (1 + pfPerc + stPerc) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + pfPerc + stPerc)) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * // final result
 * X  + bsf(X) * (1 + pfPerc + stPerc) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + pfPerc + stPerc)) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 *
 * isPlatformFeeTakesOfSellerFee=false
 * // old
 * bid = (X + bsf(X) + af + fp) + X * pfPerc + X * stPerc + X * impoundStorageTax/total_unpaid_bill
 * bid = (X + bsf(X) + af + fp) + X * (pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 * bid = X + X * (pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) + bsf(X) + af + fp
 * bid = X * (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) + bsf(X) + af + fp
 * X * (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) + bsf(X) = bid - (af + fp)
 * X + bsf(X) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp)) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * // final result
 * X + bsf(X) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp)) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * // calculate new
 * bid = (X + bsf(X) + af + fp) + X * pfPerc + (X + bsf(X) + af + fp) * stPerc + X * impoundStorageTax/total_unpaid_bill)
 * bid = (X + bsf(X) + af + fp) + (X + bsf(X) + af + fp) * stPerc + X * pfPerc + X * impoundStorageTax/total_unpaid_bill)
 * bid = (X + bsf(X) + af + fp) * (1 + stPerc) + X * pfPerc + X * impoundStorageTax/total_unpaid_bill)
 * bid = (X + bsf(X)) * (1 + stPerc) + (af + fp) * (1 + stPerc) + X * pfPerc + X * impoundStorageTax/total_unpaid_bill)
 * bid = (X + bsf(X)) * (1 + stPerc) + X * pfPerc + X * impoundStorageTax/total_unpaid_bill) + (af + fp) * (1 + stPerc)
 * (X + bsf(X)) * (1 + stPerc) + X * pfPerc + X * impoundStorageTax/total_unpaid_bill = bid - (af + fp) * (1 + stPerc)
 * X * (1 + stPerc) + bsf(X)) * (1 + stPerc) + X * pfPerc + X * impoundStorageTax/total_unpaid_bill = bid - (af + fp) * (1 + stPerc)
 * X * (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill) + bsf(X)) * (1 + stPerc) = bid - (af + fp) * (1 + stPerc)
 * X  + bsf(X) * (1 + stPerc) / (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + stPerc)) / (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill)
 *
 * // final result
 * X  + bsf(X) * (1 + stPerc) / (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + stPerc)) / (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill)
 *
 * --------------------------------------------------------------------------------------------
 * platform fee is percent
 *
 * if X >= total_unpaid_bill
 * bid = (X + bsf(X) + af + fp) * (1 + pf + stPerc) + impoundStorageTax;
 *
 * X + bsf(X) = (bid - (af + fp) * (1 + pf + stPerc) - impoundStorageTax) / (1 + pf + stPerc);
 * if X < total_unpaid_bill
 * bid = (X + bsf(X) + af + fp) * (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill);
 * X + bsf(X) = (bid - (af + fp) * (1 + pf + stPerc)) / (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill)
 *
 * --------------------------------------------------------------------------------------------
 * platform fee is step function
 *
 * if X >= total_unpaid_bill
 * bid = (X + bsf(X) + af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax;
 *
 * bid = (X + bsf(X)) * (1 + stPerc) + (af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax;
 * bid - ((af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax) = (X + bsf(X)) * (1 + stPerc);
 * (X + bsf(X)) * (1 + stPerc) = bid - ((af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax);
 * X + bsf(X) = (bid - ((af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax)) / (1 + stPerc);
 *
 * if X < total_unpaid_bill
 * bid = (X + bsf(X) + af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + pff(bid);
 *
 * bid = (X + bsf(X)) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + (af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + pff(bid);
 * bid - ((af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + pff(bid)) = (X + bsf(X)) * (1 + stPerc + impoundStorageTax/total_unpaid_bill));
 * (X + bsf(X)) * (1 + stPerc + impoundStorageTax/total_unpaid_bill)) = bid - ((af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + pff(bid));
 * X + bsf(X) = (bid - ((af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + pff(bid))) / (1 + stPerc + impoundStorageTax/total_unpaid_bill));
 *
 * @param bidAmountInCent
 * @param platformFeeData
 * @param adminFeeAmountInCent
 * @param buyerStepFeeFunc
 * @param salesTaxPerc
 * @param isPlatformFeeTakesOfSellerFee
 */

export class BidIncludesFeesBuyerFeeStepFunctionBreakdownCalculator extends FeesBreakdownCalculator {
    private salePrice = Money.zero;
    private buyerFee = Money.zero;
    private variableFee = Money.zero;

    calculate(bid: Money): WinningBidFeesInMoney {
        this.validate(bid);

        if (bid.isZero()) {
            return zeroWinningBidFees;
        }

        this.calculateSalePrice(bid);
        return this.correctWiningBidFeesIfNeeded(bid, this.calculateWinningBidFees(bid));
    }

    private calculateSalePrice(bid: Money) {
        const calculateSalePrice =
            this.platformFeeCalculator.type === PlatformFeeTypeEnum.SCHEDULE
                ? this.calculateSalePriceWhenPlatformFeeIsStepFunction
                : this.platformFeeCalculator.isPlatformFeeTakesOfSellerFee
                ? this.calculateSalePriceWhenPlatformFeeTakesOfSellerFee
                : this.calculateSalePriceWhenPlatformFeeDontTakeOfSellerFee;

        const {salePrice, fee} = calculateSalePrice(bid);
        this.salePrice = salePrice;
        this.buyerFee = Money.sumOf(fee, this.perVehicleFee.amount);
        this.variableFee = fee;
    }

    private calculateSalePriceWhenPlatformFeeDontTakeOfSellerFee = (bid: Money) => {
        // calculations for `isPlatformFeeTakesOfSellerFee=false`
        // if salePrice >= total_unpaid_bill
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) / (1 + pfPerc + stPerc) = (bid - (af + fp + impoundStorageTax))/(1 + pfPerc + stPerc)
        // ```
        // a = (1 + pf + stPerc);
        // b = (bid - (af + fp + impoundStorageTax)) / a;
        // ```
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) * (1 + stPerc) / (1 + pfPerc) = (bid - (impoundStorageTax + (af + fp) * (1 + stPerc))) / (1 + pfPerc)
        // a = (1 + pfPerc)
        // b = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)) / a
        //
        // if salePrice < total_unpaid_bill
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp))/(1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
        // ```
        // a = (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill);
        // b = (bid - (af + fp)) / a;
        // ```
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice  + bsf(salePrice) * (1 + stPerc) / (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + stPerc)) / (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill)
        // a = (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill)
        // b = (bid - (af + fp) * (1 + stPerc)) / a
        //
        // Result:
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) / a = b;
        //
        // buyerFee = bsf(salePrice);
        // salePrice + buyerFee / a = b;
        //
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) * (1 + stPerc) / a = b;
        //
        // buyerFee = bsf(salePrice);
        // salePrice + buyerFee * (1 + stPerc) / a = b;
        //
        // isChargeBuyerFee = false
        // c = 0;
        // salePrice = b;
        //
        // isChargeBuyerFee = true
        // salePrice + buyerFee / a = b;

        const pf = this.platformFeeCalculator.percent;
        const af = this.adminFeeCalculator.calculate();
        const stPerc = this.salesTaxCalculator.percent;
        const bsf = (amount: Money) => {
            const fee = this.buyerFeeCalculator.calculate(amount);
            return {fee, prevFee: this.buyerFeeCalculator.prevFee};
        };
        if (!this.options.isChargeBuyerFee) {
            // a = (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill)
            // b = (bid - (af + fp) * (1 + stPerc)) / a
            let percent = 1 / (1 + stPerc + pf + this.storageTaxCalculator.storageTaxPerc);
            let salePrice = this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(bid, Money.sumOf(af, this.perVehicleFee.amount)).takePercent(percent)
                : Money.subtractOf(bid, Money.sumOf(af, this.perVehicleFee.amount).addPercent(stPerc)).takePercent(
                      percent
                  );

            if (salePrice > (this.storageTaxCalculator.totalUnpaidBill ?? 0)) {
                // a = (1 + stPerc)
                // b = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)) / a
                percent = this.options.isExcludeSellerFeesFromSalesTax ? 1 / (1 + stPerc + pf) : 1 / (1 + pf);
                salePrice = Money.subtractOf(
                    bid,
                    this.options.isExcludeSellerFeesFromSalesTax
                        ? Money.sumOf(af, this.perVehicleFee.amount, this.storageTaxCalculator.impoundStorageTax)
                        : Money.sumOf(
                              Money.sumOf(af, this.perVehicleFee.amount).addPercent(stPerc),
                              this.storageTaxCalculator.impoundStorageTax
                          )
                ).takePercent(percent);
            }
            return {salePrice, fee: Money.zero};
        }

        // a = (1 + stPerc + pfPerc + impoundStorageTax/total_unpaid_bill)
        // b = (bid - (af + fp) * (1 + stPerc)) / a
        let percent = 1 / (1 + stPerc + pf + this.storageTaxCalculator.storageTaxPerc);
        let salePriceWithBuyerFee = this.options.isExcludeSellerFeesFromSalesTax
            ? Money.subtractOf(bid, Money.sumOf(af, this.perVehicleFee.amount)).takePercent(percent)
            : Money.subtractOf(bid, Money.sumOf(af, this.perVehicleFee.amount).addPercent(stPerc)).takePercent(percent);

        const calculate = (salePriceWithBuyerFee: Money, percent: number) => {
            // set initial value of salePrice equals to the `salePriceWithBuyerFee` value, that should be bigger than salePrice
            let salePrice = salePriceWithBuyerFee;
            const fees = bsf(salePrice);
            salePrice = Money.subtractOf(salePriceWithBuyerFee, fees.fee.takePercent(percent));
            const prevSalePrice = Money.subtractOf(salePriceWithBuyerFee, fees.prevFee.takePercent(percent));

            const isSalePriceInRange = this.buyerFeeCalculator.checkIfSalePriceIsInRange(salePrice, fees.fee);
            const isPrevSalePriceInRange = this.buyerFeeCalculator.checkIfSalePriceIsInRange(
                prevSalePrice,
                fees.prevFee
            );
            if (isSalePriceInRange) {
                return {salePrice, fee: fees.fee};
            } else if (isPrevSalePriceInRange) {
                return {salePrice: prevSalePrice, fee: fees.prevFee};
            } else {
                // 'smooth bidding'
                return {salePrice: prevSalePrice, fee: fees.prevFee};
            }
        };

        const result = calculate(salePriceWithBuyerFee, percent);
        let salePrice = result.salePrice;
        let fee = result.fee;

        if (salePriceWithBuyerFee.isGreaterThan(this.storageTaxCalculator.totalUnpaidBill ?? 0)) {
            // a = (1 + pfPerc)
            // b = (bid - ((af + fp) * (1 + stPerc) + impoundStorageTax)) / a
            percent = this.options.isExcludeSellerFeesFromSalesTax ? 1 / (1 + stPerc + pf) : 1 / (1 + pf);
            salePriceWithBuyerFee = Money.subtractOf(
                bid,
                this.options.isExcludeSellerFeesFromSalesTax
                    ? Money.sumOf(af, this.perVehicleFee.amount, this.storageTaxCalculator.impoundStorageTax)
                    : Money.sumOf(
                          Money.sumOf(af, this.perVehicleFee.amount).addPercent(stPerc),
                          this.storageTaxCalculator.impoundStorageTax
                      )
            ).takePercent(percent);

            const result = calculate(salePriceWithBuyerFee, percent);
            salePrice = result.salePrice;
            fee = result.fee;
        }
        return {salePrice, fee};
    };

    private calculateSalePriceWhenPlatformFeeTakesOfSellerFee = (bid: Money) => {
        // calculations for `isPlatformFeeTakesOfSellerFee=true`
        // if salePrice >= total_unpaid_bill
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) * (1 + pf) / (1 + pf + stPerc) = (bid - ((af + fp) * (1 + pf) + impoundStorageTax))/(1 + pf + stPerc)
        //
        // ```
        // a = (1 + pf + stPerc);
        // b = (bid - (af + fp) * (1 + pf) - impoundStorageTax) / a;
        // ```
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) = (bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)) / (1 + pfPerc + stPerc)
        // a = (1 + pf + stPerc);
        // b = (bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)) / a
        //
        // if salePrice < total_unpaid_bill
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) * (1 + pf) / (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + pf))/(1 + pf + stPerc + impoundStorageTax/total_unpaid_bill)
        //
        // ```
        // a = (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill);
        // b = (bid - (af + fp) * (1 + pf)) / a;
        // ```
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) * (1 + pfPerc + stPerc) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp) * (1 + pfPerc + stPerc)) / (1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill)
        // a = 1 + pfPerc + stPerc + impoundStorageTax/total_unpaid_bill
        // b = (bid - (af + fp) * (1 + pfPerc + stPerc)) / a
        //
        // Result:
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) * (1 + pf) / a = b;
        //
        // buyerFee = bsf(salePrice);
        // salePrice + buyerFee * (1 + pf) / a = b;
        //
        // isChargeBuyerFee = false
        // c = 0;
        // salePrice = b;
        //
        // isChargeBuyerFee = true
        // salePrice + buyerFee * (1 + pf) / a = b;
        //
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) = b;
        //
        // buyerFee = bsf(salePrice);
        // salePrice + buyerFee = b;
        //
        // isChargeBuyerFee = false
        // c = 0;
        // salePrice = b;
        //
        // isChargeBuyerFee = true
        // salePrice + buyerFee = b;

        const pf = this.platformFeeCalculator.percent;
        const af = this.adminFeeCalculator.calculate();
        const stPerc = this.salesTaxCalculator.percent;
        const bsf = (bid: Money) => {
            const fee = this.buyerFeeCalculator.calculate(bid);
            return {fee, prevFee: this.buyerFeeCalculator.prevFee};
        };
        if (!this.options.isChargeBuyerFee) {
            // a = (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill);
            // b = (bid - (af + fp) * (1 + pf)) / a;
            let percent = 1 / (1 + stPerc + pf + this.storageTaxCalculator.storageTaxPerc);
            let salePrice = Money.subtractOf(
                bid,
                Money.sumOf(af, this.perVehicleFee.amount).takePercent(1 + pf)
            ).takePercent(percent);

            if (salePrice > (this.storageTaxCalculator.totalUnpaidBill ?? 0)) {
                // a = (1 + pf + stPerc);
                // b = (bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)) / a
                percent = 1 / (1 + stPerc + pf);
                salePrice = Money.subtractOf(
                    bid,
                    this.options.isExcludeSellerFeesFromSalesTax
                        ? Money.sumOf(
                              Money.sumOf(af, this.perVehicleFee.amount).takePercent(1 + pf),
                              this.storageTaxCalculator.impoundStorageTax
                          )
                        : Money.sumOf(
                              Money.sumOf(af, this.perVehicleFee.amount).takePercent(1 + pf + stPerc),
                              this.storageTaxCalculator.impoundStorageTax
                          )
                ).takePercent(percent);
            }
            return {salePrice, fee: Money.zero};
        }

        // a = (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill);
        // b = (bid - (af + fp) * (1 + pf)) / a;
        let percent = 1 / (1 + stPerc + pf + this.storageTaxCalculator.storageTaxPerc);
        let salePriceWithBuyerFee = Money.subtractOf(
            bid,
            Money.sumOf(af, this.perVehicleFee.amount).takePercent(1 + pf)
        );

        const calculate = (salePriceWithBuyerFee: Money, percent: number) => {
            // set initial value of salePrice equals to the `salePriceWithBuyerFee` value, that should be bigger than salePrice
            let salePrice = salePriceWithBuyerFee;
            const fees = bsf(salePrice);
            salePrice = this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(salePriceWithBuyerFee, fees.fee.takePercent(1 + pf)).takePercent(percent)
                : Money.subtractOf(salePriceWithBuyerFee, fees.fee).takePercent(percent);
            const isSalePriceInRange = this.buyerFeeCalculator.checkIfSalePriceIsInRange(salePrice, fees.fee);
            const prevSalePrice = this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(salePriceWithBuyerFee, fees.prevFee.takePercent(1 + pf)).takePercent(percent)
                : Money.subtractOf(salePriceWithBuyerFee, fees.prevFee).takePercent(percent);
            const isPrevSalePriceInRange = this.buyerFeeCalculator.checkIfSalePriceIsInRange(
                prevSalePrice,
                fees.prevFee
            );
            if (isSalePriceInRange) {
                return {salePrice, fee: fees.fee};
            } else if (isPrevSalePriceInRange) {
                return {salePrice: prevSalePrice, fee: fees.prevFee};
            } else {
                // 'smooth bidding';
                return {salePrice: prevSalePrice, fee: fees.prevFee};
            }
        };

        const result = calculate(salePriceWithBuyerFee, percent);
        let salePrice = result.salePrice;
        let fee = result.fee;

        if (salePrice.isGreaterThan(this.storageTaxCalculator.totalUnpaidBill ?? 0)) {
            // a = (1 + pf + stPerc);
            // b = (bid - ((af + fp) * (1 + pfPerc + stPerc) + impoundStorageTax)) / a
            percent = 1 / (1 + stPerc + pf);
            salePriceWithBuyerFee = Money.subtractOf(
                bid,
                this.options.isExcludeSellerFeesFromSalesTax
                    ? Money.sumOf(
                          Money.sumOf(af, this.perVehicleFee.amount).takePercent(1 + pf),
                          this.storageTaxCalculator.impoundStorageTax
                      )
                    : Money.sumOf(
                          Money.sumOf(af, this.perVehicleFee.amount).takePercent(1 + pf + stPerc),
                          this.storageTaxCalculator.impoundStorageTax
                      )
            );

            const result = calculate(salePriceWithBuyerFee, percent);
            salePrice = result.salePrice;
            fee = result.fee;
        }
        return {salePrice, fee};
    };

    private calculateSalePriceWhenPlatformFeeIsStepFunction = (bid: Money) => {
        //
        // if salePrice >= total_unpaid_bill
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) / (1 + stPerc) = (bid - (af + fp + pff(bid)) - impoundStorageTax)/(1 + stPerc)
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) = (bid - ((af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax)) / (1 + stPerc);
        //
        // ```
        // a = (1 + stPerc);
        // isExcludeSellerFeesFromSalesTax = true
        // b = (bid - (af + fp + pff(bid)) - impoundStorageTax) / a;
        // isExcludeSellerFeesFromSalesTax = false
        // b = (bid - ((af + fp) * (1 + stPerc) + pff(bid) + impoundStorageTax)) / a;
        // ```
        //
        // if salePrice < total_unpaid_bill
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) / (1 + stPerc + impoundStorageTax/total_unpaid_bill) = (bid - (af + fp + pff(bid))/(1 + pf + stPerc + impoundStorageTax/total_unpaid_bill)
        // a = (1 + pf + stPerc + impoundStorageTax/total_unpaid_bill);
        // b = (bid - (af + fp) * (1 + pf)) / a;
        //
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) = (bid - ((af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + pff(bid))) / (1 + stPerc + impoundStorageTax/total_unpaid_bill));
        //
        // ```
        // a = (1 + stPerc + impoundStorageTax/total_unpaid_bill);
        // b = (bid - ((af + fp) * (1 + stPerc + impoundStorageTax/total_unpaid_bill) + ppf(bid))) / a;
        // ```
        //
        // Result:
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + bsf(salePrice) * (1 + pf) / a = b;
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + bsf(salePrice) = b;
        //
        // buyerFee = bsf(salePrice);
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + buyerFee * (1 + pf) / a = b;
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + buyerFee = b;
        //
        // isChargeBuyerFee = false
        // c = 0;
        // salePrice = b;
        //
        // isChargeBuyerFee = true
        // isExcludeSellerFeesFromSalesTax = true
        // salePrice + buyerFee * (1 + pf) / a = b;
        // isExcludeSellerFeesFromSalesTax = false
        // salePrice + buyerFee = b;

        const af = this.adminFeeCalculator.calculate();
        const stPerc = this.salesTaxCalculator.percent;
        const bsf = (bid: Money) => {
            const fee = this.buyerFeeCalculator.calculate(bid);
            return {fee, prevFee: this.buyerFeeCalculator.prevFee};
        };
        const {platformFee} = this.platformFeeCalculator.calculate({bid});
        if (!this.options.isChargeBuyerFee) {
            let percent = 1 / (1 + stPerc + this.storageTaxCalculator.storageTaxPerc);
            let salePrice = Money.subtractOf(bid, Money.sumOf(af, this.perVehicleFee.amount, platformFee)).takePercent(
                percent,
                {rounding: 'ceil'}
            );

            if (salePrice > (this.storageTaxCalculator.totalUnpaidBill ?? 0)) {
                percent = 1 / (1 + stPerc);
                salePrice = Money.subtractOf(
                    bid,
                    Money.sumOf(af, this.perVehicleFee.amount, platformFee),
                    this.storageTaxCalculator.impoundStorageTax
                ).takePercent(percent);
            }
            return {salePrice, fee: Money.zero};
        }

        let percent = 1 / (1 + stPerc + this.storageTaxCalculator.storageTaxPerc);
        let salePriceWithBuyerFee = this.options.isExcludeSellerFeesFromSalesTax
            ? Money.subtractOf(bid, Money.sumOf(af, this.perVehicleFee.amount, platformFee))
            : Money.subtractOf(
                  bid,
                  Money.sumOf(
                      Money.sumOf(af, this.perVehicleFee.amount).addPercent(
                          stPerc + this.storageTaxCalculator.storageTaxPerc
                      ),
                      platformFee
                  )
              ).takePercent(percent, {rounding: 'ceil'});

        const calculate = (salePriceWithBuyerFee: Money, percent: number) => {
            // set initial value of salePrice equals to the `salePriceWithBuyerFee` value, that should be bigger than salePrice
            let salePrice = salePriceWithBuyerFee;
            const fees = bsf(salePrice);
            salePrice = this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(salePriceWithBuyerFee, fees.fee).takePercent(percent, {
                      rounding: 'ceil',
                  })
                : Money.subtractOf(salePriceWithBuyerFee, fees.fee);
            const isSalePriceInRange = this.buyerFeeCalculator.checkIfSalePriceIsInRange(salePrice, fees.fee);
            const prevSalePrice = this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(salePriceWithBuyerFee, fees.prevFee).takePercent(percent, {
                      rounding: 'ceil',
                  })
                : Money.subtractOf(salePriceWithBuyerFee, fees.prevFee);
            const isPrevSalePriceInRange = this.buyerFeeCalculator.checkIfSalePriceIsInRange(
                prevSalePrice,
                fees.prevFee
            );
            if (isSalePriceInRange) {
                return {salePrice, fee: fees.fee};
            } else if (isPrevSalePriceInRange) {
                return {salePrice: prevSalePrice, fee: fees.prevFee};
            } else {
                // 'smooth bidding'
                return {salePrice: prevSalePrice, fee: fees.prevFee};
            }
        };

        const result = calculate(salePriceWithBuyerFee, this.options.isExcludeSellerFeesFromSalesTax ? percent : 0);

        let salePrice = result.salePrice;
        let fee = result.fee;

        if (salePrice.isGreaterThan(this.storageTaxCalculator.totalUnpaidBill ?? 0)) {
            percent = 1 / (1 + stPerc);
            salePriceWithBuyerFee = this.options.isExcludeSellerFeesFromSalesTax
                ? Money.subtractOf(
                      bid,
                      Money.sumOf(af, this.perVehicleFee.amount, platformFee),
                      this.storageTaxCalculator.impoundStorageTax
                  )
                : Money.subtractOf(
                      bid,
                      Money.sumOf(
                          Money.sumOf(af, this.perVehicleFee.amount).addPercent(stPerc),
                          platformFee,
                          this.storageTaxCalculator.impoundStorageTax
                      )
                  ).takePercent(percent, {rounding: 'ceil'});

            const result = calculate(salePriceWithBuyerFee, this.options.isExcludeSellerFeesFromSalesTax ? percent : 0);
            salePrice = result.salePrice;
            fee = result.fee;
        }
        return {salePrice, fee};
    };

    private calculateWinningBidFees(bid: Money): WinningBidFeesInMoney {
        const {salePrice, buyerFee, variableFee} = this;

        const adminFee = this.adminFeeCalculator.calculate();
        const {platformFee, rawPlatformFee} = this.platformFeeCalculator.calculate({
            amount: salePrice,
            bid,
            buyerFee,
            adminFee,
        });

        let correctedSalePrice = salePrice;
        if (platformFee !== rawPlatformFee) {
            correctedSalePrice = Money.sumOf(salePrice, Money.subtractOf(rawPlatformFee, platformFee));
        }

        const salesTax = this.options.isExcludeSellerFeesFromSalesTax
            ? this.salesTaxCalculator.calculate(correctedSalePrice)
            : this.salesTaxCalculator.calculate(Money.sumOf(correctedSalePrice, adminFee, buyerFee));
        const storageTax = this.storageTaxCalculator.calculateStorageTax(salePrice);
        return {
            salePrice: correctedSalePrice,
            total: bid,
            bid,
            platformFee,
            adminFee,
            buyerFee,
            salesTax,
            storageTax,
            variableFee,
            vehicleKeyFee: this.perVehicleFee.keeFee,
            vehicleOtherFixedFee: this.perVehicleFee.otherFee,
        };
    }

    private correctWiningBidFeesIfNeeded(bid: Money, fees: WinningBidFeesInMoney) {
        const total = this.calculateTotal(fees);
        const correction = Money.subtractOf(total, bid);
        return {...fees, salePrice: Money.subtractOf(fees.salePrice, correction)};
    }

    private calculateTotal(fees: WinningBidFeesInMoney): Money {
        const {salePrice, platformFee, adminFee, buyerFee, salesTax, storageTax} = fees;
        return Money.sumOf(salePrice, platformFee, adminFee, buyerFee, (salesTax ?? Money.zero).add(storageTax));
    }
}

export function calculateBuyerStepFeeSalePrice(
    amount: number,
    {
        platformFeeData,
        adminFeeAmount,
        stepFunctionFeeArr,
        salesTaxPerc,
        isPlatformFeeTakesOfSellerFee,
        isChargeBuyerFee,
        minBidAmount,
        storageTaxCalculator,
        feePrice,
        isExcludeSellerFeesFromSalesTax,
    }: {
        platformFeeData: PlatformFeeType;
        adminFeeAmount: number;
        stepFunctionFeeArr: StepFunctionFee[];
        salesTaxPerc: number;
        isPlatformFeeTakesOfSellerFee: boolean;
        isChargeBuyerFee?: boolean;
        minBidAmount?: Money;
        storageTaxCalculator: StorageTaxCalculator;
        feePrice?: FeePriceDto | null;
        isExcludeSellerFeesFromSalesTax: boolean;
    }
): WinningBidFeesInMoney {
    return new BidIncludesFeesBuyerFeeStepFunctionBreakdownCalculator(
        StepFunctionBuyerFeeCalculator.getInstance(stepFunctionFeeArr),
        new PercentPlatformFeeCalculator(platformFeeData, {isPlatformFeeTakesOfSellerFee}),
        storageTaxCalculator,
        new SalesTaxCalculator(salesTaxPerc),
        AdminFeeCalculator.getInstance(Money.fromDollars(adminFeeAmount)),
        new PerVehicleFee(feePrice),
        {
            isChargeBuyerFee,
            minBidAmount,
            isExcludeSellerFeesFromSalesTax,
        }
    ).calculate(Money.fromDollars(amount));
}
