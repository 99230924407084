import {Instance, types} from 'mobx-state-tree';
import {LDClient, LDFlagChangeset, LDFlagSet, initialize} from 'launchdarkly-js-client-sdk';
import {authUserToLdUser} from '../utils/launchDarklyutils';
import {UserInfo} from '@joyrideautos/auction-core/src/dtos/UserDto';
import BaseStore from './BaseStore';

export const FeatureFlagStore = BaseStore.named('FeatureFlagStore')
    .props({
        flags: types.map(
            types.union(
                types.string,
                types.boolean,
                types.number,
                types.array(types.string),
                types.array(types.boolean),
                types.array(types.number)
            )
        ),
    })
    .volatile((self) => {
        return {
            ldClient: null as LDClient | null,
        };
    })
    .actions((self) => ({
        setAllFlags: (allFlags: LDFlagSet) => {
            for (const name in allFlags) {
                self.flags.set(name, allFlags[name]);
            }
        },
        replaceFlags: (changeSet: LDFlagChangeset) => {
            for (const name in changeSet) {
                self.flags.set(name, changeSet[name].current);
            }
        },
    }))
    .actions((self) => ({
        loadUser: (user?: UserInfo) => {
            if (!self.rootStore.appConfig.commonConfig.launchDarklyEnabled) return;
            if (self.ldClient === null) {
                // we have never logged in to launch darkly, so we need to do so now.
                self.ldClient = initialize(
                    self.rootStore.appConfig.commonConfig.launchDarklyClientId,
                    authUserToLdUser(user)
                );

                self.ldClient.on('ready', () => {
                    if (!self.ldClient) return;

                    self.setAllFlags(self.ldClient.allFlags());
                });

                self.ldClient.on('change', (changeSet: LDFlagChangeset) => {
                    self.replaceFlags(changeSet);
                });
            } else {
                // we have already initialized the client once; let's just tell LD that
                // the logged in user has changed.
                self.ldClient.identify(authUserToLdUser(user)).catch((e) => console.log(e));
            }
        },

        beforeDestroy: () => {
            if (self.ldClient !== null) {
                self.ldClient.close().catch((e) => console.log(e));
            }
        },
    }));

export interface FeatureFlagStoreType extends Instance<typeof FeatureFlagStore> {}

export interface HasFeatureFlagStore {
    featureFlagStore: FeatureFlagStoreType;
}
