import {Instance, types} from 'mobx-state-tree';
import {makeSubscriber} from '@joyrideautos/auction-utils/src/subscriptions';
import {CcHold, CcHoldType} from '../types/CcHold';
import BaseStore from './BaseStore';
import {ItemPath} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';

const toAuctionKey = ({regionId, auctionId}: AuctionPath) => {
    return `${regionId}-auctions-${auctionId}`;
};

const perVehicleKey = ({regionId, auctionId, itemId}: ItemPath) => {
    return `${regionId}-auctions-${auctionId}/items/${itemId}`;
};

export const CcHoldStore = BaseStore.named('CcHoldStore')
    .props({
        holds: types.map(types.map(CcHold)),
    })
    .views((self) => {
        return {
            get loggedInUserHolds() {
                const uid = self.authUserStore.userInfo?.uid;
                return self.holds.get(uid);
            },
            findHoldsForUser(uid: string): Map<string, CcHoldType> {
                return self.holds.get(uid) as Map<string, CcHoldType>;
            },
            findHoldsForUserForAuction(uid: string, auction: {regionId: string; auctionId: string}) {
                return this.findHoldsForUser(uid)?.get(toAuctionKey(auction));
            },
            findHoldsForUserPerVehicle(uid: string, itemPath: ItemPath) {
                return this.findHoldsForUser(uid)?.get(perVehicleKey(itemPath));
            },
        };
    })
    .actions((self) => {
        return {
            setHold(uid: string, ccHoldKey: string, hold?: CcHoldType) {
                const userHolds = self.findHoldsForUser(uid);
                if (userHolds) {
                    hold ? userHolds.set(ccHoldKey, hold) : userHolds.delete(ccHoldKey);
                } else {
                    if (hold) {
                        self.holds.set(uid, {[ccHoldKey]: hold});
                    }
                }
            },
            setHolds(uid: string, holds?: {[key: string]: CcHoldType}) {
                if (holds) {
                    self.holds.set(uid, holds);
                }
            },
            subscribeToHoldForAuction(uid: string, {regionId, auctionId}: {auctionId: string; regionId: string}) {
                return makeSubscriber(`CcHoldStore-${uid}-${regionId}-${auctionId}`, () =>
                    self.paymentService.subscribeToCcHoldForAuction({uid, regionId, auctionId}, (hold) => {
                        this.setHold(uid, toAuctionKey({regionId, auctionId}), hold as CcHoldType | undefined);
                    })
                )();
            },
            subscribeToHoldPerVehicle(uid: string, {regionId, auctionId, itemId}: ItemPath) {
                return makeSubscriber(`CcHoldStore-${uid}-${regionId}-${auctionId}-${itemId}`, () =>
                    self.paymentService.subscribeToCcHoldPerVehicle({uid, regionId, auctionId, itemId}, (hold) => {
                        this.setHold(uid, perVehicleKey({regionId, auctionId, itemId}), hold as CcHoldType | undefined);
                    })
                )();
            },
            subscribeToHolds(uid: string) {
                return makeSubscriber(`CcHoldStore-${uid}`, () =>
                    self.paymentService.subscribeToCcHolds(uid, (holds) => {
                        this.setHolds(uid, holds as {[key: string]: CcHoldType} | undefined);
                    })
                )();
            },
        };
    });

export interface CcHoldStoreType extends Instance<typeof CcHoldStore> {}

export interface HasCcHoldStore {
    ccHoldStore: CcHoldStoreType;
}
