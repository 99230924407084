import {AuctionOccurrenceSettings} from '../../dtos/AuctionOccurrenceDto';
import {ImpoundFeeCategoryEnum, ImpoundFees} from '../../dtos/ItemDto';
import {AuctionTypeEnum} from '../../types/AuctionTypes';
import {areDatesEqual} from '@joyrideautos/auction-utils/src/dateTimeUtils';
import {Money} from '../../types/Money';

export type StorageTaxCalculator = {
    impoundStorageTax: Money;
    totalUnpaidBill: Money;
    storageTaxPerc: number;
    calculateStorageTax: (salePrice: Money) => Money;
};

export const emptyCalculator: StorageTaxCalculator = {
    impoundStorageTax: Money.zero,
    totalUnpaidBill: Money.zero,
    storageTaxPerc: 0,
    calculateStorageTax: () => Money.zero,
};
/*
 * EXT-4008 (Austin Storage Tax)
 * Storage Tax Calculation - use the following formula
 * Associate feeAmountInCents and taxAmountInCents for STORAGE, TOW, and LIEN fees to auctions based on feeDate, where feeDate = event start date (sequence auction) or end date (listing auction)
 * GIVEN total_unpaid_bill = TOW.feeAmountInCents + STORAGE.feeAmountInCents + STORAGE.taxAmountInCents + LIEN.feeAmountInCents
 * IF ‘Vehicle Sales Price’ >= total unpaid bill, THEN Storage Tax = STORAGE.taxAmountInCents
 * ELSE Storage Tax = (Vehicle Sales Price/total unpaid bill)*STORAGE.taxAmountInCents
 */
export const createStorageTaxCalculator = ({
    auctionSettings,
    impoundFees,
    isStorageTaxEnabled,
}: {
    auctionSettings: AuctionOccurrenceSettings;
    impoundFees?: ImpoundFees;
    isStorageTaxEnabled?: boolean;
}): StorageTaxCalculator => {
    if (!isStorageTaxEnabled || !impoundFees) {
        return emptyCalculator;
    }

    const date =
        auctionSettings.auctionType === AuctionTypeEnum.SEQUENCE
            ? auctionSettings.startEvent
            : auctionSettings.expiration;

    const storage = impoundFees[ImpoundFeeCategoryEnum.STORAGE];
    const tow = impoundFees[ImpoundFeeCategoryEnum.TOW];
    const lien = impoundFees[ImpoundFeeCategoryEnum.LIEN];

    const storageCategory = storage?.filter(({feeDate}) => areDatesEqual(feeDate, date!))[0];
    const towCategory = tow?.filter(({feeDate}) => areDatesEqual(feeDate, date!))[0];
    const lienCategory = lien?.filter(({feeDate}) => areDatesEqual(feeDate, date!))[0];

    if (!storageCategory) {
        return emptyCalculator;
    }
    const totalUnpaidBill = Money.sumOf(
        Money.fromCents(storageCategory?.taxAmountInCents ?? 0),
        Money.fromCents(storageCategory?.feeAmountInCents ?? 0),
        Money.fromCents(towCategory?.feeAmountInCents ?? 0),
        Money.fromCents(lienCategory?.feeAmountInCents ?? 0)
    );

    const impoundStorageTax = Money.fromCents(storageCategory.taxAmountInCents ?? 0);
    return {
        impoundStorageTax,
        totalUnpaidBill,
        storageTaxPerc: impoundStorageTax.cents / totalUnpaidBill.cents,
        calculateStorageTax: (salePrice: Money) => {
            return impoundStorageTax.takePercent(
                salePrice.cents > totalUnpaidBill.cents ? 1 : salePrice.cents / totalUnpaidBill.cents
            );
        },
    };
};
