import {cast, getParent, getSnapshot, IAnyModelType, IAnyType, IModelType, Instance, types} from 'mobx-state-tree';

type ModelDelegate<T> = {
    afterSetValue: (newValue: T) => void;
};

type ArrayEvents<T> = {
    onChange: (newValue: T[], oldValue: T[]) => void;
};

const primitives = {
    boolean: <T extends IAnyModelType = any>(
        initialValue = false,
        events?: (parent: T) => {
            onChange: (value: boolean) => void;
        }
    ) => {
        return types.optional(
            types
                .model('BooleanModel', {
                    value: false,
                })
                .volatile(() => ({
                    delegate: undefined as ModelDelegate<boolean> | undefined,
                }))
                .views((self) => ({
                    get parent(): T {
                        return getParent(self);
                    },
                    get events() {
                        return events?.(this.parent);
                    },
                }))
                .actions((self) => ({
                    setValue(value: boolean, options?: {notify?: boolean}) {
                        const notify = options?.notify ?? true;
                        self.value = value;
                        if (notify) {
                            self.events?.onChange(value);
                            self.delegate?.afterSetValue(value);
                        }
                    },
                    toggle() {
                        self.value = !self.value;
                        self.delegate?.afterSetValue(self.value);
                    },
                    setDelegate(delegate?: ModelDelegate<boolean>) {
                        self.delegate = delegate;
                    },
                })),
            {value: initialValue}
        );
    },
    number: (
        initialValue = 0,
        events?: (parent: any) => {
            onChange: (newValue: number, oldValue: number) => void;
        }
    ) => {
        return types.optional(
            types
                .model('NumberModel', {
                    value: types.number,
                })
                .views((self) => ({
                    get parent() {
                        return getParent(self);
                    },
                    get events() {
                        return events?.(this.parent);
                    },
                }))
                .actions((self) => ({
                    setValue(value: number) {
                        const oldValue = self.value;
                        self.value = value;
                        self.events?.onChange(value, oldValue);
                    },
                    increment(by = 1) {
                        self.value += by;
                    },
                    decrement(by = 1) {
                        self.value -= by;
                    },
                    reset() {
                        self.value = initialValue;
                    },
                })),
            {value: initialValue}
        );
    },
    string: <T extends IAnyModelType = any>(
        initialValue = '',
        events?: (parent: T) => {
            onChange: (newValue: string, oldValue: string) => void;
        }
    ) => {
        return types.optional(
            types
                .model('StringModel', {
                    value: types.string,
                })
                .views((self) => ({
                    get parent(): T {
                        return getParent(self);
                    },
                    get isEmpty() {
                        return !self.value;
                    },
                    get events() {
                        return events?.(this.parent);
                    },
                }))
                .actions((self) => ({
                    setValue(value?: string | null) {
                        const oldValue = self.value;
                        self.value = value ?? '';
                        self.events?.onChange(self.value, oldValue);
                    },
                })),
            {value: initialValue}
        );
    },
    enumeration: <T extends string>(values: T[], initialValue: T) => {
        return types.optional(
            types
                .model('Enumeration', {
                    value: types.enumeration(values),
                })
                .volatile(() => ({
                    delegate: undefined as ModelDelegate<T> | undefined,
                }))
                .views((self) => ({
                    is(value: T) {
                        return value === self.value;
                    },
                }))
                .actions((self) => ({
                    setValue(value: T) {
                        self.value = value as any;
                        self.delegate?.afterSetValue(value);
                    },
                    setDelegate(delegate: ModelDelegate<T>) {
                        self.delegate = delegate;
                    },
                })),
            {value: initialValue}
        );
    },
    map: <V = any>(initialValue?: Map<string, V>) => {
        return types.optional(
            types
                .model('Map', {})
                .volatile(() => ({
                    map: initialValue ?? new Map<string, V>(),
                }))
                .views((self) => ({
                    getValue(key: string) {
                        return self.map.get(key);
                    },
                }))
                .actions((self) => ({
                    replace(map: Map<string, V>) {
                        self.map = map;
                    },
                    replaceFromObject(obj: Record<string, V>) {
                        self.map = new Map<string, V>(Object.entries(obj));
                    },
                    setValue(key: string, value: V) {
                        self.map.set(key, value);
                    },
                })),
            {}
        );
    },
    array: <T extends IAnyType>(model: T, initial?: T['Type'][], events?: (parent: any) => ArrayEvents<T['Type']>) => {
        return types.optional(
            types
                .model('Primitives.Array')
                .props({
                    array: types.array(model),
                })
                .volatile(() => ({
                    delegate: null as ArrayEvents<T['Type']> | null,
                }))
                .views((self) => ({
                    get parent(): T {
                        return getParent(self);
                    },
                    get events() {
                        return events?.(this.parent);
                    },
                }))
                .actions((self) => ({
                    setDelegate(delegate: ArrayEvents<T['Type']> | null) {
                        self.delegate = delegate;
                    },
                }))
                .views((self) => ({
                    get values() {
                        return self.array.slice();
                    },
                    get rawValues() {
                        return getSnapshot(self.array);
                    },
                    get size() {
                        return self.array.length;
                    },
                    hasValue(value: T['Type']) {
                        return self.array.includes(value);
                    },
                    hasAllValues(values: T['Type'][]) {
                        return values.every((v) => self.array.includes(v));
                    },
                    hasAnyValue(values: T['Type'][]) {
                        return values.some((v) => self.array.includes(v));
                    },
                    diff(values: T['Type'][]) {
                        return values.filter((v) => !self.array.includes(v));
                    },
                }))
                .actions((self) => ({
                    addValue(value: T['Type']) {
                        if (self.events || self.delegate) {
                            const oldValues = self.array.slice();
                            self.array.push(value);
                            const newValues = self.array.slice();
                            self.events?.onChange(newValues, oldValues);
                            self.delegate?.onChange(newValues, oldValues);
                        } else {
                            self.array.push(value);
                        }
                    },
                    addValues(values: T['Type'][]) {
                        if (self.events || self.delegate) {
                            const oldValues = self.array.slice();
                            self.array.push(...values);
                            const newValues = self.array.slice();
                            self.events?.onChange(newValues, oldValues);
                            self.delegate?.onChange(newValues, oldValues);
                        } else {
                            self.array.push(...values);
                        }
                    },
                    removeValue(value: T['Type']) {
                        if (self.events || self.delegate) {
                            const oldValues = self.array.slice();
                            self.array.remove(value);
                            const newValues = self.array.slice();
                            self.events?.onChange(newValues, oldValues);
                            self.delegate?.onChange(newValues, oldValues);
                        } else {
                            self.array.remove(value);
                        }
                    },
                    replace(array: T['Type'][]) {
                        if (self.events || self.delegate) {
                            const oldValues = self.array.slice();
                            self.array.replace(array);
                            const newValues = self.array.slice();
                            self.events?.onChange(newValues, oldValues);
                            self.delegate?.onChange(newValues, oldValues);
                        } else {
                            self.array.replace(array);
                        }
                    },
                    clear() {
                        if (self.events || self.delegate) {
                            const oldValues = self.array.slice();
                            self.array.clear();
                            const newValues = self.array.slice();
                            self.events?.onChange(newValues, oldValues);
                            self.delegate?.onChange(newValues, oldValues);
                        } else {
                            self.array.clear();
                        }
                    },
                    filter(array: T['Type'][]) {
                        this.replace(self.array.filter((v) => !array.includes(v)));
                    },
                }))
                .actions((self) => ({
                    afterCreate() {
                        self.array = cast(initial ? initial.slice() : []);
                    },
                })),
            {}
        );
    },
};

export default primitives;

interface EnumerationModel<T extends string> {
    value: T;
    setValue: (value: T) => void;
}

export interface EnumerationModelType<T extends string>
    extends Instance<IModelType<any, EnumerationModel<T>, any, any>> {}
