import {ItemPath} from '../dtos/ItemDto';
import {AuctionsWithBids, UserInfo, UserWithId} from '../dtos/UserDto';

export function mapAuctionsWithBids(auctionsWithBids?: AuctionsWithBids): (ItemPath & {bidId: string})[] {
    if (!auctionsWithBids) {
        return [];
    }

    const regionIds = Object.keys(auctionsWithBids);
    return regionIds.reduce<(ItemPath & {bidId: string})[]>((result, regionId) => {
        const auctionIds = Object.keys(auctionsWithBids[regionId]);
        for (const auctionId of auctionIds) {
            const itemIds = Object.keys(auctionsWithBids[regionId][auctionId]);
            for (const itemId of itemIds) {
                const bidOrString = auctionsWithBids[regionId][auctionId][itemId];
                const bidId = typeof bidOrString === 'string' ? bidOrString : bidOrString.bidId;
                result.push({
                    regionId,
                    auctionId,
                    itemId,
                    bidId,
                });
            }
        }
        return result;
    }, []);
}

export const mapDBUserToUserInfo = (user: UserWithId): UserInfo => {
    const paymentInfo = user.settings && user.settings.paymentInfo ? user.settings.paymentInfo : {};
    return {
        ...user,
        settings: {
            ...user.settings,
            paymentInfo: {
                ...paymentInfo,
            },
        },
        identityVerifiedByAdmin: user.identityVerifiedByAdmin,
        identityVerifiedByStripe: user.identityVerifiedByStripe,
        emailVerified: !!user.emailVerifiedAt,
        isAnonymous: false,
        phoneVerificationDate: user.phoneVerificationDate || undefined,
        phoneVerificationRequestId: user.phoneVerificationRequestId || undefined,
        phoneVerificationFor: user.phoneVerificationFor || undefined,
        phoneVerifiedAt: user.phoneVerifiedAt || undefined,
        auctionsWithBids: mapAuctionsWithBids(user.auctionsWithBids),
        failedDeposits: Object.entries(user.failedDeposits || {}).map(([itemKey, {paymentResultKey}]) => ({
            item: itemKey,
            paymentResultKey,
        })),
    };
};
