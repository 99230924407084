import {AuctionPath} from '@joyrideautos/auction-core/src/dtos/AuctionOccurrenceDto';
import {ItemPath} from '@joyrideautos/auction-core/src/dtos/ItemDto';
import {UserPayInFullConfigEntity} from '@joyrideautos/auction-core/src/models/UserPayInFullConfigEntity';
import {UserDto} from '@joyrideautos/auction-core/src/dtos/UserDto';
import BaseViewModel from '@joyrideautos/ui-models/src/BaseViewModel';
import {getSnapshot} from 'mobx-state-tree';

export const PayInFullBannerViewModel = BaseViewModel.named('PayInFullBannerModel').views((self) => ({
    userPayInFullConfigEntity(sellerId: string, user: UserDto & {uid: string}) {
        const payInFullEnabledFor = this.getPayInFullEnabledForSeller(sellerId) || {};
        const isPreApprovedUser = self.biddersStatuses.isPreApprovedUser(user.uid, sellerId);
        return new UserPayInFullConfigEntity(user, isPreApprovedUser, payInFullEnabledFor);
    },
    checkPayInFullForAuction({regionId, auctionId}: AuctionPath) {
        const auction = self.auctionsStore.findAuction(regionId, auctionId);

        if (!auction) {
            return false;
        }

        return auction.sellerIds.some((sellerId) => this.isPayInFullEnabledForSeller(sellerId));
    },
    checkPayInFullForItem({regionId, auctionId, itemId}: ItemPath) {
        const itemsStore = self.itemsStoreFactory.getItemsStoreForAuction({
            regionId,
            auctionId,
        });

        const item = itemsStore.getItem(regionId, auctionId, itemId);

        if (!item) {
            return false;
        }

        return this.isPayInFullEnabledForSeller(item.sellerId);
    },
    isPayInFullEnabledForSeller(sellerId: string) {
        if (!self.authUser) {
            return false;
        }
        return this.userPayInFullConfigEntity(sellerId, getSnapshot(self.authUser) as any).isPayInFullEnabledForSeller;
    },
    getPayInFullEnabledForSeller(sellerId: string) {
        return self.sellersStore.findSeller(sellerId)?.payInFullEnabledFor;
    },
}));
