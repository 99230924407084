import {SoldTypeEnum, WinningBidDto as WinningBidCoreDto} from '@joyrideautos/auction-core/src/dtos/WinningBidDto';
import {getRoot, Instance, types} from 'mobx-state-tree';
import type {ItemType} from './item/Item';

export type WinningBidInfo = {
    itemKey: string;
    itemId: string;
    amount: number;
    uid?: string;
    start: number;
    expiration: number;
    timestamp?: number;
    ended: boolean;
    deleted?: boolean;
    skipped?: boolean;
    reserveMet?: boolean;
};

export const PricingAndFees = types.model('PricingAndFees', {
    salePrice: types.number,
    salesTax: types.maybe(types.number),
    bid: types.number,
    platformFee: types.number,
    adminFee: types.number,
    buyerFee: types.number,
    variableFee: types.maybe(types.number),
    vehicleKeyFee: types.maybe(types.number),
    vehicleOtherFixedFee: types.maybe(types.number),
    deposit: types.maybe(types.number),
    total: types.maybe(types.number),
});

export function createPricingAndFees(initialValue?: Partial<PricingAndFeesType>) {
    return PricingAndFees.create({
        salePrice: initialValue?.salePrice ?? 0,
        salesTax: initialValue?.salesTax ?? 0,
        bid: initialValue?.bid ?? 0,
        platformFee: initialValue?.platformFee ?? 0,
        adminFee: initialValue?.adminFee ?? 0,
        buyerFee: initialValue?.buyerFee ?? 0,
        variableFee: initialValue?.variableFee ?? 0,
        vehicleKeyFee: initialValue?.vehicleKeyFee ?? 0,
        vehicleOtherFixedFee: initialValue?.vehicleOtherFixedFee ?? 0,
        deposit: initialValue?.deposit ?? 0,
        total: initialValue?.total ?? 0,
    });
}

export interface PricingAndFeesType extends Instance<typeof PricingAndFees> {}

export const WinningBidAutoBid = types
    .model('WinningBidAutoBid', {
        amount: types.maybe(types.number),
        bidId: types.string,
        timestamp: types.string,
        uid: types.string,
    })
    .views((self) => {
        return {
            get amountFormatted() {
                return self.amount && self.amount.toLocaleString();
            },
        };
    });

export interface WinningBidAutoBidType extends Instance<typeof WinningBidAutoBid> {}

export const WinningBidDto = types
    .model('WinningBidDto', {
        amount: types.maybe(types.number),
        start: types.maybe(types.string),
        ended: types.maybe(types.boolean),
        deleted: types.maybe(types.boolean),
        skipped: types.maybe(types.boolean),
        expiration: types.optional(types.string, '1970-01-01T00:00:00.000Z'),
        timestamp: types.maybe(types.string),
        autobid: types.maybe(WinningBidAutoBid),
        bidId: types.maybe(types.string),
        uid: types.maybe(types.string),
        isUserPreApproved: types.maybe(types.boolean),
        reserveMet: types.maybe(types.boolean),
        fees: types.maybe(PricingAndFees),
        payInFullAvailable: types.optional(types.boolean, false),
        billingUid: types.maybe(types.string),
        soldType: types.optional(types.enumeration(Object.values(SoldTypeEnum)), SoldTypeEnum.AUCTION),
    })
    .views((self) => {
        return {
            get amountNumber() {
                return self.amount || 0;
            },
            get amountFormatted() {
                const amount = self.amount || 0;
                return amount.toLocaleString();
            },
            get sold() {
                return self.ended && self.reserveMet && Boolean(self.uid);
            },
            get unsold() {
                return self.ended && !this.sold;
            },
        };
    })
    // Only for tests
    .actions((self) => {
        function isTest() {
            return process.env.NODE_ENV === 'test';
        }
        return {
            setExpiration(expiration: string) {
                if (!isTest()) {
                    throw new Error('setExpiration should only be used in tests');
                }
                self.expiration = expiration;
            },
            setEnded(ended: boolean) {
                if (!isTest()) {
                    throw new Error('setEnded should only be used in tests');
                }
                self.ended = ended;
            },
        };
    });

export interface WinningBidDtoType extends Instance<typeof WinningBidDto> {}

export const WinningBid = WinningBidDto.props({
    itemId: types.identifier,
    regionId: types.string,
    auctionId: types.string,
})
    .named('WinningBid')
    .views((self) => {
        return {
            getItemsStore(parent: any) {
                const {regionId, auctionId} = parent;
                const root = getRoot(parent) as any;
                return root.itemsStoreFactory.getItemsStoreForAuction({regionId, auctionId});
            },

            get item(): ItemType | undefined {
                return this.getItemsStore(self).fetchItemAsync({...self});
            },
        };
    });

export function fromDto<T>(winningBid: WinningBidCoreDto & T): WinningBidType & T {
    return winningBid as WinningBidType & T;
}

export function mapFromDto<T>(winningBids: Record<string, WinningBidCoreDto & T>): Record<string, WinningBidType & T> {
    return Object.keys(winningBids).reduce<Record<string, WinningBidType & T>>((bids, key) => {
        bids[key] = fromDto(winningBids[key]);
        return bids;
    }, {});
}

export interface WinningBidType extends Instance<typeof WinningBid> {}
