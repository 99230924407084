import {Instance, flow, isAlive, types} from 'mobx-state-tree';
import BaseViewModel from '../../BaseViewModel';
import Make, {MakeType, sorting as sortingMakes} from '../../types/Make';
import {LoadingStatus} from '../../utils/LoadingStatus';
import {CancelableError, makeCancelable} from '@joyrideautos/auction-utils/src/cancelable';
import {MakeDataDto, ModelDataDto} from '@joyrideautos/auction-core/src/dtos/RefsDto';
import {cast} from '@joyrideautos/auction-utils/src/castUtils';
import {logError} from '@joyrideautos/ui-logger/src/utils';
import VehicleModelMstModel, {
    getModelWithMakeName,
    sorting as sortingModels,
    ModelType,
} from '../../types/VehicleModelMstModel';
import {when} from 'mobx';

const cancelableMakes = makeCancelable<MakeDataDto[]>('fetch makes');
const cancelableModels = makeCancelable<ModelDataDto[]>('fetch models');

type MakesRecord = Record<string, Instance<typeof Make>>;
type ModelsRecord = Record<string, Instance<typeof VehicleModelMstModel>>;

const RefsMake = BaseViewModel.named('RefsMake')
    .props({
        makesCache: types.map(Make),
        modelsCache: types.map(VehicleModelMstModel),
    })
    .volatile(() => ({
        makesLoadingStatus: new LoadingStatus(),
        modelsLoadingStatus: new LoadingStatus(),
    }))
    .views((self) => ({
        get sortedMakes() {
            return Array.from(self.makesCache.values()).sort(sortingMakes('makeName'));
        },
        get sortedModels() {
            return Array.from(self.modelsCache.values()).sort(sortingModels('modelName'));
        },
    }))
    .actions((self) => ({
        loadAllMakes: flow(function* () {
            const {isReady, isInProgress} = self.makesLoadingStatus;
            if (!isAlive(self) || isReady || isInProgress) {
                return self.sortedMakes;
            }
            try {
                self.makesLoadingStatus.setInProgress();
                const allMakes: MakeDataDto[] = yield cancelableMakes(
                    self.rootStore.refsService.fetchAllMakes.bind(self.rootStore.refsService),
                    {
                        abort: () => !isAlive(self),
                    }
                );
                if (!isAlive(self)) {
                    return self.sortedMakes;
                }
                self.makesCache = cast(
                    allMakes
                        .filter(({active}) => active ?? true)
                        .reduce<Record<string, MakeType>>((makes, {make, makeId, makeCode, isPopular}) => {
                            makes[makeCode] = {
                                makeName: make,
                                makeId,
                                makeCode,
                                isPopular: isPopular || false,
                            };
                            return makes;
                        }, {})
                );
                self.makesLoadingStatus.setReady();
            } catch (e: any) {
                if (e instanceof CancelableError) {
                    self.logger.log(e.message);
                } else {
                    self.logger.error(e);
                    self.makesLoadingStatus.setError(e.message);
                }
            }
            return self.sortedMakes;
        }),
        loadAllModels: flow(function* () {
            const {isReady, isInProgress} = self.modelsLoadingStatus;
            if (!isAlive(self) || isReady || isInProgress) {
                return self.sortedModels;
            }
            try {
                self.modelsLoadingStatus.setInProgress();
                const allModels: ModelDataDto[] = yield cancelableModels(
                    self.rootStore.refsService.fetchAllModels.bind(self.rootStore.refsService),
                    {
                        abort: () => !isAlive(self),
                    }
                );
                if (!isAlive(self)) {
                    return self.sortedModels;
                }
                self.modelsCache = cast(
                    allModels
                        .filter(({active}) => active ?? true)
                        .reduce<Record<string, ModelType>>((models, {modelId, model, makeId, makeCode}) => {
                            models[model] = {
                                modelName: model,
                                modelId,
                                makeId,
                                makeCode,
                            };
                            return models;
                        }, {})
                );
                self.modelsLoadingStatus.setReady();
            } catch (e: any) {
                if (e instanceof CancelableError) {
                    self.logger.log(e.message);
                } else {
                    self.logger.error(e);
                    self.modelsLoadingStatus.setError(e.message);
                }
            }
            return self.sortedModels;
        }),
    }))
    .views((self) => ({
        get makes() {
            if (self.makesLoadingStatus.isNew) {
                self.loadAllMakes().catch(logError());
            }
            return self.sortedMakes;
        },
        get makesByCode() {
            return self.makesCache;
        },
        get makesByName(): MakesRecord {
            return this.makes.reduce<MakesRecord>(
                (record, make) => ({
                    [make.makeName]: make,
                    ...record,
                }),
                {}
            );
        },
        get models() {
            if (self.modelsLoadingStatus.isNew) {
                self.loadAllModels().catch(logError());
            }
            return self.sortedModels;
        },
        get modelsByMakeAndModelName(): ModelsRecord {
            return this.models.reduce<ModelsRecord>((record, model) => {
                const make = this.makesByCode.get(model.makeCode);
                const key = getModelWithMakeName(make!.makeName, model.modelName);
                record[key] = model;
                return record;
            }, {});
        },
    }))
    .actions((self) => ({
        fetchModelsForMake: flow(function* (makeName: string) {
            self.loadAllMakes().catch(logError());
            self.loadAllModels().catch(logError());
            yield when(() => self.makesLoadingStatus.isReady && self.modelsLoadingStatus.isReady);
            const make = self.makesByName[makeName];
            return self.sortedModels.filter(({makeId}) => makeId === make?.makeId);
        }),
    }));
export default RefsMake;
