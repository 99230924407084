import {BaseService} from './BaseService';
import {QueueObjectDto} from '@joyrideautos/auction-core/src/dtos/GDriveTypesDto';
import {DataSnapshot} from '../firebase/Database';
import {FeReqRouteEnum} from '@joyrideautos/auction-core/src/services/FERoutingService';

function isChangedGDriveCSVEvent(queueEntry: QueueObjectDto) {
    const keys = Object.keys(queueEntry);
    return keys.length === 2 && keys.includes('timestamp') && keys.includes('path');
}

export class GoogleDriveService extends BaseService {
    subscribeGoogleDriveQueue(userId: string, listener: (queueEntries: QueueObjectDto[]) => void): () => void {
        const onSnapshot = (snapshot: DataSnapshot | null) => {
            if (snapshot?.exists()) {
                const snapshotVal = snapshot?.val();
                const queueEntries: QueueObjectDto[] = Object.values(snapshotVal);
                // we have two types of event: GDrive async upload and when user changes csv in GDrive
                const asyncUploadOnly = queueEntries.filter((queueEntry) => !isChangedGDriveCSVEvent(queueEntry));
                listener(asyncUploadOnly);
            } else {
                listener([]);
            }
        };

        return this.firebase.database.subscribeToSnapshot(
            this.database.ref('/GoogleDrive/queue').orderByChild('uid').equalTo(userId),
            onSnapshot
        );
    }

    async validateGoogleDriveFolder(folderId: string, sellerId: string): Promise<any> {
        return await this.rpcService.call(FeReqRouteEnum.API_GOOGLE_VALIDATE_GOOGLE_DRIVE_FOLDER)({
            folderId,
            sellerId,
        });
    }

    async saveGoogleDriveConnection(
        folderId: string,
        sellerId: string,
        connectionInfo: QueueObjectDto
    ): Promise<boolean> {
        await this.rpcService.call(FeReqRouteEnum.API_GOOGLE_SET_GOOGLE_DRIVE_QUEUE)({
            folderId,
            sellerId,
            connectionInfo,
        });
        return true;
    }

    async importManual() {
        // TODO: (future) create a separate admin endpoint for this
        if (!this.config.commonConfig.migrationToken) {
            throw new Error('Please set the following env vars: DB_MIGRATION_TOKEN');
        }
        await this.rpcService.call(FeReqRouteEnum.API_GOOGLE_GOOGLE_IMPORT_MANUAL, {timeout: 540_000})({
            token: `${this.config.commonConfig.migrationToken}`,
        });
    }
}
