export interface PlatformFeeScheduleStep {
    from: number;
    to?: number;
    amountInCents: number;
}

export interface PlatformFeeScheduleDto {
    editedBy?: string;
    editedAt?: string;
    createdBy?: string;
    createdAt?: string;
    name: string;
    steps: PlatformFeeScheduleStep[];
}

export enum PlatformFeeTypeEnum {
    PERCENTAGE = 'PERCENTAGE',
    SCHEDULE = 'SCHEDULE',
}

export const platformFeeTypeDescriptions = {
    [PlatformFeeTypeEnum.PERCENTAGE]: 'Percentage',
    [PlatformFeeTypeEnum.SCHEDULE]: 'Platform Fee Schedule',
};

export const DEFAULT_PLATFORM_FEE_TYPE = PlatformFeeTypeEnum.PERCENTAGE;
