import {PlatformFeeCalculator} from './types';
import {StepFunctionPlatformFeeCalculator} from './StepFunctionPlatformFeeCalculator';
import {PercentPlatformFeeCalculator} from './PercentPlatformFeeCalculator';
import {AuctionOccurrenceSettings} from '../../dtos/AuctionOccurrenceDto';
import {PlatformFeeScheduleDto, PlatformFeeTypeEnum} from '../../dtos/PlatformFeeScheduleDto';

const createPlatformFeeCalculator = ({
    auctionSettings,
    platformFeeSchedule,
    isManagerForSeller,
}: {
    auctionSettings: AuctionOccurrenceSettings;
    platformFeeSchedule?: PlatformFeeScheduleDto | null;
    isManagerForSeller?: boolean;
}) => {
    return {
        [PlatformFeeTypeEnum.PERCENTAGE]: (): PlatformFeeCalculator =>
            PercentPlatformFeeCalculator.getInstanceWithAuctionSettings(auctionSettings, {
                isManagerForSeller: isManagerForSeller ?? false,
            }),
        [PlatformFeeTypeEnum.SCHEDULE]: (): PlatformFeeCalculator => {
            if (!platformFeeSchedule) {
                throw new Error('platformFeeSchedule is required');
            }
            return StepFunctionPlatformFeeCalculator.getInstance(platformFeeSchedule);
        },
    }[auctionSettings.platformFeeType ?? PlatformFeeTypeEnum.PERCENTAGE]();
};

export default createPlatformFeeCalculator;
